import { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import NumerFormat from 'react-number-format';
import { MDBBadge } from 'mdbreact';
import { useNavigate } from 'react-router-dom';


const $ = require('jquery');
$.Datatable = require('datatables.net-dt');

export class Datatable extends Component {
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            colores: []
        };
    }
    sub = (s) => {
        if (s.length > 70) {
            return s.substr(0, 70);
        } else {
            return s;
        }
    }



    componentDidMount() {

        $('div.dataTables_filter input').addClass('mySeachClass');

        this.$el = $(this.el);

        let datatale = [];
        let tabla_colores = [];
        let user = JSON.parse(localStorage.getItem('user'));
        let filter = JSON.parse(localStorage.getItem('filter'));

        this.props.data.map((ppi, idx) => {
            let input = (<input />);
            let tmp = [];

            if (user.fk_role == 1) {
                tmp = [
                    ppi.check,
                    ppi.folio,
                    ppi.name,
                    ppi.evaluation,
                    ppi.amount,
                    ppi.dependency,
                    ppi.created_at,
                    ppi.status,
                    ppi.executor,
                    ppi.element.pk_status,
                    ppi.comments,
                    ppi.element,
                    ppi.button,
                    //"dos",
                ];

            } else {
                tmp = [
                    ppi.check,
                    ppi.folio,
                    ppi.name,
                    ppi.evaluation,
                    ppi.amount,
                    ppi.dependency,
                    ppi.created_at,
                    ppi.status,
                    ppi.executor,
                    ppi.comments,
                    ppi.element,

                ];
            }
            console.log("Contenido de tmp:", ppi);
            let color = ppi.color
            let colores = [
                color
            ];
            tabla_colores.push(colores);
            datatale.push(tmp);
            return true;
        });
        let columns = [];
        let columnDef = [];

        if (user.fk_role == 1) {
            columns = [
                { title: '' },
                { title: 'ID' },
                { title: 'Nombre' },
                { title: 'Evaluación Socioeconómica' },
                { title: 'Monto de Inversión' },
                { title: 'Dependencia' },
                { title: 'Fecha' },
                { title: 'Estatus' },
                { title: 'Responsable' },
                { title: 'Acciones' },
                { title: '' },
                { title: 'Registro' },


            ];



            columnDef = [
                {
                    targets: [0],
                    createdCell: (td, cellData, rowData, row, idx) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                            >
                                {Number(this.props.data[row].element.pk_status) < 7
                                    || (Number(this.props.data[row].element.pk_status) > 8 && Number(this.props.data[row].element.pk_status) < 11) &&
                                    <input type="checkbox" className="check-ppi" data-idx={this.props.data[row].element.pk_ppis} onChange={(e) => {
                                        this.props.handleCheckboxChange(this.props.data[row].element.pk_ppis);
                                    }} />}</div>, td
                        ),
                },
                {
                    targets: [1],
                    createdCell: (td, cellData, rowData, row) => ReactDOM.render(
                        <div>
                            <a
                                href={{
                                    4: `/ppi/choose-type/${cellData}`,
                                    5: `/ppi/format/Homologacion/${cellData}`,
                                    6: `/ppi/format/Homologacion/${cellData}`,

                                    8: `/ppi/approved_format/Homologacion_Aprobados/${cellData}`,
                                    13: `/ppi/approved_format/Homologacion_Aprobados/${cellData}`,
                                    11: `/ppi/choose-type/${cellData}`
                                }[this.props.data[row].element.pk_status]
                                }

                                /*href={this.props.data[row].element.pk_status === 5 ? /ppi/format/Homologacion/${cellData}
                                : /ppi/approved_format/Homologacion_Aprobados/${cellData}}  */
                                target="_blank"
                                onClick={(e) => {

                                    e.preventDefault();
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </a>
                        </div >,
                        td
                    ),
                },


                {
                    targets: [2],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title={cellData}
                            >
                                {this.sub(cellData)}
                            </div>, td
                        ),
                },
                {
                    targets: [3],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [4],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            > <NumerFormat value={Number.parseFloat(cellData).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} suffix={' MXN'} />  </div>, td
                        ),
                },
                {
                    targets: [5],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [6],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [7],
                    createdCell: (td, cellData, rowData, row, idx) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            > <MDBBadge color={tabla_colores[row]}>{cellData}</MDBBadge> </div>, td
                        ),
                },
                {
                    targets: [8],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [9],
                    createdCell: (td, cellData, rowData, row) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                            >
                                {(Number(this.props.data[row].element.pk_status) > 7
                                    && Number(this.props.data[row].element.pk_status) < 11)
                                    && (this.props.data[row].element.fk_responsable === null
                                        || Number(user.pk_user) == Number(this.props.data[row].element.fk_responsable)) &&
                                    <button type="checkbox" className="btn btn-secondary" data-idx={this.props.data[row].element.pk_ppis} onClick={(e) => {
                                        this.props.Reactivate(this.props.data[row].element.pk_ppis);
                                    }}> Reactivar </button>}
                            </div>, td
                        ),
                },
                {
                    targets: [10],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Tú folio tiene comentarios"
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [11],
                    createdCell: (td, cellData, rowData, row, idx) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}

                            >

                                {






                                    Number(this.props.data[row].element.pk_status) === 8 &&

                                    <button type="checkbox"
                                        className={this.props.data[row].element.Pei ? "btn btn-success" : "btn btn-primary"
                                            && this.props.data[row].element.solicitudes ? "btn btn-success" : "btn btn-primary"
                                                && this.props.data[row].element.observaciones ? "btn btn-success" : "btn btn-primary"
                                                    && this.props.data[row].element.oficiosCC ? "btn btn-success" : "btn btn-primary"}
                                        data-idx={this.props.data[row].element.pk_ppis} onClick={() => {


                                            this.props.linkOficio(rowData);

                                        }}>+</button>}</div>, td
                        ),
                },
                /*
                {
                    targets: [11],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            //Estatus aquí
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                  
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [12],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            //Estatus aquí
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                  
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },*/


            ]
        } else {
            columns = [
                { title: '' },
                { title: 'ID' },
                { title: 'Nombre' },
                { title: 'Evaluación Socioeconómica' },
                { title: 'Monto de Inversión' },
                { title: 'Dependencia' },
                { title: 'Fecha' },
                { title: 'Estatus' },
                { title: 'Responsable' },
                { title: '' }
            ];

            columnDef = [
                {
                    targets: [0],
                    createdCell: (td, cellData, rowData, row, idx) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                            >
                                {Number(this.props.data[row].element.pk_status) < 7 &&
                                    <input type="checkbox" className="check-ppi" data-idx={this.props.data[row].element.pk_ppis} onChange={(e) => {
                                        this.props.handleCheckboxChange(this.props.data[row].element.pk_ppis);
                                    }} />}</div>, td
                        ),
                },
                {
                    targets: [1],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [2],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title={cellData}
                            >
                                {this.sub(cellData)}
                            </div>, td
                        ),
                },
                {
                    targets: [3],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [4],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            > <NumerFormat value={Number.parseFloat(cellData).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$ '} suffix={' MXN'} />  </div>, td
                        ),
                },
                {
                    targets: [5],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [6],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [7],
                    createdCell: (td, cellData, rowData, row, idx) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            > <MDBBadge color={tabla_colores[row]}>{cellData}</MDBBadge> </div>, td
                        ),
                },
                {
                    targets: [8],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                            >
                                {cellData}
                            </div>, td
                        ),
                },
                {
                    targets: [9],
                    createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                                onClick={() => {
                                    this.props.handleClick(rowData);
                                }}
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Tú folio tiene comentarios"
                            >
                                {cellData}
                            </div>, td
                        ),
                }
            ]
        }

        var table = this.$el.DataTable(
            {
                data: datatale,
                columns: columns,
                language: {
                    "lengthMenu": "Mostrando _MENU_ registros por página",
                    "info": "Mostrando página _PAGE_ de _PAGES_",
                    "infoEmpty": "Sin registros encontrados",
                    "infoFiltered": "(filtrados de _MAX_ registros totales)",
                    "search": "Filtrar:",
                    "zeroRecords": "No se encontraron registros",
                    "paginate": {
                        "first": "Primero",
                        "last": "Último",
                        "next": "Siguiente",
                        "previous": "Anterior"
                    },
                },
                columnDefs: columnDef
            }
        );
        table.on('search.dt', function () {
            localStorage.setItem('SeachFilter', table.search());
        });
        if (localStorage.getItem('SeachFilter') != null && localStorage.getItem('SeachFilter') != "") {
            console.log('Filter', localStorage.getItem('SeachFilter'))
            table.search(localStorage.getItem('SeachFilter')).draw();
        }
    }

    filterTable = () => {
        // this.$el.DataTable
        console.log('Filter', 'Sellado')
    }

    handleClick = (element, status) => {
        let path;
        let user = JSON.parse(localStorage.getItem('user'));
        let Bandera = false;

        return;

        // if((element.pk_status === 1 || element.pk_status === 3) && user.fk_role === 1){
        if ((element.pk_status === 1 || element.pk_status === 3 || element.pk_status === 10 || element.pk_status === 9)) {
            path = `/ppi/${element.folio}`;
        }
        // if(element.pk_status === 2 && user.fk_role === 2){
        if (element.pk_status === 2) {
            path = `/ppi/edit-solicitude/${element.folio}`;
        }
        // if(element.pk_status === 4 && user.fk_role === 2){
        if (element.pk_status === 4) {
            path = `/ppi/choose-type/${element.folio}`;
        }
        // if(element.pk_status === 5 && user.fk_role === 2){


        if (element.pk_status === 5) {
            path = `/ppi/format/${element.fk_format}/${element.folio}`;
        }

        if (path === undefined) {
            return false;
        }



        if (element.pk_status === 8 && Bandera) {
            path = `/ppi/format/Homologacion_Aprobados/${element.folio}`;
            console.log("8")

            //< Route exact path = "/ppi/format/Homologacion_Aprobados/:id" component = { HomologacionAprobados } />
        }

        // Quitar validación en producción
        if (element.pk_status > 5 && element.pk_status < 9) {
            return false;
        }
        if (element.approved === null) {
            status = true;
        }

        this.setState({
            url: path,
            redirect: true,
            status: status,
            userRole: user.fk_role
        });
    }

    /*
     linkOficio = (element, status) => {
         
         let path;
         let user = JSON.parse(localStorage.getItem('user'));
 
         if (element.pk_status === 8 && user.fk_role === 1) {
             path = `/oficios/ppi/${element.folio}`;
 
         }
 
       
     }*/
    componentWillUnmount() {
    }
    render() {
        return (
            <div>
                <table className="display" width="100%" ref={el => this.el = el}></table>
            </div>
        );
    }
}


