import { Component } from 'react';

import './assets/NotaTecnica.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPaperclip,
    faPlusCircle,
    faTimesCircle,
    faDownload,
    faChevronUp,
    faExclamationTriangle,
    faCheck,
    faUndo,
    faCheckCircle,
    faFileAlt,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import { FormatMoney } from 'format-money-js';
import NumberFormat from 'react-number-format';
import $ from 'jquery';
import MyComponent from '../Map/MapOnlyRead';
import PlacesWithStandaloneSearchBox from '../Map/SearchBox';
import { Form, Table, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

// const mapUrl = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credentials.mapsKey}`;
export default class HomologacionAprobados extends Component {
    constructor(props) {
        super(props);

        this.state = {
            total_benefit_absolute: 0,
            total_benefit_absolute_actualizado: 0,
           
            total_benefit_1: 0,
            total_benefit_2: 0,
            tasa: 0,
            folio: this.props.id,
            vpn: 0,
            tir: 0,
            tri: 0,
            optimal_tri: 0,
            formato: 0,
            amount: 0,
            udis: 0,
            fecha: '',
            fecha_creacion:'',
            place: '',
            creador: '',
            aproved_name: '',
            showNombre: false,
            responsable: '',
            clave_cartera: '',
            applicant: '',
            rowsSources: [0],
            rowsCalendar: [0],
            rowsComponent: [0],
            rowsAlineacion: [0],
            rowsAlternativa: [0],
            archivos_nombre: [],
            ppi_evaluado: 0,
            rowOptimizationMeasures: [0],
            rowSourceIndex: 0,
            rowCalendarIndex: 0,
            rowComponentIndex: 0,
            limitRowsCalendar: 1,
            limitRowsComponent: 1,
            users: [],
            roles: [],
            cities: [],
            classifications: [],
            dependencies: [],
            funding_sources: [],
            funding_subsources: [],
            measurement_units: [],
            citySelected: 0,
            options_feasibilities: ['No', 'Sí', 'NA'],

            classificationSelected: 0,
            total_IX_amount: 0,
            name_ppi: '',
            alternative_justification: '',

            type_ppi: '',
            concepts: [],
            items: [],
            dependency_applicant: '',
            dependency_executor: 0,
            cantidadDecimales: [{ cantidadInversion: [{ cantidad: [], type: [] }], cantidadInversionALT: [{ cantidad: [], type: [] }], cantidadOperation: [{ cantidad: [], type: [] }], cantidadOperationALT: [{ cantidad: [], type: [] }], cantidadMaintenance: [{ cantidad: [], type: [] }], cantidadMaintenanceALT: [{ cantidad: [], type: [] }], cantidadexternalities: [{ cantidad: [], type: [] }], cantidadexternalitiesALT: [{ cantidad: [], type: [] }], total_cantidad: [{ cantidad: [], type: [] }], total_cantidadALT: [{ cantidad: [], type: [] }], total_cantidad2: [{ cantidad: [], type: [] }], total_cantidad2ALT: [{ cantidad: [], type: [] }] }],
            optimization_measures: [{ nom_alt: "", alternatives: "", totalCostVAT: 0 }],

            cost_quantification: [{ year: 0, inversion: '', operation: '', maintenance: '', externalities: '', total: 0, total_actualizado: 0 }],

            cost_quantification_alt2: [{ year: 0, inversion: '', operation: '', maintenance: '', externalities: '', total: 0, total_actualizado: 0 }],
            benefits_quantification: [{ year: 0, benefit_1: '', benefit_2: '', benefit_3: '', benefit_4: '', benefit_5: '', benefit_6: '', externalities: '', total: 0, total_beneficios: 0, total_neto: 0, tri: 0, blocked: false }],
            benefits_quantification_index: 0,


            founding_sources_origin: [{ origin: 0, percentage: '', amount: '', subsource: 0, other: '', subsourceFlag: false, otherFlag: false }],
            calendar_investment: [{ advance: '', physical: '', financial: '' }],
            calendar_index: 0,
            components: [{ name_concept: '', description_concept: '', unit_of_measure: 0, quantity: '', unit_price: '', total_amount: '', number_concept: 0, number_item: 0, description_item: '' }],
            alineacion_estrategica: [{ plan: '', eje: '', tema: '', objetivo: '', estrategia: '', linea: '' }],
            alternativas_solucion: [{ alternativa: "", ventajas: "", desventajas: "" }],
            data_sheet_extra_pre_investments: [{ study_name: '', type_of_study: '', estimated_date_completion: '', justification_realization: '', description: '', estimated_cost_amount: '' }],
            goals: [{ name_concept: '', unit_of_measure: '', quantity: '' }],
            total_physical: 0,
            total_financial: 0,
            total_financial_currency_format: 0,
            relationated_programs: '',
            objectives: '',
            action_lines: '',
            problem_description: '',
            description: '',
            current_situation_offer: '',
            demand_current_situation: '',
            intereaction_current_situation: '',
            general_description: '',
            objective_general: '',
            offer_ppi: '',
            //sal_disabled: '',
            demand_ppi: '',
            interaction_ppi: '',
            execution_result: '',
            beneficiaries: 0,
            vida_util: 0,
            executive_project: 0,
            executive_project_description: '',
            permissions: 0,
            permissions_description: '',
            enviromental_studies: 0,
            enviromental_studies_description: '',
            property_rights: 0,
            property_rights_description: '',
            numero_catastro: 0,
            catastro: '',
            final_comments: '',
            name_applicant: '',
            applicant_title: '',
            applicant_pin: '',
            executor_name: '',
            executor_title: '',
            pictures: '',
            executor_document: '',
            coordinatesData: [],
            observations_location: '',
            PicFile: null,
            pic_file_name: '',
            responsable_document: null,
            responsable_document_name: '',
            attachments: [],
            attachments_names: [],
            subtotal_components: 0,
            iva_components: 0,
            total_components: 0,
            total_percentage_funding: 0,
            total_amount_funding: 0,
            permission_admin: true,
            showImagesFile: false,
            imagesFile: null,
            showOficioFile: false,
            showPlanFile: false,
            planFile: null,
            oficioFile: null,
            showAttachment: false,
            attachment: null,
            scroll: true,
            showModal: false,
            showModalConfirm: false,
            showModalDeleteImage: false,
            selectedFile: '',
            titleModal: '',
            messageModal: '',
            updaterState: '',
            puesto: '',
            status_aproved : 0,
            asp_relevantlegal: '',
            tipoFormato: [],
            //Totales tabla de indicadores 
            //Cuantificacion de Costos del PPI (sin incluir IVA)
            total_inversion: 0,
            total_operation: 0,
            total_maintenance: 0,
            total_externalitie: 0,
            total_absolute: 0,
            total_absolute_actualizado: 0,
            alternativa: 0,

            //Cuantificación del PPI alternativa 2 (sin incluir IVA)
            total_inversion_alt2: 0,
            total_operation_alt2: 0,
            total_maintenance_alt2: 0,
            total_externalitie_alt2: 0,
            total_absolute_alt2: 0,
            total_absolute_actualizado_alt2: 0,



            general_information_approved: false,
            problem_description_approved: false,
            general_description_approved: false,
            objective_approved: false,
            exesution_results_approved: false,
            feasibilities_approved: false,
            general_considerations_approved: false,
            attachments_approved: false,




            commentsGIPFlag: false, //general_information
            commentsGIP: '', //general_information

            commentsPDPFlag: false, //problem_description
            commentsPDP: '', //problem_description

            commentsGDPFlag: false, //general_description
            commentsGDP: '', //general_description

            commentsObjectiveFlag: false, // Objective
            commentsObjective: '', // Objective

            commentsExecutionResultsFlag: false, // Execution_results
            commentsExecutionResults: '', // Execution_results

            commentsFPFlag: false, //feasibilities
            commentsFP: '', //feasibilities

            commentsGCPFlag: false, //general_considerations
            commentsGCP: '', //general_considerations

            commentsAPFlag: false, //attachments
            commentsAP: '', //attachments


            asa_offer_analysus: '',
            asa_offer_analysis: '',
            asa_interaction: '',
            ShowModalCancelCCConfirm: false,


            formatReadyToApprove: false,
        };
        this.api = window.$domain;
        this.environment = window.$environment;
        this.exports = window.$exports;

        this.handleCancelCC = this.handleCancelCC.bind(this);



    }


    async loadTipoFormato(url) {
        console.log("props", this.props);
        const { formato_PPI, id } = this.props;
      

        let Nombre = "";
        let adquisiciones = this.props.adquisiciones;
        let VerTablaIndicadorABC = "";
        let VerTablaIndicadorACE = "";

        //let bandera = true;

        
        if (this.state.formato === 1 || this.state.formato === 2) {
            Nombre = "Cedula";
        }
        if (this.state.formato === 2) {
            Nombre = "Cedula";
            adquisiciones = true;
        }
        if (this.state.formato === 3) {
            Nombre = "Nota Técnica";
        }
        if (this.state.formato === 4) {
            Nombre = "Nota Tecnica Adquisiciones";
            adquisiciones = true;
        }
        if (this.state.formato === 5) {
            Nombre = "Ficha Técnica";
        }
        if (this.state.formato === 5 && Number(this.props.monto) >= 30000000 && this.props.tipo === "Infraestructura económica") {
            Nombre = " Ficha Técnica Indicadores";
            VerTablaIndicadorABC = "";
            VerTablaIndicadorACE = "none";
        }


        if (this.state.formato === 7)
            Nombre = " Ficha Técnica Pre-inversión";
        adquisiciones = false;


        if (this.state.formato === 6) {
            Nombre = "Ficha Tecnica";
            adquisiciones = true;
        }
        if (this.state.formato === 8) {
            Nombre = "ACB";
            VerTablaIndicadorABC = "";
            VerTablaIndicadorACE = "none";
        }
        if (this.state.formato === 9) {
            Nombre = "ACB Adquisiciones";
            adquisiciones = true;
            VerTablaIndicadorABC = "";
            VerTablaIndicadorACE = "none";
        }
        if (this.state.formato === 10) {
            Nombre = "ACE";
            VerTablaIndicadorABC = "none";
            VerTablaIndicadorACE = "";
        }
        if (this.state.formato === 11) {
            Nombre = "ACE";
            adquisiciones = true;
            VerTablaIndicadorABC = "none";
            VerTablaIndicadorACE = "";
        }


        this.setState({ adquisiciones: adquisiciones, VerTablaIndicadorABC: VerTablaIndicadorABC, VerTablaIndicadorACE: VerTablaIndicadorACE });

        const path = `${url}/ppi/preview_format/${Nombre}`;

        try {
            const response = await axios.get(path, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const jsonArray = JSON.parse(response.data[0].id_sesion);
            const array = Object.values(jsonArray);

            return array;
        } catch (error) {
            console.error(error);
            return [];
        }
    }



    componentDidMount = async () => {
        let id = this.props.id;
        var user = JSON.parse(localStorage.getItem('user'));
        if (Number(user.fk_role) !== 1) {
            this.setState({
                permission_admin: false
            });
        }
        const fm = new FormatMoney({
            decimals: 2
        });

        if (this.state.scroll) {
            this.scrollToBottom();
        }
        this.scrollToBottom();

        const response = await axios.get(`${this.api}/ppi/loadApproved/${id}`);

        let cities = [];
        let classifications = [];
        let dependencies = [];
        let funding_sources = [];
        let funding_subsources = [];
        let funding_sources_ppi = [];
        let calendar_investment = [];
        let optimization_measures = [];
        let components = [];
        let data_sheet_extra_pre_investments = [];
        let alternativas_solucion = [];
        let goals = [];
        let measurement_units = [];
        let archivos_File = [];
        let coordinates = [];
        let users = [];
        let roles = [];
        let attachmentsFiles = [];
        let concepts = [];
        let items = [];
        let cities_per_analysis_cost = [];
        let puesto = '';
        let aproved_name = ''

        let data_sheet_extra_annex_costs = [];
        let data_sheet_extra_annex_benefits = [];
        let costQuantificationAlt2 = [];
        let alineacion_estrategica = [];
        let formato = response.data.info.fk_format;
        this.setState({ formato: formato });

        console.log("Nombre anterior", this.state.showNombre);
        response.data.users.map((us, idx) => {
            users.push({
                name: us.name,
                value: us.pk_user,
                nip: us.pin
            });
            return true;
        });


        response.data.roles.map((rl, idx) => {
            roles.push({
                name: rl.role_name,
                value: rl.pk_role
            });
            return true;
        });



        if (response.data.attachments.length > 0) {
            response.data.attachments.map((attachment) => {

                attachmentsFiles.push({
                    name: attachment.name,
                    id: attachment.pk_attachments,
                    size: attachment.file_size,
                    path: attachment.file
                });
                return true;
            });
        }
        if (response.data.estudio_socioeconomico.length > 0) {
            response.data.estudio_socioeconomico.map((archivo) => {

                archivos_File.push({
                    name: archivo.name,
                    id: archivo.pk_attachments,
                    size: archivo.file_size,
                    path: archivo.file
                });
                return true;
            });
        }

        if (response.data.data_sheet_extra_annex_costs.length > 0) {

            response.data.data_sheet_extra_annex_costs.map((row, idx) => {
                /*console.log("row.total",row.total_cost)
                console.log("typeof",typeof row.total_cost)
                console.log("data_seet",data_sheet_extra_annex_costs) */
                data_sheet_extra_annex_costs.push({

                    year: idx,
                    inversion: row.investment,
                    operation: row.operation,
                    maintenance: row.maintenance,
                    externalities: row.externalities,
                    total: 0,
                    total_actualizado: row.total_cost
                });
                return true;
            });

            setTimeout(() => {
                //this.calculateCostQuantification();
            }, 1000);
        }
        let total_ben1 = 0,
            total_ben2 = 0,
            total_bena = 0,
            total_actualizado = 0,
            total_bneto = 0;
        if (response.data.data_sheet_extra_annex_benefits.length > 0) {
            response.data.data_sheet_extra_annex_benefits.map((row, idx) => {
                data_sheet_extra_annex_benefits.push({
                    year: idx,
                    benefit_1: row.ben1,
                    benefit_2: row.ben2,
                    total: (row.ben1 + row.ben2),
                    total_beneficios: row.total_ben,
                    total_neto: row.current_ben,
                    tri: row.tri
                });

                total_ben1 += row.ben1;
                total_ben2 += row.ben2;
                total_bena += (row.ben1 + row.ben2);
                total_actualizado += row.total_ben;
                total_bneto += row.current_ben;
                return true;
            });

            total_actualizado = fm.from(total_actualizado);
            total_bneto = fm.from(total_bneto);
        }
        if (response.data.coordinates.length > 0) {
            response.data.coordinates.map((coordinate, idx) => {
                coordinates.push({
                    coords: {
                        name: coordinate.name,
                        address: coordinate.address,
                        lat: parseFloat(coordinate.latitude),
                        lng: parseFloat(coordinate.longitude),
                        comment: coordinate.annotation,
                        amount: response.data.info.amount,
                        visible: true
                    }, id: 'place_' + coordinate.latitude
                });
                return true;
            });
            setTimeout(() => {
                this.setState({
                    coordinatesData: coordinates,
                });
            }, 300);
        }

        if (response.data.data_sheet_extra_pre_investments.length > 0) {
            response.data.data_sheet_extra_pre_investments.map((programOrProyect, idx) => {

                data_sheet_extra_pre_investments.push({
                    study_name: programOrProyect.study_name,
                    type_of_study: programOrProyect.type_of_study,
                    estimated_date_completion: this.formatDate(programOrProyect.estimated_date_completion),
                    second_estimated_date_completion: this.formatDate(programOrProyect.second_estimated_date_completion),
                    justification_realization: programOrProyect.justification_realization,
                    description: programOrProyect.description,
                    estimated_cost_amount: programOrProyect.estimated_cost_amount,

                });
                return true;
            });
            setTimeout(() => {
                //  this.calculateDataSheet();
                // this.onTrigger();
            }, 200);
        }

        response.data.concepts.map((concept, idx) => {
            concepts.push({
                name: concept.description,
                value: concept.pk_concept
            });
            return true;
        });
        response.data.items.map((item, idx) => {
            items.push({
                name: item.description,
                value: item.pk_item
            });
            return true;
        });


        response.data.cities.map((city, idx) => {
            cities.push({
                name: city.name,
                value: city.pk_city
            });
            return true;
        });

        /*if (response.data.cities_per_analysis_cost.length > 0) {
            var count = 0;
            response.data.cities_per_analysis_cost.map((city, idx) => {
                if (count === 0) {
                    cities_per_analysis_cost.push({
                        city: city.fk_city,
                        delete: false
                    });
                } else {
                    cities_per_analysis_cost.push({
                        city: city.fk_city,
                        delete: true
                    });
                }
                count++;
                return true;
            });
        } else {
            cities_per_analysis_cost.push({ city: 0, delete: false });
        }*/

        response.data.classifications.map((classification, idx) => {
            classifications.push({
                name: classification.name,
                value: classification.pk_classification
            });
            return true;
        });
        response.data.dependencies.map((dependency, idx) => {
            dependencies.push({
                name: dependency.name,
                value: dependency.pk_dependency
            });
            return true;
        });
        response.data.funding_sources.map((funding_source, idx) => {
            funding_sources.push({
                name: funding_source.name,
                value: funding_source.pk_funding_source
            });
            return true;
        });
        response.data.funding_subsources.map((subsource) => {
            funding_subsources.push({
                name: subsource.name,
                value: subsource.pk_funding_subsources
            })
            return true;
        });
        response.data.measurement_units.map((unit) => {
            measurement_units.push({
                name: unit.name,
                value: unit.pk_measurement_unit
            })
            return true;
        });
        console.log("fuentes", response.data.funding_sources_ppi);
        if (response.data.funding_sources_ppi.length > 0) {
            console.log("fuentes", response.data.funding_sources_ppi);
            response.data.funding_sources_ppi.map((funding_source, idx) => {
                funding_sources_ppi.push({
                    origin: funding_source.fk_funding_sources,
                    percentage: funding_source.percentage,
                    amount: Number.parseFloat(funding_source.amount).toFixed(2),
                    subsourceFlag: funding_source.fk_funding_sources === 2 ? true : false,
                    subsource: funding_source.fk_funding_subsource,
                    otherFlag: funding_source.fk_funding_sources === 5 ? true : false,
                    other: funding_source.other,
                });
                return true;
            });
            setTimeout(() => {
                this.calculateTotalFunding();
                // this.onTrigger();
            }, 200);
        }


        if (response.data.components.length > 0) {
            response.data.components.map((component, idx) => {
                components.push({
                    name_concept: component.name,
                    description: component.description,
                    unit_of_measure: component.fk_measurement_unit,
                    quantity: component.quantity,
                    unit_price: Number.parseFloat(component.unit_price).toFixed(2),
                    total_amount: component.total_amount,
                    number_concept: component.concept_number,
                    description_concept: component.concept_description,
                    number_item: component.item_number,
                    description_item: component.item_description,
                });
                return true;
            });

            setTimeout(() => {
                //this.calculateComponents();
                //this.onTrigger();
            }, 200);
        }
        if (response.data.optimization_measures.length > 0) {
            response.data.optimization_measures.map((programOrProyect, idx) => {
                optimization_measures.push({
                    nom_alt: programOrProyect.nom_alt,
                    alternatives: programOrProyect.alternatives,
                    totalCostVAT: programOrProyect.totalCostVAT
                });
                return true;
            });
            setTimeout(() => {
                // this.onTrigger();
            }, 200);

        }




        response.data.calendar_investment.map((monthItem, idx) => {
            let newTotal = this.state.total_physical + Number(monthItem.physical);
            let newTotalFinancial = this.state.total_financial + Number(monthItem.financial);

            this.setState({
                total_physical: newTotal,
                total_financial: newTotalFinancial,
                total_financial_currency_format: fm.from(newTotalFinancial)
            });
            calendar_investment.push({
                advance: monthItem.advance,
                physical: monthItem.physical !== null ? monthItem.physical : 0,
                financial: monthItem.financial !== null ? monthItem.financial : 0
            });
            return true;
        });

        if (response.data.alineacion_estrategica.length > 0) {
            response.data.alineacion_estrategica.map((alineacion, idx) => {
                alineacion_estrategica.push({
                    plan: alineacion.plan,
                    eje: alineacion.eje,
                    tema: alineacion.tema,
                    objetivo: alineacion.objetivo,
                    estrategia: alineacion.estrategia,
                    linea: alineacion.linea
                });
                return true;
            });
            setTimeout(() => {

            }, 200);
        }

        if (response.data.alternativas_solucion.length > 0) {
            response.data.alternativas_solucion.map((solucion, idx) => {
                alternativas_solucion.push({
                    alternativa: solucion.alternativa,
                    ventajas: solucion.ventajas,
                    desventajas: solucion.desventajas,

                });
                return true;
            });
            setTimeout(() => {

            }, 200);
        }
        if (response.data.cost_quantification_alt2 && response.data.cost_quantification_alt2.length > 0) {
            response.data.cost_quantification_alt2.map((row, idx) => {
                costQuantificationAlt2.push({
                    year: idx,
                    inversion: row.investment,
                    operation: row.operation,
                    maintenance: row.maintenance,
                    externalities: row.externalities,
                    total: 0,
                    total_actualizado: row.total_cost
                });
                return true;
            });

            setTimeout(() => {
                //this.calculateCostQuantificationAlt2();
            }, 1000);
        }

        let fecha = response.data.info.created_at;
        fecha = fecha.split(" ")[0];

       let  fecha_creacion =  response.data.info.created_at;
       fecha_creacion = fecha_creacion.split(" ")[0];


        //let bandera = fecha > "2024-02-26" ? false : true;

        let tipoFormato = await this.loadTipoFormato(this.api);
        this.setState({ tipoFormato: tipoFormato });

        setTimeout(() => {
            console.log("estatus1", response.data.info.fk_status);
            this.setState({

                amount: response.data.info.amount,
                udis: response.data.info.udis,
                fecha: fecha,
                fecha_creacion : fecha_creacion < "2024-08-28" ? false : true,
                // bandera:bandera,
                cities: cities,
                responsable: response.data.info.fk_responsable,
                clave_cartera: response.data.info.clave_cartera,
                cost_quantification: data_sheet_extra_annex_costs,
                cost_quantification_alt2: costQuantificationAlt2,
                benefits_quantification: data_sheet_extra_annex_benefits,
                tasa: response.data.tasa,
                total_benefit_1: total_ben1,
                total_benefit_2: total_ben2,
                total_benefit_absolute: total_bena,
                total_benefit_absolute_actualizado: total_actualizado,
                vpn: response.data.info.annexed_vpn,
                tir: response.data.info.annexed_tir,
                tri: response.data.info.annexed_tri,
                optimal_tri: response.data.info.annexed_optimal_tri,

                users: users,
                roles: roles,
                ppi_evaluado: response.data.info.annexed_cae_pe,
                alternativa: response.data.info.annexed_cae_alternative,

                classifications: classifications,
                dependencies: dependencies,
                funding_sources: funding_sources,
                funding_subsources: funding_subsources,
                founding_sources_origin: funding_sources_ppi,
                components: components,
                alineacion_estrategica: alineacion_estrategica,
                alternativas_solucion: alternativas_solucion,
                goals: goals,
                measurement_units: measurement_units,
                calendar_investment: calendar_investment,
                id_responsable: response.data.info.fk_responsable,
                name_ppi: response.data.info.name_ppi || '',
                type_ppi: response.data.info.type_ppi || '',
                showImagesFile: response.data.info.pictures_url !== null ? true : false,

                //showNombre: response.data.info.previous_name !== null ? true:false,


                imagesFile: response.data.info.pictures_url,
                showOficioFile: response.data.info.document !== null ? true : false,
                oficioFile: response.data.info.document,
                showAttachment: response.data.info.file !== null ? true : false,
                concepts: concepts,
                items: items,
                attachment: null,
                attachments_names: attachmentsFiles,
                dependency_applicant: response.data.info.dependency_applicant || '',
                dependency_executor: response.data.info.dependency_executor,
                classificationSelected: response.data.info.classification,
                citySelected: response.data.cities_per_analysis_cost,
                observations_location: response.data.info.observations_location,
                relationated_program: response.data.info.relationated_programs || '',
                objectives: response.data.info.objectives || '',
                action_lines: response.data.info.action_lines || '',
                creador: response.data.info.fk_user,


                showNombre: response.data.info.fk_user === response.data.info.fk_user ? true : false,

                description: response.data.info.description || '',
                current_situation_offer: response.data.info.offer_analysis || '',
                demand_current_situation: response.data.info.analysis_of_demand || '',
                intereaction_current_situation: response.data.info.interaction || '',
                general_description: response.data.info.general_description || '',
                objective_general: response.data.info.general_objective || '',
                offer_ppi: response.data.info.offer_analysis_ppi || '',
                demand_ppi: response.data.info.analysis_of_demand_ppi || '',
                interaction_ppi: response.data.info.interaction_ppi || '',
                execution_result: response.data.info.execution_results || '',
                beneficiaries: response.data.info.ppi_beneficiaries || '',
                executive_project: response.data.info.executive_project,
                executive_project_description: response.data.info.executive_project_description || '',
                permissions: response.data.info.permissions,
                permissions_description: response.data.info.permissions_description || '',
                enviromental_studies: response.data.info.enviromental_studies,
                enviromental_studies_description: response.data.info.enviromental_studies_description || '',
                property_rights: response.data.info.property_rights,
                property_rights_description: response.data.info.property_rights_description || '',
                numero_catastro: response.data.info.numero_catastro || '',
                catastro: response.data.info.catastro || '',
                final_comments: response.data.info.final_comments || '',
                name_applicant: response.data.info.fk_user,
                applicant_title: response.data.info.fk_role,
                applicant_pin: response.data.info.approval_pin || '',
                executor_name: response.data.info.name_executor || '',
                executor_title: response.data.info.executor_title || '',
                showPlanFile: response.data.info.architecturalPlan_file !== null ? true : false,
                planFile: response.data.info.architecturalPlan_file,
                archivos_nombre: archivos_File,

                asa_offer_analysus: response.data.info.asa_offer_analysus || '',
                asa_offer_analysis: response.data.info.asa_offer_analysis || '',
                asa_interaction: response.data.info.asa_interaction || '',
                alternative_justification: response.data.info.alternative_justification || '',
                data_sheet_extra_pre_investments: data_sheet_extra_pre_investments,





                general_information_approved: response.data.info.status_gip === 1 ? true : false,
                problem_description_approved: response.data.info.status_pd === 1 ? true : false,
                general_description_approved: response.data.info.status_gdp === 1 ? true : false,
                objective_approved: response.data.info.objective_status === 1 ? true : false,
                exesution_results_approved: response.data.info.execution_results_status === 1 ? true : false,
                feasibilities_approved: response.data.info.status_fp === 1 ? true : false,
                general_considerations_approved: response.data.info.status_gc === 1 ? true : false,
                attachments_approved: response.data.info.status_a === 1 ? true : false,

                formatReadyToApprove: response.data.sections,
                puesto: response.data.info.cargopuesto,
                status_aproved: response.data.info.fk_status,
                aproved_name: response.data.info.aproved_name,
                vida_util: response.data.info.vida_util || '',
                optimization_measures: optimization_measures,
                asp_relevantlegal: response.data.info.asp_relevantlegal || '',

            });
            this.calculateComponents();
            this.calculateTotalFunding();
            this.calculateDataSheet();
            this.calculateCostQuantification();
            this.calculateCostQuantificationAlt2();
            this.calculateBenefitQuantification();
            /* this.calculateTIR();
             this.calculateTIRDecimals();
             this.calculateTIRDecimalsDos();
             this.calculateTIRDecimalsTres();*/

        }, 100);

        $(".loader").hide("fast", () => { });

    }
    handleCallback = (childData) => {
        this.setState({
            coordinatesData: childData
        });
        // this.onTrigger();
    }

    formatDate = (d) => {
        var data = d.split('-');
        return data[2] + "/" + data[1] + "/" + data[0];
    }


    scrollToBottom() { //Scroll to the bottom of a div
        $(document).scrollTop($(document)[0].scrollHeight);
    }
    handleScroll() { //Handle every scroll event
        let elem = $(document);
        if (elem !== undefined) {
            if ((elem[0].scrollHeight - elem.scrollTop()) > 1300) {
                this.setState({
                    scroll: false
                });
            }
            else {
                this.setState({
                    scroll: true
                })
            }
        }
    }
    handleClickUp = () => {
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".NotaTecnica").offset().top - 180
        }, 1000);
    }

    //handles clave cartera 
    handleCancelCC = () => {
        this.setState({
            ShowModalCancelCCConfirm: true
        });
    }

    handleCloseConfirmCancelCC = () => {
        this.setState({
            ShowModalCancelCCConfirm: false
        });
    }




    handleChangePlansFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];

        $(".loader").show("fast", () => { });
        nextState.plans = value;
        nextState.plans_name = value.name;
        this.setState(nextState);
        //this.onTrigger();

        const plansData = new FormData();
        if (nextState.plans) {
            plansData.append('plans', nextState.plans);

            axios.post(`${this.api}/ppi/plans/${this.props.id}`, plansData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    NotificationManager.success('Plano arquitectónico, corte transversal y/o render guardados correctamente', '¡Archivo guardado!');
                    setTimeout(() => {
                        $(".loader").hide("slow", () => { });
                    }, 1000);
                })
                .catch((error) => {
                    console.error(error)
                    setTimeout(() => {
                        $(".loader").hide("slow", () => { });
                    }, 1000);
                });
        }
    }

    //Cancelar clave cartera
     ConfirmDeleteClaveCC = () => {
        axios.put(`${this.api}/ppi/cancelCC/${this.props.id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if(response.status === 200){
                window.location.reload();
            }
        }).catch((error) => {
            console.error(error)
        });   
     }


    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
        //this.onTrigger();
    }


    onChangehandlerCity = (e) => {
        const idx = e.target.dataset.idx;
        const citySelected = this.state.citySelected;
        citySelected[idx].city = e.target.value;
        this.setState({ citySelected });
        //this.onTrigger();
    }

    onChangehandlerOrigin = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin;
        founding_sources_origin[idx].origin = e.target.value;
        if (Number(e.target.value) === 2) {
            founding_sources_origin[idx].subsourceFlag = true;
            founding_sources_origin[idx].subsource = 0;
        } else {
            founding_sources_origin[idx].subsourceFlag = false;
            founding_sources_origin[idx].subsource = 0;
        }

        if (Number(e.target.value) === 5) {
            founding_sources_origin[idx].otherFlag = true;
            founding_sources_origin[idx].other = '';
        } else {
            founding_sources_origin[idx].otherFlag = false;
            founding_sources_origin[idx].other = '';
        }
        setTimeout(() => {
            this.setState({ founding_sources_origin });
        }, 100);
        //this.onTrigger();
    }
    onChangehandlerSubsource = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin;
        founding_sources_origin[idx].subsource = e.target.value;
        setTimeout(() => {
            this.setState({ founding_sources_origin });
        }, 100);
        //this.onTrigger();
    }
    onChangehandlerOther = (e) => {
        const idx = Number(e.target.dataset.idx);
        const founding_sources_origin = this.state.founding_sources_origin;
        founding_sources_origin[idx].other = e.target.value;
        setTimeout(() => {
            this.setState({ founding_sources_origin });
        }, 100);
        //this.onTrigger();
    }
    onChangehandlerPercentage = (e) => {
        const idx = e.target.dataset.idx;
        const founding_sources_origin = this.state.founding_sources_origin;
        var regexp = /^[0-9,.\b]+$/;

        if (e.target.value === '' || regexp.test(e.target.value)) {
            let percentage = Number(e.target.value) / 100;
            let amount = percentage * this.state.amount;

            founding_sources_origin[idx].percentage = e.target.value;
            founding_sources_origin[idx].amount = Number.parseFloat(amount).toFixed(2);

            this.setState({ founding_sources_origin });

            //this.onTrigger();
        }
    }
    onChangehandlerAmount = (e) => {
        const idx = e.target.dataset.idx;
        const founding_sources_origin = this.state.founding_sources_origin;
        founding_sources_origin[idx].amount = e.target.value;
        this.setState({ founding_sources_origin });

        // this.onTrigger();
    }
    onChangehandlerAdvance = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment;
        calendar_investment[idx].advance = e.target.value;
        this.setState({
            calendar_investment
        });
        //this.onTrigger();
    }
    onChangehandlerPhysical = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment;
        var regexp = /^[0-9.\b]+$/;
        if (e.target.value === '' || regexp.test(e.target.value)) {
            calendar_investment[idx].physical = e.target.value;
            this.setState({
                calendar_investment
            });
            this.calculateInvestment();
            // this.onTrigger();
        }
    }
    onChangehandlerFinancial = (e) => {
        const idx = e.target.dataset.idx;
        const calendar_investment = this.state.calendar_investment;
        calendar_investment[idx].financial = e.target.value;

        setTimeout(() => {
            this.setState({
                calendar_investment
            });
            this.calculateInvestment();
            //this.onTrigger();
        }, 100);
    }
    onChangehandlerNameConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].name_concept = e.target.value;
        this.setState({
            components
        });
        //   this.onTrigger();
    }
    onChangehandlerAlineacion = (e) => {
        const idx = e.target.dataset.idx;
        const alineacion = this.state.alineacion;
        alineacion[idx].plan = e.target.value;
        this.setState({
            alineacion
        });
        //   this.onTrigger();
    }

    /* onChangehandlerAlineacionEje = (e) => {
         const idx = e.target.dataset.idx;
         const { alineacion } = this.state;
         const updatedAlineacion = [...alineacion];  // Crear una copia del arreglo
         updatedAlineacion[idx] = {
             ...updatedAlineacion[idx],  // Crear una copia del objeto en el índice
             eje: e.target.value
         };
         
         this.setState({
             alineacion: updatedAlineacion
         }, () => {
             this.onTrigger();  // Llamar a onTrigger después de que el estado se haya actualizado
         });
     }*/

    onChangehandlerAlineacion_ = (e) => {
        const idx = e.target.dataset.idx;
        const property = e.target.name;
        const value = e.target.value;


        this.setState(prevState => {
            const updatedAlineacion = [...prevState.alineacion_estrategica];
            const updatedItem = { ...updatedAlineacion[idx], [property]: value };
            updatedAlineacion[idx] = updatedItem;


            return {
                alineacion_estrategica: updatedAlineacion
            };
        }, () => {
            //         this.onTrigger();
        });
    }

    onChangehandlerAlternativas_ = (e) => {
        const idx = e.target.dataset.idx;
        const property = e.target.name;
        const value = e.target.value;

        this.setState(prevState => {
            const updatedAlternativa = [...prevState.alternativas_solucion];
            const updatedItems = { ...updatedAlternativa[idx], [property]: value };
            updatedAlternativa[idx] = updatedItems;

            console.log()
            return {
                alternativas_solucion: updatedAlternativa
            };
        }, () => {
            //            this.onTrigger();
        });

    }


    onChangehandlerNumberConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].number_concept = e.target.value;

        this.state.concepts.map((concept, idxT) => {
            if (Number(e.target.value) === concept.value) {
                components[idx].description_concept = concept.name;
            }
            return true;
        });
        this.setState({
            components
        });
        // this.calculateInvestment();
        //        this.onTrigger();
    }
    onChangehandlerDescConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].description_concept = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        //      this.onTrigger();
    }
    onChangehandlerNumberItem = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].number_item = e.target.value;
        this.state.items.map((item, idxT) => {
            if (Number(e.target.value) === item.value) {
                components[idx].description_item = item.name;
            }
            return true;
        });
        this.setState({
            components
        });
        //     this.onTrigger();
    }
    onChangehandlerDescItem = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].description_item = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        //   this.onTrigger();
    }
    onChangehandlerDescriptionConcept = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].description = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        //   this.onTrigger();
    }
    onChangehandlerUnitOfMeasure = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].unit_of_measure = e.target.value;
        this.setState({
            components
        });
        // this.calculateInvestment();
        //   this.onTrigger();
    }
    onChangehandlerQuantityComponent = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        var regexp = /^[0-9,.\b]+$/;

        if (e.target.value === '' || regexp.test(e.target.value)) {
            components[idx].quantity = e.target.value;
            this.setState({
                components
            });
            this.calculateTotalComponent(idx);
            //this.calculateComponents();
            //            this.onTrigger();
        }
    }
    onChangehandlerUnitPrice = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        var regexp = /^[0-9,.\b]+$/;

        if (e.target.value === '' || regexp.test(e.target.value)) {
            components[idx].unit_price = e.target.value;
            this.setState({
                components
            });
            this.calculateTotalComponent(idx);
            //this.calculateComponents();
            //            this.onTrigger();
        }
    }
    onChangehandlerTotalAmount = (e) => {
        const idx = e.target.dataset.idx;
        const components = this.state.components;
        components[idx].total_amount = e.target.value;
        this.setState({
            components
        });
        // this.calculateTotalComponent(idx);
        //this.calculateComponents();
        //      this.onTrigger();
    }
    onChangehandlerNameConceptGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals;
        goals[idx].name_concept = e.target.value;
        this.setState({
            goals
        });
        //    this.onTrigger();
    }
    onChangehandlerUnitMeasureGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals;
        goals[idx].unit_of_measure = e.target.value;
        this.setState({
            goals
        });
        //   this.onTrigger();
    }
    onChangehandlerQuantityGoal = (e) => {
        const idx = e.target.dataset.idx;
        const goals = this.state.goals;
        goals[idx].quantity = e.target.value;
        this.setState({
            goals
        });
        //    this.onTrigger();
    }
    onChangehandlerPIN = (e) => {
        let applicant_pin = e.target.value;
        var regexp = /^[0-9\b]+$/;

        if (applicant_pin === '' || regexp.test(applicant_pin)) {
            this.setState({
                applicant_pin
            });
        }
        //        this.onTrigger();
    }
    onChangehandlerBeneficiaries = (e) => {
        let beneficiaries = e.target.value;
        this.setState({
            beneficiaries
        });
        //      this.onTrigger();
    }
    onChangeOptimizationMeasuresAlternatives = (e) => {
        const idx = Number(e.target.dataset.idx);
        const optimization_measures = this.state.optimization_measures;
        optimization_measures[idx].alternatives = e.target.value;
        setTimeout(() => {
            this.setState({ optimization_measures });
        }, 100);
        //    this.onTrigger();
    }
    onChangeOptimizationMeasuresTotalCostVAT = (e) => {
        const idx = Number(e.target.dataset.idx);
        const optimization_measures = this.state.optimization_measures;
        optimization_measures[idx].totalCostVAT = e.target.value;
        setTimeout(() => {
            this.setState({ optimization_measures });
        }, 100);
        //   this.onTrigger();
    }

    //Calculo de totales tabla 1
    calculateCostQuantification() {
        var cost_quantification = [...this.state.cost_quantification];

        let total_inversion = 0, total_operation = 0, total_maintenance = 0, total_externalitie = 0, total_absolute = 0, total_absolute_actualizado = 0;

        const fm = new FormatMoney({
            decimals: 2
        });

        this.state.cost_quantification.map((cost, idx) => {
            let total = 0;
            if (typeof cost.inversion == 'string' && cost.inversion.length > 0) {
                total += parseFloat(cost.inversion.replaceAll(',', ''));
                total_inversion += parseFloat(cost.inversion.replaceAll(',', ''));
            } else if (cost.inversion.length > 0 || cost.inversion !== 0) {
                total += parseFloat(cost.inversion);
                total_inversion += parseFloat(cost.inversion);
            }

            if (typeof cost.operation == 'string' && cost.operation.length > 0) {
                total += parseFloat(cost.operation.replaceAll(',', ''));
                total_operation += parseFloat(cost.operation.replaceAll(',', ''));
            } else if (cost.operation.length > 0 || cost.operation !== 0) {
                total += parseFloat(cost.operation);
                total_operation += parseFloat(cost.operation);
            }

            if (typeof cost.maintenance == 'string' && cost.maintenance.length > 0) {
                total += parseFloat(cost.maintenance.replaceAll(',', ''));
                total_maintenance += parseFloat(cost.maintenance.replaceAll(',', ''));
            } else if (cost.maintenance.length > 0 || cost.maintenance !== 0) {
                total += parseFloat(cost.maintenance);
                total_maintenance += parseFloat(cost.maintenance);
            }

            if (typeof cost.externalities == 'string' && cost.externalities.length > 0) {
                total += parseFloat(cost.externalities.replaceAll(',', ''));
                total_externalitie += parseFloat(cost.externalities.replaceAll(',', ''));
            } else if (cost.externalities.length > 0 || cost.externalities !== 0) {
                total += parseFloat(cost.externalities);
                total_externalitie += parseFloat(cost.externalities);
            }

            let anio = idx;
            let percenta_discount = 1 + (this.state.tasa / 100);
            let total_actualizado = (total / (Math.pow(percenta_discount, anio))).toFixed(2);
            let total_temp_actualizado = 0;
            /* console.log("total_actulizado____putos", cost_quantification[idx].total_actualizado)
             console.log("total_actualizado",isNaN(total_actualizado) ? 0 : fm.from(parseFloat(total_actualizado)))
             console.log("total_actualizado",typeof cost_quantification[idx].total_actualizado)*/
            total_temp_actualizado += parseFloat(cost_quantification[idx].total_actualizado)

            cost_quantification[idx].total = isNaN(total) ? 0 : fm.from(total);
            // cost_quantification[idx].total_actualizado = isNaN(total_actualizado) ? 0 : fm.from(parseFloat(total_actualizado));


            total_absolute += total;
            total_absolute_actualizado += parseFloat(total_temp_actualizado);

            return true;
        });

        total_inversion = fm.from(total_inversion)
        total_operation = fm.from(total_operation)
        total_maintenance = fm.from(total_maintenance)
        total_externalitie = fm.from(total_externalitie)
        total_absolute = fm.from(total_absolute)
        total_absolute_actualizado = fm.from(parseFloat(total_absolute_actualizado))

        setTimeout(() => {
            this.setState({ cost_quantification, total_inversion, total_operation, total_maintenance, total_externalitie, total_absolute, total_absolute_actualizado });
        }, 100);

    }

    //Calculate tabla 2
    calculateCostQuantificationAlt2() {
        var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];

        let total_inversion_alt2 = 0, total_operation_alt2 = 0, total_maintenance_alt2 = 0, total_externalitie_alt2 = 0, total_absolute_alt2 = 0, total_absolute_actualizado_alt2 = 0;

        const fm = new FormatMoney({
            decimals: 2
        });

        this.state.cost_quantification_alt2.map((cost, idx) => {
            let total = 0;
            if (typeof cost.inversion == 'string' && cost.inversion.length > 0) {
                total += parseFloat(cost.inversion.replaceAll(',', ''));
                total_inversion_alt2 += parseFloat(cost.inversion.replaceAll(',', ''));
            } else if (cost.inversion.length > 0 || cost.inversion !== 0) {
                total += parseFloat(cost.inversion);
                total_inversion_alt2 += parseFloat(cost.inversion);
            }

            if (typeof cost.operation == 'string' && cost.operation.length > 0) {
                total += parseFloat(cost.operation.replaceAll(',', ''));
                total_operation_alt2 += parseFloat(cost.operation.replaceAll(',', ''));
            } else if (cost.operation.length > 0 || cost.operation !== 0) {
                total += parseFloat(cost.operation);
                total_operation_alt2 += parseFloat(cost.operation);
            }

            if (typeof cost.maintenance == 'string' && cost.maintenance.length > 0) {
                total += parseFloat(cost.maintenance.replaceAll(',', ''));
                total_maintenance_alt2 += parseFloat(cost.maintenance.replaceAll(',', ''));
            } else if (cost.maintenance.length > 0 || cost.maintenance !== 0) {
                total += parseFloat(cost.maintenance);
                total_maintenance_alt2 += parseFloat(cost.maintenance);
            }

            if (typeof cost.externalities == 'string' && cost.externalities.length > 0) {
                total += parseFloat(cost.externalities.replaceAll(',', ''));
                total_externalitie_alt2 += parseFloat(cost.externalities.replaceAll(',', ''));
            } else if (cost.externalities.length > 0 || cost.externalities !== 0) {
                total += parseFloat(cost.externalities);
                total_externalitie_alt2 += parseFloat(cost.externalities);
            }

            let anio = idx;
            let percenta_discount = 1 + (this.state.tasa / 100);
            let total_actualizado = (total / (Math.pow(percenta_discount, anio))).toFixed(2);
            let total_temp_actualizado = 0;


            cost_quantification_alt2[idx].total = isNaN(total) ? 0 : fm.from(total);
            total_temp_actualizado += parseFloat(cost_quantification_alt2[idx].total_actualizado)
            // cost_quantification_alt2[idx].total_actualizado = isNaN(total) ? 0 : fm.from(parseFloat(total_actualizado));

            total_absolute_alt2 += total;
            total_absolute_actualizado_alt2 += parseFloat(total_temp_actualizado);

            return true;
        });

        total_inversion_alt2 = fm.from(total_inversion_alt2)
        total_operation_alt2 = fm.from(total_operation_alt2)
        total_maintenance_alt2 = fm.from(total_maintenance_alt2)
        total_externalitie_alt2 = fm.from(total_externalitie_alt2)
        total_absolute_alt2 = fm.from(total_absolute_alt2)
        total_absolute_actualizado_alt2 = fm.from(parseFloat(total_absolute_actualizado_alt2))

        setTimeout(() => {
            this.setState({ cost_quantification_alt2, total_inversion_alt2, total_operation_alt2, total_maintenance_alt2, total_externalitie_alt2, total_absolute_alt2, total_absolute_actualizado_alt2 });
        }, 100);

    }

    //Calculate beneficios 
    calculateBenefitQuantification = async (checkVPN = true) => {

        var benefits_quantification = [...this.state.benefits_quantification];
        let total_benefit_1 = 0, total_benefit_2 = 0, total_benefit_absolute = 0;
        let total_benefit_absolute_actualizado = 0, total_benefit_absolute_neto = 0;

        const fm = new FormatMoney({
            decimals: 2
        });

        const cost = this.state.cost_quantification;

        let total_cost_actualizado = 0;

        cost.map((costo, idx) => {
            total_cost_actualizado += Number(costo.total_actualizado.toString().replace(/,/g, ''))
            return true;
        });

        this.state.benefits_quantification.map((benefit, idx) => {
            let total = 0;

            if (typeof benefit.benefit_1 === 'string' && benefit.benefit_1.length > 0) {
                total += parseFloat(benefit.benefit_1.replaceAll(',', ''));
                total_benefit_1 += parseFloat(benefit.benefit_1.replaceAll(',', ''));
            } else if (benefit.benefit_1.length > 0 || benefit.benefit_1 !== 0) {
                total += !isNaN(parseFloat(benefit.benefit_1)) ? parseFloat(benefit.benefit_1) : 0;
                total_benefit_1 += !isNaN(parseFloat(benefit.benefit_1)) ? parseFloat(benefit.benefit_1) : 0;
            }


            if (typeof benefit.benefit_2 === 'string' && benefit.benefit_2.length > 0) {
                total += parseFloat(benefit.benefit_2.replaceAll(',', ''));
                total_benefit_2 += parseFloat(benefit.benefit_2.replaceAll(',', ''));
            } else if (benefit.benefit_2.length > 0 || benefit.benefit_2 !== 0) {
                total += !isNaN(parseFloat(benefit.benefit_2)) ? parseFloat(benefit.benefit_2) : 0;
                total_benefit_2 += !isNaN(parseFloat(benefit.benefit_2)) ? parseFloat(benefit.benefit_2) : 0;
            }

            let anio = idx;
            let percenta_discount = 1 + (this.state.tasa / 100);
            let total_actualizado = (total / (Math.pow(percenta_discount, anio))).toFixed(2);
            let neto = 0;

            if (cost[idx]) {
                let previo = cost[idx].total;
                neto = total - Number(previo);
            }

            if (Number(benefit.benefit_1) == 0 && Number(benefit.benefit_2) == 0) {
                neto = 0;
            }


            benefits_quantification[idx].total = isNaN(total) ? 0 : fm.from(total);
            benefits_quantification[idx].total_beneficios = isNaN(total_actualizado) ? 0 : fm.from(parseFloat(total_actualizado));
            benefits_quantification[idx].total_neto = isNaN(neto) ? 0 : fm.from(parseFloat(neto));


            total_benefit_absolute += isNaN(parseFloat(total)) ? 0 : parseFloat(total);
            total_benefit_absolute_actualizado += isNaN(parseFloat(total_actualizado)) ? 0 : parseFloat(total_actualizado);
            total_benefit_absolute_neto += isNaN(parseFloat(neto)) ? 0 : parseFloat(neto);

            let tri = 0;


            if (Number(benefits_quantification[idx].benefit_1.toString().replace(/,/g, '')) > 0) {

                /* TRI */
                let start = idx - 1;

                if (start < 0) {
                    start = 0;
                }
                let sumatoria_ciclica = 0;
                let anio_pow = 0;
                let tasa = (this.state.tasa / 100);

                sumatoria_ciclica += cost[start] ? Number(cost[start].inversion.toString().replace(/,/g, '')) : 0;
                if (start - 1 >= 0) {
                    for (var i = start - 1; i >= 0; i--) {
                        anio_pow++;
                        let percent = Math.pow((1 + Number(tasa)), anio_pow);
                        let final_result = Number(cost[i].inversion.toString().replace(/,/g, '')) * percent;
                        sumatoria_ciclica += final_result;
                    }
                    let ben_neto = Number(benefits_quantification[idx].total_neto.toString().replace(/,/g, ''));

                    tri = ben_neto / sumatoria_ciclica;
                } else {
                    let ben_neto = Number(benefits_quantification[idx].total_neto.toString().replace(/,/g, ''));
                    tri = ben_neto / sumatoria_ciclica;
                }

                tri = (Number(tri) * 100).toFixed(2);
                benefits_quantification[idx].tri = tri;
                /* TERMINA TRI */
            } else {
                tri = 0;
                benefits_quantification[idx].tri = tri;
            }

            return true;
        });

        let flag = false;


        this.state.benefits_quantification.map((ben, idx) => {
            if (Number(ben.benefit_1.toString().replace(/,/g, '')) > 0 && !flag) {
                if (Number(ben.tri) > 0) {
                    this.setState({
                        tri: ben.tri,
                        optimal_tri: idx
                    });
                    flag = true;
                }
            }
            return true;
        });

        var vpnTemp = this.state.vpn
        var tirTemp = this.state.tir
        var triTemp = this.state.tri
        let optimalTriTemp = this.state.optimal_tri

        console.log('vpn', vpnTemp)
        console.log('tir', tirTemp)
        console.log('tri', triTemp)
        console.log('optimalTriTemp', optimalTriTemp)

        let vpn = this.state.vpn


        if (!checkVPN) {
            vpn = total_benefit_absolute_actualizado - total_cost_actualizado;
            vpn = parseFloat(vpn).toFixed(2);
        }

        let tir = 0, tir_decimal = 0, tir_decimaldos = 0;
        let tir_decimaltre = 0;

        if (Number(total_benefit_absolute.toString().replace(/,/g, '')) > 0) {
            tir = await this.calculateTIR(this.state.tasa);
            tir_decimal = await this.calculateTIRDecimals(tir);
            tir_decimaldos = await this.calculateTIRDecimalsDos(tir_decimal);
            tir_decimaltre = await this.calculateTIRDecimalsTres(tir_decimaldos);
        }

        tir_decimaltre = parseFloat(tir_decimaltre).toFixed(2);

        total_benefit_1 = fm.from(total_benefit_1)
        total_benefit_2 = fm.from(total_benefit_2)

        total_benefit_absolute = fm.from(total_benefit_absolute)
        total_benefit_absolute_actualizado = fm.from(total_benefit_absolute_actualizado)
        total_benefit_absolute_neto = fm.from(total_benefit_absolute_neto)


        // this.setState({benefits_quantification, total_benefit_1, total_benefit_2, total_benefit_3, total_benefit_4, total_benefit_5, total_benefit_6,total_benefit_externalitie, total_benefit_absolute});

        this.setState({
            benefits_quantification,
            total_benefit_1,
            total_benefit_2,
            total_benefit_absolute,
            total_benefit_absolute_actualizado,
            total_benefit_absolute_neto,
            tir: tir_decimaltre,
            vpn
        });

        console.log('this.state.vpn', this.state.vpn)
        console.log('this.state.tir', this.state.tir)
        console.log('this.state.tri', this.state.tri)
        console.log('this.state.optimal_tri', this.state.optimal_tri)


        if (!checkVPN) {
            console.log("CHEVPN");
            this.setState({
                vpn: vpnTemp,
                tir: tirTemp,
                tri: triTemp,
                optimal_tri: optimalTriTemp
            })
        };





        setTimeout(() => {
            /* CALCULO CAE */
            let resultado_anualidad = 0, resultado_anualidad_alt = 0;
            let costo_actualizado = this.state.total_absolute_actualizado;
            if (costo_actualizado !== undefined) {
                let tasa = (this.state.tasa / 100);
                let anios = this.state.cost_quantification.length - 1;
                let percent = Math.pow((1 + Number(tasa)), anios);
                let multiplica_tasa = tasa * percent;
                let resta_tasa = percent - 1;
                let resultado_division = multiplica_tasa / resta_tasa;
                resultado_anualidad = Number(costo_actualizado.toString().replaceAll(',', '')) * resultado_division;
            }
            /**************/


            /* CALCULO CAE ALTERNATIVA */
            let costo_actualizado_alternativa = this.state.total_absolute_actualizado_alt2;
            console.log("costo", costo_actualizado_alternativa);

            if (costo_actualizado_alternativa !== undefined) {

                let tasa = (this.state.tasa / 100);
                let anios = this.state.cost_quantification_alt2.length - 1;
                let percent = Math.pow((1 + Number(tasa)), anios);
                let multiplica_tasa = tasa * percent;
                let resta_tasa = percent - 1;
                let resultado_division = multiplica_tasa / resta_tasa;
                resultado_anualidad_alt = Number(costo_actualizado_alternativa.toString().replaceAll(',', '')) * resultado_division;

                console.log("resultado anulaidad", resultado_anualidad_alt);
                console.log("Resultado division", resultado_division);
                console.log("multi", multiplica_tasa);
                console.log("resta", resta_tasa);
                console.log("percent", percent);
                console.log("tasa", this.state);
                console.log("año", anios);



            }
            /**************/

            this.setState({
                ppi_evaluado: fm.from(resultado_anualidad),
                alternativa: fm.from(resultado_anualidad_alt),

            })

        }, 200);
    }

    async calculateTIR(tasa) {
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa) + 1);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = Number(costo.total.toString().replaceAll(',', ''));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);
            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if (Number(beneficio.benefit_1) > 0) {
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);

                total_ben = Number(beneficio.total.toString().replaceAll(',', '')).toFixed(2);
                total_actualizado = (total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                sum_ben_actualizados += parseFloat(total_actualizado);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);

        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if (parseFloat(nueva_vpn) > 0 && nueva_tasa < 100) {
            return await this.calculateTIR(nueva_tasa);
        } else {
            return tasa;
        }
    }
    async calculateTIRDecimals(tasa) {
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa) + 0.1);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.inversion.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);

            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if (Number(beneficio.benefit_1.toString().replaceAll(',', '')) > 0) {
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);

                total_ben = (Number(beneficio.benefit_1.toString().replaceAll(',', '')) + Number(beneficio.benefit_2.toString().replaceAll(',', ''))).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;
                sum_ben_actualizados += parseFloat(total_actualizado * 1);
            }
            return true;
        });

        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if (nueva_vpn > 0) {
            return await this.calculateTIRDecimals(nueva_tasa);
        } else {
            return tasa.toFixed(2);
        }
    }
    async calculateTIRDecimalsDos(tasa) {
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa) + 0.01);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.inversion.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);
            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if (Number(beneficio.benefit_1.toString().replaceAll(',', '')) > 0) {
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);

                total_ben = (Number(beneficio.benefit_1.toString().replaceAll(',', '')) + Number(beneficio.benefit_2.toString().replaceAll(',', ''))).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;

                sum_ben_actualizados += parseFloat(total_actualizado * 1);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if (nueva_vpn > 0) {
            return await this.calculateTIRDecimalsDos(nueva_tasa);
        } else {
            return tasa;
        }
    }
    async calculateTIRDecimalsTres(tasa) {
        let sum_costos_actualizados = 0, sum_ben_actualizados = 0;
        let nueva_tasa = (Number(tasa) + 0.001);

        this.state.cost_quantification.map((costo, idx) => {
            let total_costo = 0, total_actualizado = 0;
            let percent_discount = 1 + (nueva_tasa / 100);

            total_costo = (Number(costo.inversion.toString().replaceAll(',', '')) + Number(costo.operation.toString().replaceAll(',', '')) + Number(costo.maintenance.toString().replaceAll(',', '')) + Number(costo.externalities.toString().replaceAll(',', '')));
            total_actualizado = (total_costo / (Math.pow(percent_discount, idx))).toFixed(2);


            sum_costos_actualizados += parseFloat(total_actualizado);
            return true;
        });

        this.state.benefits_quantification.map((beneficio, idx) => {
            if (Number(beneficio.benefit_1.toString().replaceAll(',', '')) > 0) {
                let total_ben = 0, total_actualizado = 0;
                let percent_discount = 1 + (nueva_tasa / 100);

                total_ben = (Number(beneficio.benefit_1.toString().replaceAll(',', '')) + Number(beneficio.benefit_2.toString().replaceAll(',', ''))).toFixed(2);
                total_actualizado = Number(total_ben / (Math.pow(percent_discount, idx))).toFixed(2);
                total_actualizado = total_actualizado * 1;

                sum_ben_actualizados += parseFloat(total_actualizado * 1);
            }
            return true;
        });
        sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
        sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
        let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

        if (nueva_vpn > 0) {
            return await this.calculateTIRDecimalsTres(nueva_tasa);
        } else {
            this.setState({
                annexed_tir: tasa
            });
            return tasa;
        }
    }

    //Calculate-preinversion
    calculateDataSheet = async (index) => {
        const fm = new FormatMoney({
            decimals: 2
        });


        const data_sheet_extra_pre_investments = this.state.data_sheet_extra_pre_investments;

        // let idx = Number(index);
        let amount = 0, amount1 = 0, amount2 = 0, amount8 = 0, amount9 = 0, amount10 = 0,
            amount11 = 0, amount12 = 0, amount13 = 0, amount14 = 0, amount16 = 0, amount17 = 0, amount18 = 0;



        data_sheet_extra_pre_investments.map((funding) => {
            amount14 += Number(funding.estimated_cost_amount.toString().replaceAll(',', ''));
            return true;
        });


        setTimeout(() => {
            this.setState({


                total_IX_amount: fm.from(amount14),

            });
            //this.onTrigger();
        }, 100)
    }

    calculateTotalFunding = (index) => {

        const fm = new FormatMoney({
            decimals: 2
        });
        const founding_sources_origin = this.state.founding_sources_origin;
        let percentage = 0, amount = 0;
        founding_sources_origin.map((funding) => {
            percentage += Number(funding.percentage);
            amount += Number(funding.amount.toString().replaceAll(',', ''));
            return true;
        });
        setTimeout(() => {
            this.setState({
                total_percentage_funding: percentage,
                total_amount_funding: fm.from(amount)
            });
            //            this.onTrigger();
        }, 100)

        console.log("calculte", percentage);
        console.log("amount", amount);

    }

    calculateTotalComponent = (index) => {
        const components = this.state.components;
        let idx = Number(index);
        if (components[idx].quantity !== undefined && components[idx].unit_price !== undefined) {
            if (Number(components[idx].quantity.toString().replaceAll(',', '')) && Number(components[idx].unit_price.toString().replaceAll(',', ''))) {
                components[idx].total_amount = Number(components[idx].quantity.toString().replaceAll(',', '')) * Number(components[idx].unit_price.toString().replaceAll(',', ''));
            } else {
                return false;
            }
            this.setState({
                components
            });
            //            this.onTrigger();
        }
    }

    calculateComponents = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let subtotal = 0;
        this.state.components.map((element) => {
            if (element.quantity !== "" && element.unit_price !== "") {
                subtotal += element.total_amount
            }
            return true;
        });

        this.setState({
            subtotal_components: fm.from(subtotal),
            iva_components: fm.from((subtotal * 0.16)),
            total_components: fm.from((subtotal * 1.16))
        }, () => {
            this.forceUpdate();
        });
        //        this.onTrigger();
    }

    calculateInvestment = () => {
        const fm = new FormatMoney({
            decimals: 2
        });
        let newTotal = 0, newTotalFinancial = 0;

        this.state.calendar_investment.map((element) => {
            newTotal += Number(element.physical);
            newTotalFinancial += Number(element.financial.toString().replaceAll(',', ''));
            return true;
        });

        this.setState({
            total_physical: newTotal,
            total_financial: newTotalFinancial,
            total_financial_currency_format: fm.from(newTotalFinancial)
        });
        //        this.onTrigger();
    }

    /* Handlers de clicks para abrir ventanas de selección de archivos */
    handleInputFileClick = (e) => {
        this.fileInput.click();
    }
    architecturalPlanfile = (e) => {
        this.planfileInput.click();
    }
    handleInputFileOficioClick = (e) => {
        this.fileOficioInput.click();
    }
    handleInputAttachmentsClick = (e) => {
        this.attachmentsInput.click();
    }
    /*  */

    setDoneByAdmin = (e) => {
        let id = this.props.id;
        axios.put(`${this.api}/ppi/formatDone/${id}`, { 'section': e })
            .then((response) => {
                if (response.status === 200) {
                    switch (e.toLowerCase()) {
                        case 'general_information':
                            this.setState({
                                general_information_approved: true,
                                commentsGIPFlag: false,
                                commentsGIP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Información general del PPI fue aprobada'
                            });
                            break;
                        case 'problem_description':
                            this.setState({
                                problem_description_approved: true,
                                commentsPDPFlag: false,
                                commentsPDP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Descripción de la problemática fue aprobada'
                            });
                            break;
                        case 'general_description':
                            this.setState({
                                general_description_approved: true,
                                commentsGDPFlag: false,
                                commentsGDP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Descripción general del PPI fue aprobada'
                            });
                            break;
                        case 'objective':
                            this.setState({
                                objective_approved: true,
                                commentsObjectiveFlag: false,
                                commentsObjective: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Objetivo del PPI fue aprobada'
                            });
                            break;
                        case 'execution_results':
                            this.setState({
                                exesution_results_approved: true,
                                commentsExecutionResultsFlag: false,
                                commentsExecutionResults: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Resultados de la ejecución y beneficios económicos y/o sociales del PPI fue aprobada'
                            });
                            break;
                        case 'feasibilities':
                            this.setState({
                                feasibilities_approved: true,
                                commentsFPFlag: false,
                                commentsFP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Factibilidades del PPI fue aprobada'
                            });
                            break;
                        case 'general_considerations':
                            this.setState({
                                general_considerations_approved: true,
                                commentsGCPFlag: false,
                                commentsGCP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Consideraciones Generales fue aprobada'
                            });
                            break;
                        case 'attachments':
                            this.setState({
                                attachments_approved: true,
                                commentsAPFlag: false,
                                commentsAP: '',
                                showModal: true,
                                icon: 'success',
                                titleModal: 'Sección aprobada',
                                messageModal: 'La sección Documentos adjuntos fue aprobada'
                            });
                            break;
                        default:
                            break;
                    }
                }
                if (response.data.sections === true) {
                    this.setState({
                        formatReadyToApprove: true
                    });
                    // this.props.parentCallback(this.state);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }
    setUndoByAdmin = (e) => {
        let id = this.props.id;
        axios.put(`${this.api}/ppi/formatUndo/${id}`, { 'section': e })
            .then((response) => {
                if (response.status === 200) {
                    switch (e.toLowerCase()) {
                        case 'general_information':
                            this.setState({
                                general_information_approved: false,
                                showModal: true,
                                icon: 'warning',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Información general del PPI regresó a estatus no aprobada'
                            });
                            break;
                        case 'problem_description':
                            this.setState({
                                problem_description_approved: false,
                                showModal: true,
                                icon: 'warning',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Descripción de la problemática regresó a estatus no aprobada'
                            });
                            break;
                        case 'general_description':
                            this.setState({
                                general_description_approved: false,
                                showModal: true,
                                icon: 'warning',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Descripción general del PPI regresó a estatus no aprobada'
                            });
                            break;
                        case 'objective':
                            this.setState({
                                objective_approved: false,
                                showModal: true,
                                icon: 'warning',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Objetivo del PPI regresó a estatus no aprobada'
                            });
                            break;
                        case 'execution_results':
                            this.setState({
                                exesution_results_approved: false,
                                showModal: true,
                                icon: 'warning',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Resultados de la ejecución y beneficios económicos y/o sociales del PPI regresó a estatus no aprobada'
                            });
                            break;
                        case 'feasibilities':
                            this.setState({
                                feasibilities_approved: false,
                                showModal: true,
                                icon: 'warning',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Factibilidades del PPI regresó a estatus no aprobada'
                            });
                            break;
                        case 'general_considerations':
                            this.setState({
                                general_considerations_approved: false,
                                showModal: true,
                                icon: 'warning',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Consideraciones Generales regresó a estatus no aprobada'
                            });
                            break;
                        case 'attachments':
                            this.setState({
                                attachments_approved: false,
                                showModal: true,
                                icon: 'warning',
                                titleModal: 'Acción revertida',
                                messageModal: 'La sección Documentos adjuntos regresó a estatus no aprobada'
                            });
                            break;
                        default:
                            break;
                    }
                }
                if (response.data.sections === false) {
                    this.setState({
                        formatReadyToApprove: false
                    });
                    //this.props.parentCallback(this.state);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    /* COMMENTS */
    AddCommentGIP = () => {
        this.setState({
            commentsGIPFlag: !this.state.commentsGIPFlag
        });
    }

    AddCommentPDP = () => {
        this.setState({
            commentsPDPFlag: !this.state.commentsPDPFlag
        });
    }

    AddCommentGDP = () => {
        this.setState({
            commentsGDPFlag: !this.state.commentsGDPFlag
        });
    }

    AddCommentObjective = () => {
        this.setState({
            commentsObjectiveFlag: !this.state.commentsObjectiveFlag
        });
    }

    AddCommentEr = () => {
        this.setState({
            commentsExecutionResultsFlag: !this.state.commentsExecutionResultsFlag
        });
    }

    AddCommentFP = () => {
        this.setState({
            commentsFPFlag: !this.state.commentsFPFlag
        });
    }

    AddCommentGCP = () => {
        this.setState({
            commentsGCPFlag: !this.state.commentsGCPFlag
        });
    }

    AddCommentAP = () => {
        this.setState({
            commentsAPFlag: !this.state.commentsAPFlag
        });
    }
    /* TERMINA AREA DE COMMENTS HANDLERS */

    /* Files */
    handleChangePicFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];

        $(".loader").show("fast", () => { });
        nextState.PicFile = value;
        nextState.pic_file_name = value.name;
        this.setState(nextState);
        // this.onTrigger();

        const imgData = new FormData();
        if (nextState.PicFile) {
            imgData.append('document_files', nextState.PicFile);

            axios.post(`${this.api}/ppi/images/${this.props.id}`, imgData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    NotificationManager.success('Imágenes de la situación actual guardadas correctamente', '¡Archivo guardado!');
                    setTimeout(() => {
                        $(".loader").hide("slow", () => { });
                    }, 1000);
                })
                .catch((error) => {
                    console.error(error)
                    setTimeout(() => {
                        $(".loader").hide("slow", () => { });
                    }, 1000);
                });
        }
    }
    handleChangeOficioFileInput = (e) => {
        var nextState = this.state;
        let value = e.target.files[0];

        $(".loader").show("fast", () => { });
        nextState.responsable_document = value;
        if (value.name.length > 20) {
            nextState.responsable_document_name = value.name.substr(0, 14) + "...";
        } else {
            nextState.responsable_document_name = value.name;
        }
        this.setState(nextState);
        //this.onTrigger();

        const oficioData = new FormData();
        if (nextState.responsable_document) {
            oficioData.append('oficio', nextState.responsable_document);

            axios.post(`${this.api}/ppi/oficio/${this.props.id}`, oficioData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    NotificationManager.success('Oficio guardado correctamente', '¡Archivo guardado!');
                    setTimeout(() => {
                        $(".loader").hide("slow", () => { });
                    }, 1000);
                })
                .catch((error) => {
                    console.error(error)
                    NotificationManager.error('El oficio no se guardaro correctamente', '¡Error al guardar el Oficio!');
                    setTimeout(() => {
                        $(".loader").hide("slow", () => { });
                    }, 1000);
                });
        }
        setTimeout(() => {
            $(".loader").hide("slow", () => { });
        }, 1000);
    }
    handleChangeAttachmentsFileInput = (e) => {
        var nextState = this.state;
        let values = e.target.files;

        $(".loader").show("fast", () => { });
        Array.from(values).forEach(file => {
            nextState.attachments.push(file);
            var _size = file.size;
            var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i = 0;
            while (_size > 900) { _size /= 1024; i++; }

            var exactSize = (Math.ceil(Math.round(_size * 100) / 100)) + ' ' + fSExt[i];

            nextState.attachments_names.push({ name: file.name, size: exactSize });
        });
        this.setState(nextState);
        // this.onTrigger();

        const attachmentData = new FormData();
        let countFiles = 0;

        if (nextState.attachments) {
            nextState.attachments.map((file, idx) => {
                attachmentData.append('attachments[' + idx + ']', file);
                countFiles++;
                return true;
            });
        }

        if (countFiles > 0) {
            axios.post(`${this.api}/ppi/attachments/${this.props.id}`, attachmentData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    let attachmentsFiles = [];
                    if (response.data.attachments.length > 0) {
                        response.data.attachments.map((attachment) => {
                            var _size = attachment.file_size;
                            var fSExt = ['Bytes', 'KB', 'MB', 'GB'], i = 0;
                            while (_size > 900) { _size /= 1024; i++; }

                            var exactSize = (Math.ceil(Math.round(_size * 100) / 100)) + ' ' + fSExt[i];
                            attachmentsFiles.push({
                                name: attachment.name,
                                id: attachment.pk_attachments,
                                size: exactSize,
                                path: attachment.file
                            });
                            return true;
                        });
                    }

                    this.setState({ attachments_names: attachmentsFiles, attachments: [] });
                    NotificationManager.success('Documentos adjuntos guardados correctamente', '¡Archivo guardado!');
                    setTimeout(() => {
                        $(".loader").hide("slow", () => { });
                    }, 1000);
                })
                .catch((error) => {
                    console.error(error)
                    NotificationManager.error('Los documentos adjuntos no se guardaron correctamente', '¡Error al guardar los archivos adjuntos!');
                    setTimeout(() => {
                        $(".loader").hide("slow", () => { });
                    }, 1000);
                });
        }
    }
    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }
    handleCloseConfirm = () => {
        this.setState({
            showModalConfirm: false
        });
    }

    handleShowConfirm = () => {
        this.setState({
            showModalConfirm: true
        });
    }

    handleCloseDeleteImage = () => {
        this.setState({
            showModalDeleteImage: false
        });
    }

    handleShowDeleteImage = () => {
        this.setState({
            showModalDeleteImage: true
        });
    }

    /* Agregar y Quitar Renglones en Tablas */
    addRowSources() {
        var nextState = this.state;
        let newFSOrigin = { origin: 0, percentage: '', amount: '' };
        nextState.founding_sources_origin.push(newFSOrigin);
        this.setState(nextState);
    }

    addRowOptimizationMeasures = (e) => {
        var nextState = this.state;
        let newFSOrigin = { alternatives: '', totalCostVAT: '' };
        nextState.optimization_measures.push(newFSOrigin);
        this.setState(nextState);
    }


    removeRowSources(index) {
        var rowsSources = [...this.state.rowsSources];
        var founding_sources_origin = [...this.state.founding_sources_origin];
        const findRealIdx = (element) => element === index;
        var realIndex = founding_sources_origin.findIndex(findRealIdx);
        founding_sources_origin.splice(realIndex, 1);
        setTimeout(() => {
            this.setState({ rowsSources, founding_sources_origin });

        }, 100);
        // this.onTrigger();
    }
    addRowCalendar() {
        if (this.state.calendar_investment.length === 30) {
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal: '¡Advertencia!',
                messageModal: 'Has superado el límite de meses de inversión'
            });
            return false;
        }
        let index = this.state.calendar_index + 1;
        setTimeout(() => {
            this.setState({
                calendar_index: index
            });
            this.rerenderList();
        }, 100);

        var nextState = this.state;
        let newCalendar = { advance: `Mes ${index}`, physical: '', financial: '' };
        nextState.calendar_investment.push(newCalendar);
        this.setState(nextState);
    }

    removeRowCalendar(index) {
        var rowsCalendar = [...this.state.rowsCalendar];
        var calendar_investment = [...this.state.calendar_investment];
        const findRealIdx = (element) => element === index;
        var realIndex = calendar_investment.findIndex(findRealIdx);
        calendar_investment.splice(realIndex, 1);
        this.setState({ rowsCalendar, calendar_investment: calendar_investment });
        setTimeout(() => {
            this.calculateInvestment();
            //this.onTrigger();
            this.rerenderList();
        }, 100);

    }
    rerenderList() {
        var calendar_investment = [...this.state.calendar_investment];
        let index = 0;
        for (var month in calendar_investment) {
            index++;
            calendar_investment[month].advance = `Mes ${index}`;
        }
        setTimeout(() => {
            this.setState({
                calendar_investment,
                calendar_index: index
            });
        }, 100);
    }
    addRowComponent() {
        if (this.state.components.length === 50) {
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal: '¡Advertencia!',
                messageModal: 'Has superado el límite de componentes por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newComponent = { name_concept: '', description_concept: '', unit_of_measure: 0, quantity: '', unit_price: '', total_amount: '', number_concept: 0, number_item: 0, description_item: '' }
        nextState.components.push(newComponent);
        this.setState(nextState);
    }

    addRowAlineacion() {


        var nextState = this.state;
        let newAlineacion = { plan: '', eje: '', tema: '', objetivo: '', estrategia: '', linea: '' }
        nextState.alineacion_estrategica.push(newAlineacion);
        this.setState(nextState);
    }

    //NUEVA VERSION
    removeRowAlineacion(index) {
        this.setState(prevState => {
            const updatedRowsAlineacion = prevState.rowsAlineacion.filter(row => row !== index);
            const updatedComponents = prevState.alineacion_estrategica.filter(component => component !== index);

            return {
                rowsAlineacion: updatedRowsAlineacion,
                alineacion_estrategica: updatedComponents
            };
        }, () => {
            // this.onTrigger(); // Llamar a onTrigger después de que el estado se haya actualizado
        });
    }
    //alternativas de solucion
    addRowAlternativa() {
        var nextState = this.state;
        let newAlternativa = { alternativa: '', ventajas: '', desventajas: '' }
        nextState.alternativas_solucion.push(newAlternativa);
        this.setState(nextState);
    }

    removeRowAlternativa(index) {


        this.setState(prevState => {
            const updatedRowsAlternativa = prevState.rowsAlternativa.filter(row => row !== index);
            const updatedComponent = prevState.alternativas_solucion.filter(component => component !== index);

            return {
                rowsAlternativa: updatedRowsAlternativa,
                alternativas_solucion: updatedComponent
            };

        }, () => {
            // this.onTrigger(); // Llamar a onTrigger después de que el estado se haya actualizado
        });
    }




    removeRowComponent(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var components = [...this.state.components];
        const findRealIdx = (element) => element === index;
        var realIndex = components.findIndex(findRealIdx);
        components.splice(realIndex, 1);
        this.setState({ rowsComponent, 'components': components });
        this.forceUpdate();
        setTimeout(() => {
            //this.calculateComponents();
            //this.onTrigger();
        }, 100);
    }

    addRowComponentAdq() {
        if (this.state.components.length === 50) {
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal: '¡Advertencia!',
                messageModal: 'Has superado el límite de componentes por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newComponent = { number_concept: 0, description_concept: '', number_item: 0, description_item: '', name_concept: '', description: '', unit_of_measure: 0, quantity: '', unit_price: '', total_amount: '' }
        nextState.components.push(newComponent);
        this.setState(nextState);
    }
    removeRowComponentAdq(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var components = [...this.state.components];
        const findRealIdx = (element) => element === index;
        var realIndex = components.findIndex(findRealIdx);
        components.splice(realIndex, 1);
        this.setState({ rowsComponent, 'components': components });
        this.forceUpdate();
        setTimeout(() => {
            // this.calculateComponents();
            // this.onTrigger();
        }, 100);
    }


    addRowGoal() {
        if (this.state.goals.length === 50) {
            this.setState({
                showModal: true,
                icon: 'warning',
                titleModal: '¡Advertencia!',
                messageModal: 'Has superado el límite de metas por PPI'
            });
            return false;
        }
        var nextState = this.state;
        let newGoal = { name_concept: '', unit_of_measure: 0, quantity: '' }
        nextState.goals.push(newGoal);
        this.setState(nextState);
    }
    removeRowGoal(index) {
        var rowsComponent = [...this.state.rowsComponent];
        var goals = [...this.state.goals];
        const findRealIdx = (element) => element === index;
        var realIndex = goals.findIndex(findRealIdx);
        goals.splice(realIndex, 1);
        this.setState({ rowsComponent, goals: goals });
        //this.onTrigger();
    }

    removeRowOptimizationMeasures(index) {
        /*
        var rowOptimizationMeasures = [...this.state.rowOptimizationMeasures];
        var optimization_measures = [...this.state.optimization_measures];
        const findRealIdx = (element) => element === index;
        var realIndex = optimization_measures.findIndex(findRealIdx);
        optimization_measures.splice(realIndex, 1);
        setTimeout(() => {
            this.setState({ rowOptimizationMeasures, optimization_measures });
        }, 100);
        this.onTrigger();*/

        this.setState(prevState => {
            const updatedRowsOptimization = prevState.rowOptimizationMeasures.filter(row => row !== index);
            const updatedComponent = prevState.optimization_measures.filter(component => component !== index);

            return {
                rowOptimizationMeasures: updatedRowsOptimization,
                optimization_measures: updatedComponent
            };

        }, () => {
            // this.onTrigger(); // Llamar a onTrigger después de que el estado se haya actualizado
        });


    }

    addRowCity() {
        var nextState = this.state;
        let newCity = { city: 0, delete: true };
        nextState.citySelected.push(newCity);
        this.setState(nextState);
    }
    removeRowCity(index) {
        var citySelected = [...this.state.citySelected];
        const findRealIdx = (element) => element === index;
        var realIndex = citySelected.findIndex(findRealIdx);
        citySelected.splice(realIndex, 1);
        this.setState({ citySelected });
        // this.onTrigger();
    }

    render() {
        const fm = new FormatMoney({
            decimals: 2
        });

        const fmND = new FormatMoney({
            decimals: 0
        });
        let id = this.props.id;
        let status = this.props.status;
        let user = JSON.parse(localStorage.getItem('user'));
        let message = "";
        let seccionclave = "";
        let seccion1 = "";
        let seccion2 = "";
        let seccion3 = "";
        let seccion4 = "";
        let seccion5 = "";
        let tabla1 = "";
        let seccion6 = "";
        let seccionConsideracionesFinales = "";
        let tablaIndicadoresABC = "";
        let seccionestudio = "";
        let isAdmin = false;
        let VisualizarTodos = false;
        let adminInRevision = false;
      



        if (user.fk_role === 1 && status === 5) {
            message = (
                <div className="row alert-message">
                    <div className="col col-12 col-lg-6 offset-lg-3">
                        <div className="row">
                            <div className="col-3">
                                <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                            </div>
                            <div className="col-9" style={{ 'textAlign': 'left' }}>
                                El formato aún se encuentra de proceso, aún no puedes validarlo
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (user.fk_role === 1 && status === 6) {
            adminInRevision = true;
        }
        if (user.fk_role === 1) {
            isAdmin = true;
        }
        var isResponsable = this.state.id_responsable === user.pk_user ? true : false;
        var pd_disabled = !adminInRevision && this.state.problem_description_approved === true ? true : false;
        var gd_disabled = !adminInRevision && this.state.general_description_approved === true ? true : false;
        var ob_disabled = !adminInRevision && this.state.objective_approved === true ? true : false;
        var gi_disabled = !adminInRevision && this.state.general_information_approved === true ? true : false;

        var er_disabled = !adminInRevision && this.state.exesution_results_approved === true ? true : false;
        var sal_disabled = !adminInRevision && this.state.solution_alternatives_approved === true ? true : false;
        var fe_disabled = !adminInRevision && this.state.feasibilities_approved === true ? true : false;
        var cg_disabled = !adminInRevision && this.state.general_considerations_approved === true ? true : false;
        var da_disabled = !adminInRevision && this.state.attachments_approved === true ? true : false;


        if (this.props.formato_PPI > 1) {
            VisualizarTodos = false;
        }

        seccionclave = (
            <>
                {
                    <div className="row wrap-section">
                        <div className="col-12 not-padding">
                            <div className="row">
                                <div className="col-10 offset-1" style={{ 'textAlign': 'left' }}>
                                    <div className="row">
                                        <label className="col-lg-5 col-sm-12">Folio:</label>
                                        <div className="col-lg-7 col-sm-12">
                                            {this.state.folio}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-5 col-sm-12">Monto de inversión:</label>
                                        <div className="col-lg-7 col-sm-12">
                                            ${fm.from(this.state.amount)}

                                        </div>
                                    </div>
                                   
                                    <div className="row">
                                        <label className="col-lg-5 col-sm-12">Monto en UDIS:</label>
                                        <div className="col-lg-7 col-sm-12">
                                            {fmND.from(this.state.udis)}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-5 col-sm-12">Fecha de creación</label>
                                        <div className="col-lg-7 col-sm-12">
                                            {this.state.fecha}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-5 col-sm-12">Creador</label>
                                        <div className="col-lg-7 col-sm-12">
                                            
                                                <p>{this.state.aproved_name}</p>
                                           


                                        </div>
                                    </div>
                                    <div className="row">
                                        <label className="col-lg-5 col-sm-12">Responsable del PPI</label>
                                        <div className="col-lg-7 col-sm-12">
                                            {this.state.responsable}
                                        </div>
                                    </div>

                                    {isAdmin && <div className="row">
                                        <label className="col-lg-5 col-sm-12">Clave cartera</label>
                                        <div className="col-lg-7 col-sm-12">
                                            {this.state.clave_cartera}
                                        </div>
                                    </div>}

                                    <div className="row">
                                        <div className="col-4">
                                            <a href={`${this.exports}/ppi/exportPPIHomo/${id}`} target="_blank" rel="noreferrer">Descargar PPI</a>

                                        </div>

                                        {isAdmin && <div className="col-4">
                                            <a href={`${this.exports}/ppi/exportClaveCarteraV2/${id}`} target="_blank" rel="noreferrer">Descargar clave cartera</a>
                                        </div>}


                                    </div><br></br>

                                       {isAdmin &&
                                        <button
                                            style={{
                                                position: 'center',
                                                top: '10px',    // Ajusta esta propiedad para la distancia desde la parte superior
                                                right: '10px',  // Ajusta esta propiedad para la distancia desde la derecha
                                                padding: '5px 10px',  // Agregar relleno para hacer el botón más grande
                                                background: '#A93226',  // Cambiar el color de fondo
                                                color: '#fff',  // Cambiar el color del texto
                                                border: 'none',  // Eliminar el borde
                                                borderRadius: '5px',  // Agregar bordes redondeados
                                                cursor: 'pointer',  // Cambiar el cursor al pasar el mouse
                                                fontSize: '16px',  // Cambiar el tamaño del texto
                                            }}
                                           
                                            onClick={this.handleCancelCC}
                                            disabled={this.state.status_aproved === 13}
                                            className="btn btn-danger mx-2"
                                             
                                        >
                                            Cancelar clave cartera
                                        </button>}
                                   

                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )


        seccion1 = (
            <>
                <div className="row title-section" disabled={VisualizarTodos}>
                    <div className="col-10">Información general del PPI</div>


                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">

                                    <label className="col-lg-5 col-sm-12">Nombre del PPI</label>
                                    <div className="col-lg-7 col-sm-12" style={{ 'text-align': 'left' }}>
                                        {this.state.name_ppi}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Tipo de PPI</label>
                                    <div className="col-lg-7 col-sm-12" style={{ 'text-align': 'left' }}>
                                        {this.state.type_ppi}
                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Municipio(s)</label>
                                    <div className="col-lg-7 col-sm-12" style={{ 'text-align': 'left' }}>
                                        {this.state.citySelected}
                                    </div>
                                </div>

                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Dependencia solicitante</label>
                                    <div className="col-lg-7 col-sm-12" style={{ 'text-align': 'left' }}>
                                        {this.state.dependency_applicant}
                                    </div>
                                </div>
                                <div className="row">
                                    <label className="col-lg-5 col-sm-12">Dependencia responsable</label>
                                    <div className="col-lg-7 col-sm-12" style={{ 'textAlign': 'left' }}>
                                        {this.state.dependency_executor}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">

                                    <div className="col-10">

                                        <h6>Fuentes de financiamiento</h6>

                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td><strong>Origen</strong></td>
                                                    <td><strong>Fondo</strong></td>
                                                    <td><strong>%</strong></td>
                                                    <td><strong>Monto</strong></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.founding_sources_origin.map((row, index) => <tr key={'origin_' + index}>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].origin}
                                                    </td>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].subsource}
                                                        {this.state.founding_sources_origin[index].other}
                                                    </td>
                                                    <td>
                                                        {this.state.founding_sources_origin[index].percentage} %
                                                    </td>
                                                    <td>
                                                        $ {row.amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                {/*DIV ROW*/}
                                <div className="col-md-12 ">
                                    <div className="row results-investment">
                                        <div className="col-3">Porcentaje (%)</div>
                                        <div className="col-3">{this.state.total_percentage_funding}%</div>

                                        <div className="col-3">Total ($)</div>
                                        <div className="col-3">$ {this.state.total_amount_funding}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <small className="note">Máximo 30 meses</small>
                                    </div>
                                    <div className="col-10">
                                        <h6>Calendario de inversión</h6>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td><strong>Avance</strong></td>
                                                    <td><strong>Físico (%)</strong></td>
                                                    <td><strong>Financiero ($)</strong></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.calendar_investment.map((row, index) => <tr key={'ci_' + index}>
                                                    <td>
                                                        {this.state.calendar_investment[index].advance}
                                                    </td>
                                                    <td>
                                                        {this.state.calendar_investment[index].physical} %
                                                    </td>
                                                    <td>
                                                        $ {row.financial.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="row results-investment">
                                        <div className="col-3">Total físico (100%)</div>
                                        <div className="col-3">{this.state.total_physical}%</div>
                                        <div className="col-3">Total financiero ($)</div>
                                        <div className="col-3">$ {this.state.total_financial_currency_format.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                                    </div>
                                </div>





                                <div className="row subtitle-section">
                                    <div className="col-3 text-left">
                                        <h6>Vida útil</h6>
                                    </div>
                                    <div className="col-3">

                                        <div style={{ 'textAlign': 'left' }}>{this.state.vida_util}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-12">
                                        <h6>Localización Geográfica</h6>
                                    </div>
                                </div>
                                <div className="row">
                                    {!isAdmin && !gi_disabled ?
                                        <div className="col-lg-12">
                                            <PlacesWithStandaloneSearchBox amount={this.state.amount} coordinates={this.state.coordinatesData} id={id} parentCallback={this.handleCallback} />
                                        </div>
                                        :
                                        <div className="col-lg-12" style={{ 'height': '400px' }}>
                                            <MyComponent amount={this.state.amount} disabled={true} coordinates={this.state.coordinatesData} id={id} />
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="row d-flex">
                                            <div className="col-lg-12">
                                                <div className="row subtitle-section">
                                                    <div className="col-12">
                                                        <h6>Observaciones</h6>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">


                                                        <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.observations_location}</div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">



                                        <div className="row subtitle-section">


                                            <div className="col-10">
                                                <h6>Alineación estratégica</h6>
                                            </div>


                                        </div>
                                        {this.state.alineacion_estrategica.map((row, index) => (
                                            <div className="row d-flex">
                                                <div className="col-lg-12">

                                                    <Table bordered className="table-not-padding">

                                                        <tbody>
                                                            <tr>
                                                                <td style={{ "verticalAlign": "middle", "textAlign": "left", 'width': '150px' }}><strong>Plan o programa</strong></td>

                                                                <td style={{ "textAlign": "left" }}>
                                                                    {this.state.alineacion_estrategica[index].plan}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ "verticalAlign": "middle", "textAlign": "left" }}><strong>Eje</strong></td>

                                                                <td style={{ "textAlign": "left" }}>
                                                                    {this.state.alineacion_estrategica[index].eje}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ "verticalAlign": "middle", "textAlign": "left" }}><strong>Tema</strong></td>
                                                                <td style={{ "textAlign": "left" }}>


                                                                    {this.state.alineacion_estrategica[index].tema}

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ "verticalAlign": "middle", "textAlign": "left" }}><strong>Objetivo</strong></td>
                                                                <td style={{ "textAlign": "left" }}>

                                                                    {this.state.alineacion_estrategica[index].objetivo}

                                                                </td>

                                                            </tr>
                                                            <tr>
                                                                <td style={{ "verticalAlign": "middle", "textAlign": "left" }}><strong>Estrategia</strong></td>

                                                                <td style={{ "textAlign": "left" }}>


                                                                    {this.state.alineacion_estrategica[index].estrategia}

                                                                </td>

                                                            </tr>
                                                            <tr>
                                                                <td style={{ "verticalAlign": "middle", "textAlign": "left" }}><strong>Línea de acción</strong></td>
                                                                <td style={{ "textAlign": "left" }}>


                                                                    {this.state.alineacion_estrategica[index].linea}

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
        seccion2 = (
            <>
                <div className="row title-section" disabled={VisualizarTodos}>
                    <div className="col-10">Descripción del PPI</div>

                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Descripción general del PPI</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">

                                                <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.general_description}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Objetivo</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">

                                                <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.objective_general}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.formato === 7 &&
                                    <div>



                                        <div className="row d-flex">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row subtitle-section">
                                                            <div className="col-11"></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6"></div>
                                                            <div className="col-10 col-sm-4 pt-2" style={{ 'textAlign': 'right' }}><b>Suma Total de inversión en estudios:</b></div>
                                                            <div className="col-10 col-sm-2"><input className="form-control" value={this.state.total_IX_amount} disabled /> </div>

                                                            <div className="col-lg-12 col-sm-12">
                                                                <Table bordered className="table-not-padding">
                                                                    <thead>
                                                                        <tr><td colspan="7"><center><b>Estudios de preinversión requeridos</b></center></td></tr>
                                                                        <tr>
                                                                            <td style={{ 'width': '15%' }}><strong>Nombre del estudio</strong></td>
                                                                            <td style={{ 'width': '15%' }}><strong>Tipo de estudio</strong></td>
                                                                            <td style={{ 'width': '20%' }}><strong>Descripción</strong></td>
                                                                            <td style={{ 'width': '20%' }}><strong>Justificación de su realización</strong></td>
                                                                            <td style={{ 'width': '10%' }}><strong>Fecha de inicio <br /> de realización</strong></td>
                                                                            <td style={{ 'width': '10%' }}><strong>Fecha final <br /> de realización</strong></td>
                                                                            <td style={{ 'width': '10%' }}><strong>Monto estimado <br />(incluye IVA)</strong></td>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {this.state.data_sheet_extra_pre_investments.map((row, index) => <tr key={'typeorigina_' + index}>
                                                                            <td>
                                                                                {this.state.data_sheet_extra_pre_investments[index].study_name}
                                                                            </td>
                                                                            <td>
                                                                                {this.state.data_sheet_extra_pre_investments[index].type_of_study}
                                                                            </td>
                                                                            <td>
                                                                                {this.state.data_sheet_extra_pre_investments[index].description}
                                                                            </td>
                                                                            <td>
                                                                                {this.state.data_sheet_extra_pre_investments[index].justification_realization}
                                                                            </td>
                                                                            <td>
                                                                                {this.state.data_sheet_extra_pre_investments[index].estimated_date_completion}
                                                                            </td>
                                                                            <td>
                                                                                {this.state.data_sheet_extra_pre_investments[index].second_estimated_date_completion}
                                                                            </td>
                                                                            <td>
                                                                                {fm.from(this.state.data_sheet_extra_pre_investments[index].estimated_cost_amount)}
                                                                            </td>
                                                                        </tr>)}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                }

                                {this.props.adquisiciones === false && this.state.formato !== 7 &&
                                    <div className="row d-flex">
                                        <div className="col-lg-12">
                                            <div className="row subtitle-section">
                                                <div className="col-12">
                                                    <small className="note">Máximo 50 componentes</small>
                                                </div>

                                                <div className="col-10">
                                                    <h6>Descripción de los componentes del PPI</h6>
                                                </div>

                                            </div>

                                            <div className="row d-flex">
                                                <div className="col-lg-12">


                                                    <Table bordered className="table-not-padding">
                                                        <thead>
                                                            <tr>
                                                                <td><strong>Nombre del componente</strong></td>
                                                                <td><strong>Descripción</strong></td>
                                                                <td><strong>Unidad de medida</strong></td>
                                                                <td><strong>Cantidad</strong></td>
                                                                <td><strong>Precio unitario (SIN IVA $)</strong></td>
                                                                <td><strong>Monto total (SIN IVA $)</strong></td>

                                                            </tr>
                                                        </thead>
                                                        <tbody className="body-components">
                                                            {this.state.components.map((row, index) => <tr key={'compo_' + index}>
                                                                <td>
                                                                    {this.state.components[index].name_concept}
                                                                </td>
                                                                <td>
                                                                    {this.state.components[index].description}
                                                                </td>
                                                                <td>
                                                                    {this.state.components[index].unit_of_measure}
                                                                </td>
                                                                <td>
                                                                    {this.state.components[index].quantity}
                                                                </td>
                                                                <td>
                                                                    $ {row.unit_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </td>
                                                                <td>
                                                                    $ {this.state.components[index].total_amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </td>
                                                            </tr>)}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                            <div className="row d-flex">
                                                <div className="col-lg-4 offset-lg-8">
                                                    <Table bordered>
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>Subtotal</strong></td>
                                                                <td>$ {this.state.subtotal_components}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>IVA</strong></td>
                                                                <td>$ {this.state.iva_components}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Total</strong></td>
                                                                <td>$ {this.state.total_components}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                {this.props.adquisiciones === true &&
                                    <div className="row d-flex">
                                        <div className="col-lg-12">
                                            <div className="row subtitle-section">
                                                <div className="col-12">
                                                    <small className="note">Máximo 50 componentes</small>
                                                </div>

                                                <div className="col-10">
                                                    <h6>Descripción de los componentes del PPI</h6>
                                                </div>

                                            </div>
                                            <Table bordered className="table-not-padding">
                                                <thead>
                                                    <tr>
                                                        <th colspan="2">Concepto</th>
                                                        <th colspan="2">Partida</th>
                                                        <th rowspan="2" style={{ "verticalAlign": "middle", "textAlign": "center" }}>Descripción del componente</th>
                                                        <th rowspan="2" style={{ "verticalAlign": "middle", "textAlign": "center" }}>Unidad de medida</th>
                                                        <th rowspan="2" style={{ "verticalAlign": "middle", "textAlign": "center" }}>Cantidad</th>
                                                        <th rowspan="2" style={{ "verticalAlign": "middle", "textAlign": "center" }}>Precio unitario (SIN IVA $)</th>
                                                        <th rowspan="2" style={{ "verticalAlign": "middle", "textAlign": "center" }}>Monto total (SIN IVA $)</th>
                                                        {!isAdmin && <th rowspan="2" style={{ "verticalAlign": "middle", "textAlign": "center" }}></th>}
                                                    </tr>

                                                    <tr>
                                                        <th>Num.</th>
                                                        <th>Descripción</th>
                                                        <th>Num.</th>
                                                        <th>Descripción</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="body-components">
                                                    {this.state.components.map((row, index) => <tr key={'comp_' + index}>
                                                        <td>
                                                            {this.state.components[index].number_concept}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].description_concept}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].number_item}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].description_item}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].description}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].unit_of_measure}
                                                        </td>
                                                        <td>
                                                            {this.state.components[index].quantity}
                                                        </td>
                                                        <td>
                                                            $ {this.state.components[index].unit_price}
                                                        </td>
                                                        <td>
                                                            $ {this.state.components[index].total_amount}
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                            <div className="row d-flex">
                                                <div className="col-lg-4 offset-lg-8">
                                                    <Table bordered>
                                                        <tbody>
                                                            <tr>
                                                                <td><strong>Subtotal</strong></td>
                                                                <td>$ {this.state.subtotal_components}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>IVA</strong></td>
                                                                <td>$ {this.state.iva_components}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><strong>Total</strong></td>
                                                                <td>$ {this.state.total_components}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                }





                                <div className="row subtitle-section">
                                    <div className="col-3 text-left">
                                        <label>Número de beneficiarios directos del PPI</label>
                                    </div>
                                    <div className="col-3">

                                        <div style={{ 'textAlign': 'left' }}>{this.state.beneficiaries}</div>

                                    </div>

                                </div><br></br><br></br>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Resultados de la ejecución y beneficios económicos y/o sociales</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">

                                                <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.execution_result}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.props.adquisiciones === false &&
                                    <div className="row d-flex">
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <label className="col-12">Factibilidades del PPI</label>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <Table bordered className="table-not-padding">
                                                        <thead>
                                                            <tr>
                                                                <td><strong>Tipo de factibilidad</strong></td>
                                                                <td><strong>Respuesta</strong></td>
                                                                <td><strong>Comentario</strong></td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Se cuenta con proyecto ejecutivo</td>
                                                                <td>
                                                                    {this.state.options_feasibilities[this.state.executive_project ? this.state.executive_project : 0]}
                                                                </td>
                                                                <td>

                                                                    {this.state.executive_project_description}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Se cuenta con permisos</td>
                                                                <td>
                                                                    {this.state.options_feasibilities[this.state.permissions ? this.state.permissions : 0]}

                                                                </td>
                                                                <td>

                                                                    {this.state.permissions_description}

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Se cuenta con estudios ambientales</td>
                                                                <td>
                                                                    {this.state.options_feasibilities[this.state.enviromental_studies ? this.state.enviromental_studies : 0]}

                                                                </td>
                                                                <td>

                                                                    {this.state.enviromental_studies_description}

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Se cuenta con derechos de propiedad</td>
                                                                <td>
                                                                    {this.state.options_feasibilities[this.state.property_rights ? this.state.property_rights : 0]}
                                                                </td>
                                                                <td>

                                                                    {this.state.property_rights_description}

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Número de catastro</td>
                                                                <td>
                                                                    {this.state.options_feasibilities[this.state.numero_catastro ? this.state.numero_catastro : 0]}

                                                                </td>
                                                                <td>

                                                                    {this.state.catastro}

                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}


                            </div>
                        </div>

                    </div>
                </div>
            </>
        );

        seccion3 = (
            <>
                <div className="row title-section" disabled={VisualizarTodos}>
                    <div className="col-10">Análisis de la situación actual </div>


                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Justificación del PPI (problemática, necesidad u oportunidad)</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">

                                                <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.description}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Oferta actual</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">

                                                <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.current_situation_offer}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Demanda actual</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">

                                                <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.demand_current_situation}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Interacción actual</label>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">

                                                <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.intereaction_current_situation}</div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-10 offset-1">

                                <div className="row subtitle-section">


                                    <div className="col-12">
                                        <small className="note">Descargar plantilla disponible en "Ayuda/Contacto" {'>'} "Marco Normativo" </small>
                                    </div>

                                    <label className="col-12">Plantilla de imágenes situación actual</label>
                                </div>


                                <div className="row row-file">
                                    <div className="col-1 file-icon">
                                        <FontAwesomeIcon icon={faFileAlt} />
                                    </div>
                                    <div className="col-8 text-collapsed file-name" style={{ textAlign: 'left' }}>
                                        <span>{this.state.pic_file_name}</span>
                                    </div>

                                    <div className="col-1 file-download" style={{ textAlign: 'center' }}>
                                        <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.imagesFile}`} download>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>






                    </div>
                </div>


            </>
        )



        seccion4 = (
            <>
                <div className="row title-section">
                    <div className="col-10">Análisis de la situación con PPI</div>


                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">

                                    <label className="col-12">Oferta con PPI</label>

                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">

                                        <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.asa_offer_analysus}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">

                                    <label className="col-12">Demanda con PPI</label>

                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">

                                        <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.asa_offer_analysis}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">

                                    <label className="col-12">Interacción con PPI</label>

                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">

                                        <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.asa_interaction}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">

                                <div className="row subtitle-section">


                                    <div className="col-12">
                                        <small className="note">Descargar plantilla disponible en "Ayuda/Contacto" {'>'} "Marco Normativo" </small>
                                    </div>

                                    <label className="col-12">Plantilla de imágenes situación con PPI</label>
                                </div>

                                <div className="row row-file">
                                    <div className="col-1 file-icon">
                                        <FontAwesomeIcon icon={faFileAlt} />
                                    </div>
                                    <div className="col-8 text-collapsed file-name" style={{ textAlign: 'left' }}>
                                        <span>{this.state.plans_name}</span>
                                    </div>

                                    <div className="col-1 file-download" style={{ textAlign: 'center' }}>
                                        <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.planFile}`} download>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>



                    </div>
                </div>

            </>
        )

        seccion5 = (
            <>
                <div className="row title-section" disabled={VisualizarTodos}>
                    <div className="col-10">Análisis de la situación optimizada</div>

                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">

                                    <label className="col-12">Descripción de la posible medida de optimización</label>

                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">

                                        <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.asp_relevantlegal}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">

                                    <label className="col-12">Oferta optimizada</label>

                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">

                                        <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.offer_ppi}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">

                                    <label className="col-12">Demanda optimizada</label>

                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">

                                        <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.demand_ppi}</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row">

                                    <label className="col-12">Interacción optimiziada</label>

                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">

                                        <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.interaction_ppi}</div>

                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>


            </>
        )

        tabla1 = (
            <>


                <div className="row" style={{
                    display: this.state.VerTablaIndicadorAC
                }}>



                    <div className="col-10 offset-1">
                        <div className="row subtitle-section">
                            <div className="col-10">
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-sm-12">
                                <Table bordered className="table-not-padding force-table">
                                    <thead>
                                        <tr>
                                            <td colspan="7"><strong>Cuantificacion de Costos del PPI (sin incluir IVA)</strong> </td>
                                        </tr>
                                        <tr>
                                            <td style={{ 'width': '45px' }}><strong> Año </strong></td>
                                            <td><strong> Inversión </strong></td>
                                            <td><strong> Operación </strong></td>
                                            <td><strong> Mantenimiento</strong></td>
                                            <td><strong> Otros costos</strong></td>
                                            <td><strong> Total</strong></td>
                                            <td><strong> Valor presente de costos totales</strong></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.cost_quantification.map((row, index) => <tr key={'cost_' + row}>
                                            {/*console.log("Total actualizado", row.total_actualizado)}
                                                   {console.log("cost_quantification", this.state.cost_quantification)*/}
                                            <td>
                                                {row.year}
                                            </td>
                                            <td>
                                                $ {row.inversion.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </td>
                                            <td>
                                                $ {row.operation.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </td>
                                            <td>
                                                $ {row.maintenance.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </td>
                                            <td>
                                                $ {row.externalities.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </td>
                                            <td>
                                                $ {row.total}
                                            </td>
                                            <td>
                                                $ {row.total_actualizado.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                            </td>

                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td>TOTAL</td>
                                            <td>
                                                $ {this.state.total_inversion}
                                            </td>
                                            <td>
                                                $ {this.state.total_operation}
                                            </td>
                                            <td>
                                                $ {this.state.total_maintenance}
                                            </td>
                                            <td>
                                                $ {this.state.total_externalitie}
                                            </td>
                                            <td>
                                                $ {this.state.total_absolute}
                                            </td>
                                            <td>
                                                $ {this.state.total_absolute_actualizado}
                                            </td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>






            </>
        );

        seccion6 = (
            <>
                <div className="row title-section" disabled={VisualizarTodos}>
                    <div className="col-10">Alternativas de Solución</div>

                </div>
                <div className="row wrap-section">
                    <div className="col-12 not-padding">

                        <div className="row">
                            <div className="col-10 offset-1">

                                <div className="row subtitle-section">


                                    <div className="col-10">
                                        <h6>Alternativas de solución desechadas</h6>
                                    </div>

                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td><strong>Nombre de la alternativa</strong></td>
                                                    <td><strong>Descripción</strong></td>
                                                    <td><strong>Costo total (incluye IVA)</strong></td>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.optimization_measures.map((row, index) => <tr key={'typeoriginOM_' + index}>
                                                    <td>

                                                        {this.state.optimization_measures[index].nom_alt}

                                                    </td>
                                                    <td>

                                                        {this.state.optimization_measures[index].alternatives}

                                                    </td>
                                                    <td>

                                                        $ {fm.from(this.state.optimization_measures[index].totalCostVAT)}

                                                    </td>

                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">

                                <div className="row subtitle-section">


                                    <div className="col-10">
                                        <h6>Descripción de las ventajas y desventajas del PPI frente a las alternativas de solución desechadas</h6>
                                    </div>

                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <Table bordered className="table-not-padding">
                                            <thead>
                                                <tr>
                                                    <td><strong>Alternativa</strong></td>
                                                    <td><strong>Ventaja</strong></td>
                                                    <td><strong>Desventaja</strong></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.alternativas_solucion.map((row, index) => <tr key={'alternativa_solucion' + index}>
                                                    <td>

                                                        {this.state.alternativas_solucion[index].alternativa}

                                                    </td>
                                                    <td>
                                                        {this.state.alternativas_solucion[index].ventajas}

                                                    </td>
                                                    <td>
                                                        {this.state.alternativas_solucion[index].desventajas}

                                                    </td>


                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </>
        )

        tablaIndicadoresABC = (
            <>
                <div className="row title-section" disabled={VisualizarTodos}>
                    <div className="col-10">Anexo: Indicadores de rentabilidad</div>


                </div>


                <div className="row wrap-section">




                    <div className="col-12 not-padding">


                        {tabla1}


                        <div className="row" style={{
                            display: this.state.VerTablaIndicadorACE
                        }}>

                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">

                                    <div className="col-10">
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-sm-12">
                                        <Table bordered className="table-not-padding force-table">
                                            <thead>
                                                <tr>
                                                    <td colspan="7"><strong>Cuantificación del PPI alternativa 2 (sin incluir IVA)</strong></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ 'width': '45px' }}><strong>Año</strong></td>
                                                    <td><strong>Inversión</strong></td>
                                                    <td><strong>Operación</strong></td>
                                                    <td><strong>Mantenimiento</strong></td>
                                                    <td><strong>Otros costos</strong></td>
                                                    <td><strong>Total</strong></td>
                                                    <td><strong>Valor presente de costos totales</strong></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.cost_quantification_alt2.map((row, index) => <tr key={'cost_' + row}>
                                                    <td>
                                                        {this.state.cost_quantification_alt2[index].year}
                                                    </td>
                                                    <td>
                                                        $ {fm.from(this.state.cost_quantification_alt2[index].inversion)}
                                                    </td>
                                                    <td>
                                                        $ {fm.from(this.state.cost_quantification_alt2[index].operation)}
                                                    </td>
                                                    <td>
                                                        $ {fm.from(this.state.cost_quantification_alt2[index].maintenance)}
                                                    </td>
                                                    <td>
                                                        $ {fm.from(this.state.cost_quantification_alt2[index].externalities)}
                                                    </td>
                                                    <td>
                                                        $ {this.state.cost_quantification_alt2[index].total}
                                                    </td>
                                                    <td>
                                                        $ {this.state.cost_quantification_alt2[index].total_actualizado}
                                                    </td>

                                                </tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td><strong>Total</strong></td>
                                                    <td>

                                                        $ {this.state.total_inversion_alt2}

                                                    </td>
                                                    <td>
                                                        $ {this.state.total_operation_alt2}

                                                    </td>
                                                    <td>
                                                        $ {this.state.total_maintenance_alt2}

                                                    </td>
                                                    <td>
                                                        $ {this.state.total_externalitie_alt2}

                                                    </td>
                                                    <td>
                                                        $ {this.state.total_absolute_alt2}

                                                    </td>
                                                    <td>
                                                        $ {this.state.total_absolute_actualizado_alt2}

                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{
                            display: this.state.VerTablaIndicadorABC
                        }}>

                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-10">
                                        {/* <h4>Cuantificación de beneficios</h4> */}
                                    </div>

                                </div>
                                <div className="row">

                                    <div className="col-lg-12 col-sm-12">

                                        <Table bordered className="table-not-padding force-table">

                                            <thead>
                                                <tr>
                                                    <td colspan="7"><strong>Cuantificacion de Beneficios del PPI (sin incluir IVA)</strong></td>
                                                </tr>
                                                <tr>
                                                    <td rowspan="2" style={{ 'width': '45px' }}><strong>Año</strong></td>
                                                    <td colspan="2"><strong>Beneficios</strong></td>
                                                    <td rowspan="2"><strong>Total</strong></td>
                                                    <td rowspan="2"><strong>Valor presente de los beneficios totales</strong></td>
                                                    {/*<td rowspan="2">Beneficio neto (corriente)</td>
                                                    <td rowspan="2">TRI</td> */}
                                                </tr>
                                                <tr>
                                                    <td><strong>1</strong></td>
                                                    <td><strong>2</strong></td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.benefits_quantification.map((row, index) => <tr key={'cost_' + index}>
                                                    <td>
                                                        {row.year}
                                                    </td>
                                                    <td>
                                                        $ {fm.from(row.benefit_1)}
                                                    </td>
                                                    <td>
                                                        $ {fm.from(row.benefit_2)}
                                                    </td>
                                                    <td>
                                                        $ {row.total}
                                                    </td>
                                                    <td>
                                                        $ {row.total_beneficios}
                                                    </td>
                                                    {/*<td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {this.state.benefits_quantification[index].total_neto}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="$" 
                                                            style={{'text-align':'right'}}
                                                            name="cost_quantification" 
                                                            value={this.state.benefits_quantification[index].total_neto}
                                                            disabled={true}
                                                            />
                                                        </OverlayTrigger>
                                                        </td> */ }
                                                    <td>
                                                        {/*  
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.benefits_quantification[index].tri} %
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="0"
                                                            data-idx={index}
                                                            suffix={'%'}
                                                            disabled={true}
                                                            value={this.state.benefits_quantification[index].tri}
                                                            onChange={this.onChangehandlerBenefitTotal}
                                                            thousandSeparator={true}
                                                            
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>{ }  */}

                                                    </td>

                                                </tr>)}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td><strong>Total</strong></td>
                                                    <td>
                                                        <NumberFormat
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            name="name_ppi"
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            disabled={true}
                                                            value={this.state.total_benefit_1}
                                                            onChange={this.onChangehandler}
                                                        />
                                                    </td>
                                                    <td>
                                                        <NumberFormat
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            name="name_ppi"
                                                            disabled={true}
                                                            value={this.state.total_benefit_2}
                                                            onChange={this.onChangehandler}
                                                        />
                                                    </td>
                                                    <td>
                                                        <NumberFormat
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            name="name_ppi"
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            disabled={true}
                                                            value={this.state.total_benefit_absolute}
                                                            onChange={this.onChangehandler}
                                                        />
                                                    </td>
                                                    <td>
                                                        <NumberFormat
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Escribe aquí"
                                                            name="name_ppi"
                                                            thousandSeparator={true}
                                                            prefix={'$'}
                                                            disabled={true}
                                                            value={this.state.total_benefit_absolute_actualizado}
                                                            onChange={this.onChangehandler}
                                                        />
                                                    </td>
                                                    {/*<td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_benefit_absolute_neto}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        
                                                        </td>*/ }
                                                </tr>
                                            </tfoot>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row subtitle-section">
                                    <div className="col-sm-12 col-lg-6 offset-lg-3" style={{ 'textAlign': 'center' }}>
                                        <h6>Cálculo de indicadores de rentabilidad</h6>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <div className="col-8 col-lg-8" style={{
                                                display: this.state.VerTablaIndicadorABC
                                            }}>
                                                <Table bordered className="col-sm-12 col-lg-6 offset-lg-3">
                                                    <thead>
                                                        <tr>
                                                            <td colspan="2"><strong>Indicadores</strong></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><strong>VPN</strong></td>
                                                            <td>
                                                                $ {this.state.vpn}

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>TIR</strong></td>
                                                            <td>
                                                                % {this.state.tir}

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td><strong>TRI</strong></td>
                                                            <td>

                                                                % {this.state.tri}

                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <center><b>Año óptimo de inicio de operación</b></center>
                                                            </td>
                                                            <td>

                                                                {this.state.optimal_tri}

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>




                                            <div className="col-lg-8 col-sm-6" style={{
                                                display: this.state.VerTablaIndicadorACE,
                                                textAlign: "Center"
                                            }}>
                                                <Table bordered className="col-sm-12 col-lg-6 offset-lg-3">
                                                    <thead>
                                                        <tr>
                                                            <td colspan="2"><strong>CAE</strong></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <strong>CAE del PPI</strong>
                                                            </td>
                                                            <td>
                                                                $ {this.state.ppi_evaluado}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <strong> CAE Alternativa</strong>
                                                            </td>
                                                            <td>
                                                                $ {this.state.alternativa}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </>
        );

        seccionestudio = (
            <>
                <div className="row title-section" disabled={VisualizarTodos}>
                    <div className="col-10">Anexo: Estudio socioeconómico</div>

                </div>

                <div className="row wrap-section">
                    <div className="col-12 not-padding">

                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <small className="col-12 note text-left">Adjuntar evaluación socioeconómica, hoja de cálculo y demás archivos que respalden el estudio </small>

                                        </div>
                                    </div>


                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {this.state.archivos_nombre.length > 0 && this.state.archivos_nombre.map((archivo, idx) => (
                                            <div className="row row-file" key={'estudiosocioeconomico_' + idx}>
                                                <div className="col-1 file-icon">
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </div>
                                                <div className="col-8 text-collapsed file-name" style={{ 'textAlign': 'left' }}>
                                                    <span>{archivo.name}</span>
                                                </div>

                                                <div className="col-1 file-download" style={{ 'textAlign': 'center' }}>
                                                    {archivo.path && <div>
                                                        <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${archivo.path}`} download>
                                                            <FontAwesomeIcon icon={faDownload} />
                                                        </a>
                                                    </div>
                                                    }
                                                </div>


                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>



            </>
        )

        seccionConsideracionesFinales = (
            <>
                <div className="row title-section" disabled={VisualizarTodos}>
                    <div className="col-10">Consideraciones finales</div>

                </div>
               
                <div className="row wrap-section">
             
                    <div className="col-12 not-padding">
                    {this.state.fecha_creacion ? <div className="row col-10 offset-1">
                        {console.log("Fecha_creación", this.state.fecha_creacion)}
                     
                     <div className="row d-flex">
                     <div className="nota-nueva">
                         <strong>La dependencia solicitante es responsable de contar con los estudios técnicos, ambientales y legales, además de cualquier permiso, propuesta económica o documento que garantice la factibilidad de ejecución del PPI.</strong>
                     </div>
                     </div>
                 </div> : null }
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                               
                     
                  
                
                                    <div className="col-12">
                                        <div className="row">
                                     
                                            <div className="col-4">
                                                <label>Nombre del solicitante</label>
                                               
                                                {console.log("Nombre executor", this.state.creador)}


                                               
                                                    <p>{this.state.aproved_name}</p>
                                               
                                              

                                          

                                            </div>

                                            <div className="col-4">

                                                <label>Cargo</label>
                                                <p>{this.state.puesto}</p>

                                            </div>


                                            <div className="col-4">
                                                <label>Aprobado con PIN</label>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <br />
                                                    {/* <b style={{ 'color': '#2C70B9' }}>(Solicitante)</b> */}

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex" style={{ 'textAlign': 'left' }}>
                                    <div className="col-12">
                                        <div className="row">

                                            <div className="col-4">

                                                <label>Nombre del ejecutor</label>
                                                <p>{this.state.executor_name}</p>
                                            </div>

                                            <div className="col-4">

                                                <label>Cargo</label>
                                                <p>{this.state.executor_title}</p>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <p style={{ 'textAlign': 'left', 'margin-bottom': '0px' }}>
                                                    <br />
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">

                                <div className="row subtitle-section">


                                    <div className="col-12">
                                        <small className="note">Solo se permite PDF </small>
                                    </div>

                                    <label className="col-12">Oficio de responsabilidad de ejecución del PPI</label>
                                </div>


                                <div className="row row-file">
                                    <div className="col-1 file-icon">
                                        <FontAwesomeIcon icon={faFileAlt} />
                                    </div>
                                    <div className="col-8 text-collapsed file-name" style={{ textAlign: 'left' }}>
                                        <span>{this.state.responsable_document_name}</span>
                                    </div>

                                    <div className="col-1 file-download" style={{ textAlign: 'center' }}>
                                        <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.oficioFile}`} download>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-12">
                                        <div className="row">
                                            <label className="col-12">Comentarios finales</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-lg-12">

                                        <div style={{ 'textAlign': 'justify', 'white-space': 'pre-line' }}>{this.state.final_comments}</div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row wrap-section">
                    <div className="col-12 not-padding">
                        <div className="row">
                            <div className="col-10 offset-1">
                                <div className="row d-flex">
                                    <div className="col-lg-8">
                                        <div className="row">
                                            <small className="col-12 note text-left">Documentos adicionales que respalden la factibilidad del PPI</small>
                                            <label className="col-12">Documentos adjuntos <span className="fileName"></span></label>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {this.state.attachments_names.length > 0 && this.state.attachments_names.map((file, idx) => (
                                            <div className="row row-file" key={'attachment_' + idx}>
                                                <div className="col-1 file-icon">
                                                    <FontAwesomeIcon icon={faFileAlt} />
                                                </div>
                                                <div className="col-8 text-collapsed file-name" style={{ 'textAlign': 'left' }}>
                                                    <span>{file.name}</span>
                                                </div>
                                                <div className="col-1 file-size" style={{ 'textAlign': 'center' }}>
                                                    <span>{file.size}</span>
                                                </div>
                                                <div className="col-1 file-download" style={{ 'textAlign': 'center' }}>
                                                    {file.path && <div>
                                                        <a href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${file.path}`} download>
                                                            <FontAwesomeIcon icon={faDownload} />
                                                        </a>
                                                    </div>
                                                    }
                                                </div>
                                                {!isAdmin && !da_disabled && <div className="col-1" style={{ 'textAlign': 'center' }}>
                                                    <button className="col-12 btn btn-custom-delete" onClick={() => this.handleDeleteAttachmentsClick(file)}>
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </button>
                                                </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </>
        )

        return (
            <div className="col col-sm-12 col-md-11 NotaTecnica" style={{ 'margin-left': 'auto', 'margin-right': 'auto' }} onScroll={() => { this.handleScroll() }}>
                {message}

                {seccionclave}

                {this.state.tipoFormato.map((tipo, index) => {
                    // Agrega el console.log aquí
                    console.log("formato", this.state.formato);

                    return (
                        Number(tipo) === 1 && (
                            <div key={index}>
                                {index === 0 ? seccion1 :
                                    index === 1 ? seccion2 :
                                        index === 2 ? seccion3 :
                                            index === 3 ? (this.state.formato == 5 || this.state.formato == 6 ? seccion5 : seccion4) :

                                                index === 4 ? (this.state.formato == 5 || this.state.formato == 6 ? seccion4 : seccion5) :


                                                    index === 5 ? seccion6 :
                                                        index === 6 ? tablaIndicadoresABC :
                                                            index === 7 ? seccionConsideracionesFinales :
                                                                index === 8 ? seccionestudio :
                                                                    null}
                            </div>
                        )
                    )
                })}


                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Body>
                        {this.state.icon === 'warning' &&
                            <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        }
                        {this.state.icon === 'success' &&
                            <FontAwesomeIcon style={{ 'color': '#4CAF50' }} icon={faCheckCircle} size="2x" />
                        }
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Volver</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalConfirm} onHide={this.handleCloseConfirm}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">¡Advertencia!</div>
                        <p className="size-16 pb-4 col col-10 offset-1">Estás a punto de borrar un documento adjunto, ¿deseas continuar?</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-secondary primary h-30 col col-6" onClick={this.handleCloseConfirm}>Cancelar</div>
                            <div className="d-block btn btn-small btn-danger h-30 col col-6" onClick={this.ConfirmDeleteAttachment}>Si, Borrar</div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.showModalDeleteImage} onHide={this.handleCloseDeleteImage}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#4CAF50' }} icon={faCheckCircle} size="2x" />
                        <div className="size-24 bold pb-2">¡Adjuntos actualizados!</div>
                        <p className="size-16 pb-4 col col-10 offset-1">La lista de documentos adjuntos ha sido actualizada</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-primary primary h-30 col col-12" onClick={this.handleCloseDeleteImage}>Aceptar</div>
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal show={this.state.ShowModalCancelCCConfirm} onHide={this.handleCloseConfirmCancelCC}>
                    <Modal.Body>
                        <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                        <div className="size-24 bold pb-2">¡Advertencia!</div>
                        <p className="size-16 pb-4 col col-10 offset-1"> Estas a punto de cancelar la clave cartera</p>

                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                            <div className="d-block btn btn-small btn-secondary primary h-30 col col-6" onClick={this.handleCloseConfirmCancelCC}>Cancelar</div>
                            <div className="d-block btn btn-small btn-danger h-30 col col-6" onClick={this.ConfirmDeleteClaveCC}>Si, Cancelar clave cartera</div>
                        </div>
                    </Modal.Body>
                </Modal>


                {
                    this.props.sidebar ?
                        <div className="float-button-comments" onClick={this.handleClickUp}>
                            <div>
                                <button className="btn btn-float">
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </button>
                            </div>
                        </div>
                        :
                        <div className="float-button" onClick={this.handleClickUp}>
                            <div>
                                <button className="btn btn-float">
                                    <FontAwesomeIcon icon={faChevronUp} />
                                </button>
                            </div>
                        </div>
                }
                <NotificationContainer />
            </div>
        );
    }

}