import React, { Component, } from 'react';
import { Redirect } from "react-router-dom";
import ReactDOM from 'react-dom';
import axios from 'axios';
import Menu from '../Menu/Menu';
import './assets/index.scss';
import ReactHtmlParser from 'react-html-parser';
import TopBarTitle from '../TopBars/TopBarTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faSearch,
    faSpinner
} from '@fortawesome/free-solid-svg-icons';

const $ = require('jquery');
$.Datatable = require('datatables.net-dt');


class Kardex extends Component {
    _isMounted = false;
    
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            ppi: '',
            color_aleatorio: '',
            data: false,
            url: '',
            userRole: 0,
            redirect: false,
            status: null,
            datatable: [],
            loading: false,
            status_list: {
                '1':'Validando',
                '2':'Devuelto Con Observaciones',
                '3':'Validando Con Correciones',
                '4':'Aprobado para llenado',
                '5':'En Proceso',
                '6':'En revisión',
                '7':'Eliminado',
                '8':'Aprobado',
                '9':'No aprobado',
                '10':'Cancelado',
                '11':'Validando Reactivado',
            },
            actions_list: {
                'insert': 'Creado',
                'update': 'Actualizado',
                'updated': 'Actualizado',
                'deleted': 'Borrado',
                'sent': 'Enviado a revisión',
                'saved': 'Información Guardada'
            }
        };
    }

    componentDidMount(){
        $('div.dataTables_filter input').addClass('mySeachClass');
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    onChangePPIHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value.toUpperCase();
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    searchPPI = () => {
        let search = this.state.ppi;
        
        this.setState({
            loading: true
        });


        
        axios.get(`${this.api}/kardex/${search}`)
        .then((response) => {
            this.setState({
                datatable: response.data
            });
            

            this.createTable();
            
            this.setState({
                loading: false
            });
        })
        .catch((error) => {
            console.log(error)
            
            this.setState({
                loading: false
            });
        });

        
    }

    createTable(){

        this.$el = $(this.el);
    
        let datatale = [];
        this.state.datatable.map(kardex => {
            let fecha = kardex.created_at.split('T');
            fecha = fecha[0].split('-');
            fecha = fecha[2]+"/"+fecha[1]+"/"+fecha[0];

            let data = kardex.new_data.split('|');
            let dataText = '';
            
            data.map((dato, idx) => {
                let item = dato.split(':');
                dataText += item[0]+" : "+item[1]+"<br />";
            });

            let tmp = [
                kardex.user,
                kardex.role,
                kardex.movement_type,
                dataText,
                kardex.step,
                // kardex.table,
                fecha
            ];
            datatale.push(tmp);
            return true;
        });

        var table = this.$el.DataTable(
            {
                data: datatale,
                destroy: true,
                order: [5, 'desc'],
                columns: [
                    { title: 'Usuario'},
                    { title: 'Perfil'},
                    { title: 'Tipo de Movimiento'},
                    { title: 'Información Actualizada'},
                    { title: 'Estatus'},
                    // { title: 'Tabla Afectada'},
                    { title: 'Fecha'},
                ],
                language: {
                    "lengthMenu": "Mostrando _MENU_ registros por página",
                    "info": "Mostrando página _PAGE_ de _PAGES_",
                    "infoEmpty": "Sin registros encontrados",
                    "infoFiltered": "(filtrados de _MAX_ registros totales)",
                    "search": "Filtrar:",
                    "zeroRecords": "No se encontraron registros",
                    "paginate": {
                        "first":      "Primero",
                        "last":       "Último",
                        "next":       "Siguiente",
                        "previous":   "Anterior"
                    },
                },
                columnDefs: [
                    {
                        targets: [0],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                            <div
                                id={rowData.folio}
                            > 
                                {cellData} 
                            </div>, td
                        ),
                    },
                    {
                        targets: [1],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                                <div
                                    id={rowData.folio}
                                > 
                                    {cellData}
                                </div>, td
                        ),
                    },
                    {
                        targets: [2],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                                <div className={cellData}
                                    id={rowData.folio}
                                > 
                                    {
                                    this.state.actions_list[cellData]
                                    }
                                </div>, td
                        ),
                    },
                    {
                        targets: [3],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                                <div
                                    id={rowData.folio}
                                > 
                                    {ReactHtmlParser(cellData)}
                                </div>, td
                        ),
                    },
                    {
                        targets: [4],
                        createdCell: (td, cellData, rowData) =>
                        ReactDOM.render(
                                <div
                                    id={rowData.folio}
                                    // onClick={() => {
                                    // this.handleClickActivateOrNot(rowData);
                                    // }}
                                > 
                                {
                                    this.state.status_list[cellData]
                                } 
                                </div>, td
                        ),
                    },
                ]
            }
        );

        table.on( 'search.dt', function () {
            localStorage.setItem( 'SeachFilter', table.search() );
        } );
        if(localStorage.getItem('SeachFilter')!=null && localStorage.getItem('SeachFilter')!="")
                table.search( localStorage.getItem('SeachFilter') ).draw();
    }

    handleClick = (element, status) => {
        let id = element[0];
        let user = JSON.parse(localStorage.getItem('user'));
        let path;
        path = `/security/user/${id}`;
        
        this.setState({
            url: path,
            redirect: true,
            userRole: user.fk_role
        });
    }
    handleClickActivateOrNot = (element) => {
        let id = element[0];
        
        axios.delete(`${this.api}/users/${id}`)
        .then((response) => {
            window.location.reload();
        })
        .catch((error) => {
            console.log(error)
        });
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if(this.state.redirect){
            return <Redirect to={this.state.url} />
        }
        return (
            <div className="main-wrap Catalogs">
                <Menu />
                <div className="wrapper col col-10">
                    <div className="data-content">
                        <TopBarTitle title={'Historial de PPI'} subtitle={'Historial de cambios por PPI'} catalog={'on'} path={'users'} security={true} back={'/projects'}/>                    
                        <div className="col-10 offset-1 list">
                            <div className="col-12 d-inline-flex p-2" style={{'margin-bottom':'10px'}}>
                                <label style={{'margin-top':'5px'}}>Folio: </label>
                                <input 
                                    type="text" 
                                    className="form-control col-2" 
                                    name='ppi' 
                                    onChange={this.onChangePPIHandler} 
                                    style={{'margin-left':'15px'}} 
                                    value={this.state.ppi}
                                    onSubmit={this.searchPPI}
                                    />
                                <button className="btn btn-primary" style={{'margin-left':'15px'}} onClick={this.searchPPI}>
                                    {
                                        this.state.loading ? 
                                        <FontAwesomeIcon style={{'color':'#FFFFFF'}} icon={faSpinner} spin size="1x" />
                                        :
                                        <FontAwesomeIcon style={{'color':'#FFFFFF'}} icon={faSearch} size="1x" />                                        
                                    } Buscar
                                </button>
                            </div>
                            <table className="display col-12" width="100%" ref={el => this.el = el}></table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
export default Kardex;
