import React, { Component } from 'react';
import './TopPPIS.scss';
import './assets/TopBar.scss';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import validator from 'validator';
import { faArrowLeft, faTimesCircle, faExclamationTriangle, faSave, faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormatMoney }  from 'format-money-js';
import axios from 'axios';
import $ from 'jquery';

class TopBar extends Component{
    constructor(props){
        super(props);
        this.api = window.$domain;
        this.state = {
            redirect: false,
            showModal: false,
            loadingSave: false,
            loadingSend: false,
            titleModal: '',
            messageModal: '',
            buttonModal: '',
            iconModal : '',
            sent: false,
            refreshComponent: false,
            refrescar: false,
        };
    }
    componentDidMount() {
    }
    handleClose = () => {
        let refrescar = false;
        
        if(this.state.refrescar){
            window.location.reload()
        }
        this.setState({
            showModal: false,
            refreshComponent: refrescar 
        });
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }
    
    /* JHONA VALIDACIONES PARA FICHA TECNICA Y SUS VARIANTES */
    /* JHONA SI QUIERES LOS PUEDES MOVER A UN ARCHIVO EXTERNO */
    SaveDataFichaTecnica = () => {
        let allData = this.props.data.data;
        let user = JSON.parse(localStorage.getItem('user'));
        let flag = true;
        
        if(allData === null){
            return false;
        }
       

        if(user.fk_role !== 1){
            flag = true;

            if (allData.fk_format == 5) {
                let Arr2 = allData.program_or_projects;

                Arr2.forEach(element => {
                    if (element.type === null || element.relationship === null || element.type === "" || element.relationship === "") {
                        this.setState({
                            iconModal: <FontAwesomeIcon
                                icon={faTimesCircle}
                                size={'3x'}
                                style={{ 'color': '#C41622', 'marginBottom': '20px' }} />,
                            showModal: true,
                            titleModal: 'Datos requeridos',
                            messageModal: 'No has seleccionado Programas o proyectos complementarios o relacionados, escribir para poder continuar.'
                        });
                        flag = false;
                        return false;

                    }
                });
            }
            
            if(allData.dependency_executor === 0 || allData.dependency_executor === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la dependencia ejecutora, seleccionala para poder continuar.'
                });
                return false;
            }

            if( (allData.hde_executionStartDate && !validator.isEmpty(allData.hde_executionStartDate)) && (allData.hde_executionEndDate && !validator.isEmpty(allData.hde_executionEndDate))){
                let StartDate = allData.hde_executionStartDate.split("-");
                let StartMonth = Number(StartDate[1]) - 1;
                let newSDate = new Date(StartDate[0], StartMonth, StartDate[2]);

                let EndDate = allData.hde_executionEndDate.split("-");
                let EndMonth = Number(EndDate[1]) - 1;
                let newEDate = new Date(EndDate[0], EndMonth, EndDate[2]);
                
                if(newSDate.getTime() > newEDate.getTime()){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faTimesCircle} 
                                    size={'3x'} 
                                    style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Error en las fechas',
                        messageModal: 'La fecha de inicio no puede ser mayor a la fecha de término'
                    });
                    return false;
                }
            }

            // citySelected

            let percentageFunding = 0;
            allData.founding_sources_origin.forEach((item) => {
                percentageFunding += Number(item.percentage);
            });

            if(percentageFunding > 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El porcentaje total de las fuentes de financiamiento excede el 100%, por favor revisa tus datos'
                });
                return false;
            }
            let percentageCalendar = 0;
            allData.calendar_investment.forEach((item) => {
                percentageCalendar += Number(item.physical);
            });

            if(percentageCalendar > 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El porcentaje total del calendario de inversiones excede el 100%, por favor revisa tus datos'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && !validator.isNumeric(allData.applicant_pin.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Ingresa un NIP correcto.'
                });
                return false;
            }
            
            if(!validator.isEmpty(allData.applicant_pin.trim()) && Number(allData.applicant_pin.trim()) !== user.pin){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Tú NIP no coincide, verifica tú información.'
                });
                return false;
            }
            
            allData.founding_sources_origin.map((source) => {
                if(source.amount.length > 0 && validator.isNumeric(source.amount.replaceAll(',','')) && Number(source.amount.replaceAll(',','')) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El monto de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });

            if(!flag){
                return false;
            }       
        }else{
            if(validator.isEmpty(allData.name_ppi)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'El nombre del PPI no puede ir vacío.'
                });
                return false;
            }
        }

        this.setState({loadingSave:true});
        //data 1
        let data = {
            name_ppi: allData.name_ppi.trim(),
            classification: allData.classificationSelected,
            funding_sources: allData.founding_sources_origin,
            program_or_projects: allData.program_or_projects,
            
            optimization_measures_sppi: allData.optimization_measures_sppi,            
            
            data_sheet_extra_annex_benefits: allData.data_sheet_extra_annex_benefits,
            data_sheet_extra_annex_costs: allData.data_sheet_extra_annex_costs,
            data_sheet_extra_iccb_benefits: allData.data_sheet_extra_iccb_benefits,
            data_sheet_extra_iccb_costs: allData.data_sheet_extra_iccb_costs,
            data_sheet_extra_pre_investments: allData.data_sheet_extra_pre_investments,
            cost_quantification_alt2: allData.cost_quantification_alt2,

            optimization_measures: allData.optimization_measures,
            investment_calendar: allData.calendar_investment,
            components: allData.components,
            //plans
            plans: allData.plans,
           // responsable_document: allData. responsable_document,
            goals: allData.goals,
            dependency_executor: allData.dependency_executor,
            cities: allData.citySelected,
            relationated_programs: allData.relationated_program.trim(),
            objectives: allData.objectives.trim(),
            action_lines: allData.action_lines.trim(),
            coordinates: allData.coordinatesData,
            observations_location: allData.observations_location,
            description: allData.description.trim(),
            offer_analysis: allData.current_situation_offer.trim(),
            analysis_of_demand: allData.demand_current_situation.trim(),
            interaction: allData.intereaction_current_situation.trim(),
            general_description: allData.general_description.trim(),
            objective_general: allData.objective_general.trim(),
            offer_analysis_ppi: allData.offer_ppi.trim(),
            analysis_of_demand_ppi: allData.demand_ppi.trim(),
            interaction_ppi: allData.interaction_ppi.trim(),
            execution_results: allData.execution_result.trim(),
            ppi_beneficiaries: allData.beneficiaries.toString().trim(),
            executive_project: allData.executive_project,
            executive_project_description: allData.executive_project_desc.trim(),
            permissions: allData.permissions,
            permissions_description: allData.permissions_desc.trim(),
            enviromental_studies: allData.enviromental_studies,
            enviromental_studies_description: allData.enviromental_studies_desc.trim(),
            property_rights: allData.property_rights,
            property_rights_description: allData.property_rights_desc.trim(),
            final_comments: allData.final_comments.trim(),
            name_applicant: allData.name_applicant,
            applicant_title: allData.applicant_title,
            approval_pin: allData.applicant_pin.trim(),
            name_executor: allData.executor_name.trim(),
            executor_title: allData.executor_title.trim(),
            
            hde_executionStartDate:allData.hde_executionStartDate,
            hde_executionEndDate:allData.hde_executionEndDate,
            hde_numberYearsOperation:allData.hde_numberYearsOperation,
            mti_amountForStudies:allData.mti_amountForStudies,
            mti_totalAmountWithVAT :allData.mti_totalAmountWithVAT,
            mti_totalAmountWithoutVAT :allData.mti_totalAmountWithoutVAT,

            asa_offer_analysus: allData.asa_offer_analysus.trim(),
            asa_offer_analysis: allData.asa_offer_analysis.trim(),
            asa_interaction: allData.asa_interaction.trim(),
            alternative_justification:  allData.alternative_justification.trim(),

            asp_relevantlegal:  allData.asp_relevantlegal.trim(),
            asp_relevantenvironmental :  allData.asp_relevantenvironmental.trim(),
            asp_relevanttechnicians:  allData.asp_relevanttechnicians.trim(),
            annexed_vpn: allData.annexed_vpn,
            annexed_tir: allData.annexed_tir,
            annexed_tri: allData.annexed_tri,
            optimal_tri: allData.optimal_tri,
            annexed_cae_pe: allData.annexed_cae_pe,
            annexed_cae_alternative: allData.annexed_cae_alternative
        }
        axios.post(`${this.api}/ppi/save/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            this.setState({
                iconModal: <FontAwesomeIcon 
                            icon={faCheck} 
                            size={'3x'} 
                            style={{'color': '#6F9937', 'margin-bottom': '20px'}} />,
                showModal: true,
                titleModal : response.data.message,
                messageModal: 'La información se guardó correctamente, el estatus se mantendra como en proceso hasta que envíes el formato a revisión.',
                loadingSave: false,
                refrescar: true
            });
        })  
        .catch((error) => {
            this.setState({loadingSave: false});
        }).finally(() => {
            
        });
    }
    SendDataFichaTecnica = () => {
        let flag = true;
        let allData = this.props.data.data;
      //  console.log(allData);
        //flag = false;
        let user = JSON.parse(localStorage.getItem('user'));
        
        if(allData === null){
            return false;
        }
        const fm = new FormatMoney({
            decimals: 2
        });

        
        if(user.fk_role !== 1){

            if (allData.fk_format == 5) {
                let Arr2 = allData.program_or_projects;

                Arr2.forEach(element => {
                    if (element.type === null || element.relationship === null || element.type === "" || element.relationship === "") {
                        this.setState({
                            iconModal: <FontAwesomeIcon
                                icon={faTimesCircle}
                                size={'3x'}
                                style={{ 'color': '#C41622', 'marginBottom': '20px' }} />,
                            showModal: true,
                            titleModal: 'Datos requeridos',
                            messageModal: 'No has seleccionado Programas o proyectos complementarios o relacionados, escribir para poder continuar.'
                        });
                        flag = false;
                        return false;

                    }
                });
            }

            if(validator.isEmpty(allData.name_ppi)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has escrito el nombre del PPI para poder continuar.'
                });
                return false;
            }
            if(allData.classificationSelected === 0 || allData.classificationSelected === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has la clasificación del PPI para poder continuar.'
                });
                return false;
            }
            if(allData.citySelected === 0 || allData.citySelected === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la ciudad del PPI para poder continuar.'
                });
                return false;
            }
            if(allData.dependency_executor === 0 || allData.dependency_executor === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la dependencia ejecutora, seleccionala para poder continuar.'
                });
                return false;
            }

            if( (allData.hde_executionStartDate && !validator.isEmpty(allData.hde_executionStartDate)) && (allData.hde_executionEndDate && !validator.isEmpty(allData.hde_executionEndDate))){
                let StartDate = allData.hde_executionStartDate.split("-");
                let StartMonth = Number(StartDate[1]) - 1;
                let newSDate = new Date(StartDate[0], StartMonth, StartDate[2]);

                let EndDate = allData.hde_executionEndDate.split("-");
                let EndMonth = Number(EndDate[1]) - 1;
                let newEDate = new Date(EndDate[0], EndMonth, EndDate[2]);
                
                if(newSDate.getTime() > newEDate.getTime()){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faTimesCircle} 
                                    size={'3x'} 
                                    style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Error en las fechas',
                        messageModal: 'La fecha de inicio no puede ser mayor a la fecha de término'
                    });
                    return false;
                }
            }

            let totalPercentageFunding = 0;

            allData.founding_sources_origin.map((source) => {
                if(source.origin === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has seleleccionado un origen en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                if(source.origin === 2 && source.subsource === null){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has seleleccionado un fondo de origen tipo federal en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                if(source.origin === 5 && (source.other === null || validator.isEmpty(source.other.trim()))){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has ingresado un fondo de origen tipo otro en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                
                if(source.percentage.length > 0 && validator.isNumeric(source.percentage) && Number(source.percentage) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El porcentaje de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }else{
                    totalPercentageFunding += Number(source.percentage);
                }
                if(source.amount.length > 0 && validator.isNumeric(source.amount.replaceAll(',','')) && Number(source.amount.replaceAll(',','')) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El monto de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });

            if(!flag){
                return false;
            }

            if(totalPercentageFunding !== 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del porcentaje de las fuentes de financiamiento deben ser igual a 100%'
                });
                return false;
            }

            let totalPhysicalPercentage = 0;
            let totalFinancial = 0;
            allData.calendar_investment.map((element) => {
                if(validator.isEmpty(element.advance.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has ingresado un valor en avance en el calendario de inversiones.'
                    });
                    flag = false;
                    return false;
                }
                
                if(element.physical !== null){
                    totalPhysicalPercentage += parseFloat(element.physical);
                }
                if(isNaN(element.financial) && element.financial !== null){
                    totalFinancial += parseFloat(element.financial.replaceAll(',',''));
                }else if(element.financial !== null){
                    totalFinancial += parseFloat(element.financial);
                }
                return true;
            });

            if(!flag){
                return false;
            }

            if(totalPhysicalPercentage !== 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del porcentaje del calendario de inversiones deben ser igual a 100%'
                });
                return false;
            }

            if(parseFloat(totalFinancial).toFixed(2) !== parseFloat(allData.amount).toFixed(2)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del monto financiero del calendario de inversiones deben ser igual a $'+fm.from(allData.amount),
                });
                return false;
            }

            if((allData.coordinatesData.length === 0)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'No has seleccionado aún la localización geográfica'
                });
                return false;
            }

            if(validator.isEmpty(allData.relationated_program.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa los programas relacionados para poder continuar'
                });
                return false;
            }

            if(validator.isEmpty(allData.objectives.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa los objetivos/estrategias para poder continuar'
                });
                return false;
            }

            if(validator.isEmpty(allData.action_lines.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa las líneas de acción para poder continuar'
                });
                return false;
            }
            if(validator.isEmpty(allData.description.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa la descripción de la problemática para poder continuar'
                });
                return false;
            }

            if(allData.PicFile === null && allData.imagesFile === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has cargado el documento con las imágenes de evidencia de la problemática, cargalo para poder continuar'
                });
                return false;
            }

            if(Number(allData.fk_format) !== 7 && allData.optimization_measures_sppi && allData.optimization_measures_sppi.length == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos de las Posibles medidas de optimización (Análisis de la Situación Sin PPI)'
                });
                return false;
            }

            if(Number(allData.fk_format) !== 7 && allData.optimization_measures_sppi){
                allData.optimization_measures_sppi.map((oms, index) => {
                    if(oms.medidas != null && validator.isEmpty(oms.medidas.trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado una medida de las Posibles medidas de optimización (Análisis de la Situación Sin PPI)'
                        });
                        flag = false;
                        return false;
                    }
                    if(oms.desc != null && validator.isEmpty(oms.desc.trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado una descripción de las Posibles medidas de optimización (Análisis de la Situación Sin PPI)'
                        });
                        flag = false;
                        return false;
                    }
                });
            }
            if(!flag){
                return false;
            }
            


            if(Number(allData.fk_format) !== 7 && allData.optimization_measures && allData.optimization_measures.length == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos de las Posibles medidas de optimización (Alternativas de Solución)'
                });
                return false;
            }

            if(Number(allData.fk_format) !== 7 && allData.optimization_measures){
                allData.optimization_measures.map((om, index) => {
                    if(om.alternatives != null && validator.isEmpty(om.alternatives.trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado la descripción de las alternativas de solución desechadas en las Posibles medidas de optimización (Alternativas de Solución)'
                        });
                        flag = false;
                        return false;
                    }
                    if(om.totalCostVAT != null && om.totalCostVAT.length == 0){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado el costo total (incluye IVA) en las Posibles medidas de optimización (Alternativas de Solución)'
                        });
                        flag = false;
                        return false;
                    }
                });
            }
            
            if(!flag){
                return false;
            }
            if(validator.isEmpty(allData.general_description.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa la descripción general para poder continuar'
                });
                return false;
            }
            
            if(Number(allData.fk_format) !== 7){
                var total_amount_component = 0;
                allData.components.map((element) => {
                    if(element.name_concept && validator.isEmpty(element.name_concept.trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado el nombre de un componente en la descripción de los componentes del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    if(element.number_concept && element.number_concept === 0){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado el número de un concepto en uno de los componentes del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    if(element.description_concept && validator.isEmpty(element.description_concept.trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado la descripción de un concepto en uno de los componentes del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    if(element.number_item && element.number_item === 0){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado el número de partidas de uno de los componentes del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    if(element.description_item && validator.isEmpty(element.description_item.trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado la descripción de una partida en uno de los componentes del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    if(validator.isEmpty(element.description.trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado la descripción de un componente en la descripción de los componentes del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    if(Number(element.unit_of_measure) === 0 && element.unit_of_measure != null){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado la unidad de medida de un componente en la descripción de los componentes del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    if(validator.isEmpty(element.quantity.toString().trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado la cantidad de un componente en la descripción de los componentes del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    if(validator.isEmpty(element.unit_price.toString().trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado el precio unitario de un componente en la descripción de los componentes del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    total_amount_component += parseFloat(element.total_amount);
                    return true;
                });
                if(!flag){
                    return false;
                }
    
                var total_components = Number.parseFloat(total_amount_component * 1.16).toFixed(2);
                var cantidad_financiada = Number.parseFloat(allData.amount).toFixed(2);
                if(total_components !== cantidad_financiada){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El valor total de los componentes no coincide con el monto a financiar, revisa tus datos.'
                    });
                    return false;
                }
                
                allData.goals.map((element) => {
                    if(validator.isEmpty(element.name_concept.trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado el nombre de un concepto en las metas del PPI'
                        });
                        flag = false;
                        return false;
                    }
    
                    if(Number(element.unit_of_measure) === 0 && element.unit_of_measure != null){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado la unidad de medida de un concepto en las metas del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    if(validator.isEmpty(element.quantity.trim())){
                        this.setState({
                            iconModal: <FontAwesomeIcon 
                                        icon={faExclamationTriangle} 
                                        size={'3x'} 
                                        style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                            showModal: true,
                            titleModal : 'Valor requerido',
                            messageModal: 'Aún no has ingresado la cantidad de un concepto en las metas del PPI'
                        });
                        flag = false;
                        return false;
                    }
                    return true;
                });
                if(!flag){
                    return false;
                }
            }
            

            if(validator.isEmpty(allData.objective_general.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado el objetivo general del PPI para continuar'
                });
                return false;
            }

            if(allData.planFile === null && allData.fk_format == 5 || allData.planFile === "" && allData.fk_format == 5){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has cargado el documento plano arquitectónico, corte transversal y/o render, cargalo para poder continuar'
                });
                return false;
            }



            if(allData.fk_format !== 7){
                if(validator.isEmpty(allData.offer_ppi.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el análisis de la oferta con PPI para continuar'
                    });
                    return false;
                }
                if(validator.isEmpty(allData.demand_ppi.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el análisis de la demanda con PPI para continuar'
                    });
                    return false;
                }
                if(validator.isEmpty(allData.interaction_ppi.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la interacción de la oferta y demanda con PPI para continuar'
                    });
                    return false;
                }
                if(validator.isEmpty(allData.execution_result.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el Resultados de la ejecución y beneficios económicos y/o sociales para continuar'
                    });
                    return false;
                }
                if(validator.isEmpty(allData.beneficiaries.toString().trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el número de beneficiarios del PPI para continuar'
                    });
                    return false;
                }
            }
            if(validator.isEmpty(allData.applicant_pin.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Campo requerido',
                    messageModal: 'Ingresa tú NIP para poder continuar con el envío.'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && Number(allData.applicant_pin.trim()) !== user.pin){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Ingresa un NIP correcto.'
                });
                return false;
            }
            if(validator.isEmpty(allData.executor_name.toString())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Aún no has ingresado el nombre del responsable ejecutor del PPI para continuar'
                });
                return false;
            }

            
            let flagAnex = true;
            if(allData.project !== null && (allData.project === 2 && allData.amount >= 30000000)){
                if(Object.keys(allData.data_sheet_extra_annex_costs).length === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos'
                    });
                    flagAnex = false;
                }
                
                if(!flagAnex){
                    return false;
                }

                let QDataC1 = 0; //Cantidad de datos en tabla costos 1
                allData.data_sheet_extra_annex_costs.map((cost1, index) => {
                    let flagCost = false;
                    if(cost1.investment !== null && cost1.investment.toString().trim().length > 0){
                        flagCost = true;
                    }
                    if(cost1.operation !== null && cost1.operation.toString().trim().length > 0){
                        flagCost = true;
                    }
                    if(cost1.maintenance !== null && cost1.maintenance.toString().trim().length > 0){
                        flagCost = true;
                    }
                    if(cost1.externalities !== null && cost1.externalities.toString().trim().length > 0){
                        flagCost = true;
                    }

                    if(flagCost){
                        QDataC1++;
                    }
                });
                
                if(QDataC1 == 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos'
                    });
                    flagAnex = false;
                }
                
                if(Object.keys(allData.cost_quantification_alt2).length == 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos Alternativa 2'
                    });
                    flagAnex = false;
                }
                
                if(!flagAnex){
                    return false;
                }

                let QDataC2 = 0; //Cantidad de datos en tabla costos 2
                allData.cost_quantification_alt2.map((cost2, index) => {
                    let flagCost = false;
                    if(cost2.inversion !== null && cost2.inversion.toString().trim().length > 0){
                        flagCost = true;
                    }
                    if(cost2.operation !== null && cost2.operation.toString().trim().length > 0){
                        flagCost = true;
                    }
                    if(cost2.maintenance !== null && cost2.maintenance.toString().trim().length > 0){
                        flagCost = true;
                    }
                    if(cost2.externalities !== null && cost2.externalities.toString().trim().length > 0){
                        flagCost = true;
                    }

                    if(flagCost){
                        QDataC2++;
                    }
                });
                
                if(QDataC2 == 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos Alternativa 2'
                    });
                    flagAnex = false;
                }

                
                if(!flagAnex){
                    return false;
                }

                if(Object.keys(allData.data_sheet_extra_annex_benefits).length == 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de beneficios'
                    });
                    flagAnex = false;
                }
                
                if(!flagAnex){
                    return false;
                }

                let QDataBEN = 0; //Cantidad de datos en tabla beneficios
                allData.data_sheet_extra_annex_benefits.map((ben, index) => {
                    let flagCost = false;
                    if(ben.ben1 !== null && ben.ben1.toString().trim().length > 0){
                        flagCost = true;
                    }
                    if(ben.ben2 !== null && ben.ben2.toString().trim().length > 0){
                        flagCost = true;
                    }
                    if(flagCost){
                        QDataBEN++;
                    }
                });
                
                if(!flagAnex){
                    return false;
                }
                
                if(QDataBEN == 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de beneficios'
                    });
                    flagAnex = false;
                }
                
                if(!flagAnex){
                    return false;
                }
            }
        }else{
            if(validator.isEmpty(allData.name_ppi.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'El nombre del PPI no puede ir vacío.'
                });
                return false;
            }
        }
                
        this.setState({loadingSend : true});

        let data = {
            name_ppi: allData.name_ppi.trim(),
            classification: allData.classificationSelected,
            funding_sources: allData.founding_sources_origin,
            program_or_projects: allData.program_or_projects,
            
            optimization_measures_sppi: allData.optimization_measures_sppi,            
            
            data_sheet_extra_annex_benefits: allData.data_sheet_extra_annex_benefits,
            data_sheet_extra_annex_costs: allData.data_sheet_extra_annex_costs,
            data_sheet_extra_iccb_benefits: allData.data_sheet_extra_iccb_benefits,
            data_sheet_extra_iccb_costs: allData.data_sheet_extra_iccb_costs,
            data_sheet_extra_pre_investments: allData.data_sheet_extra_pre_investments,
            cost_quantification_alt2: allData.cost_quantification_alt2,

            optimization_measures: allData.optimization_measures,
            investment_calendar: allData.calendar_investment,
            components: allData.components,
            goals: allData.goals,
            dependency_executor: allData.dependency_executor,
            cities: allData.citySelected,
            relationated_programs: allData.relationated_program.trim(),
            objectives: allData.objectives.trim(),
            action_lines: allData.action_lines.trim(),
            coordinates: allData.coordinatesData,
            observations_location: allData.observations_location,
            description: allData.description.trim(),
            offer_analysis: allData.current_situation_offer.trim(),
            analysis_of_demand: allData.demand_current_situation.trim(),
            interaction: allData.intereaction_current_situation.trim(),
            general_description: allData.general_description.trim(),
            objective_general: allData.objective_general.trim(),
            offer_analysis_ppi: allData.offer_ppi.trim(),
            analysis_of_demand_ppi: allData.demand_ppi.trim(),
            interaction_ppi: allData.interaction_ppi.trim(),
            execution_results: allData.execution_result.trim(),
            ppi_beneficiaries: allData.beneficiaries.toString().trim(),
            executive_project: allData.executive_project,
            executive_project_description: allData.executive_project_desc.trim(),
            permissions: allData.permissions,
            permissions_description: allData.permissions_desc.trim(),
            enviromental_studies: allData.enviromental_studies,
            enviromental_studies_description: allData.enviromental_studies_desc.trim(),
            property_rights: allData.property_rights,
            property_rights_description: allData.property_rights_desc.trim(),
            final_comments: allData.final_comments.trim(),
            name_applicant: allData.name_applicant,
            applicant_title: allData.applicant_title,
            approval_pin: allData.applicant_pin.trim(),
            name_executor: allData.executor_name.trim(),
            executor_title: allData.executor_title.trim(),
            
            hde_executionStartDate:allData.hde_executionStartDate,
            hde_executionEndDate:allData.hde_executionEndDate,
            hde_numberYearsOperation:allData.hde_numberYearsOperation,
            mti_amountForStudies:allData.mti_amountForStudies,
            mti_totalAmountWithVAT :allData.mti_totalAmountWithVAT,
            mti_totalAmountWithoutVAT :allData.mti_totalAmountWithoutVAT,

            asa_offer_analysus: allData.asa_offer_analysus.trim(),
            asa_offer_analysis: allData.asa_offer_analysis.trim(),
            asa_interaction: allData.asa_interaction.trim(),
            alternative_justification:  allData.alternative_justification.trim(),
            asp_relevantlegal:  allData.asp_relevantlegal.trim(),
            asp_relevantenvironmental :  allData.asp_relevantenvironmental.trim(),
            asp_relevanttechnicians:  allData.asp_relevanttechnicians.trim(),

            annexed_vpn: allData.annexed_vpn,
            annexed_tir: allData.annexed_tir,
            annexed_tri: allData.annexed_tri,
            optimal_tri: allData.optimal_tri,
            annexed_cae_pe: allData.annexed_cae_pe,
            annexed_cae_alternative: allData.annexed_cae_alternative
        }
        axios.post(`${this.api}/ppi/send/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {            
            this.setState({
                iconModal: <FontAwesomeIcon 
                            icon={faSave} 
                            size={'3x'} 
                            style={{'color': '#6F9937', 'margin-bottom': '20px'}} />,
                showModal: true,
                titleModal : 'Información enviada correctamente',
                messageModal: response.data.message,
                loadingSend: false,
                sent: true
            });
        })  
        .catch((error) => {
            console.error(error)
            this.setState({loadingSend: false});
        })
        .finally(() => {
        });
    }
    /* TERMINA VALIDACIONES PARA FICHA TECNICA Y SUS VARIANTES */


    /* VALIDACIONES PARA NOTA TECNICA Y CEDULA */
    SaveData = () => {
        let allData = this.props.data.data;
        let user = JSON.parse(localStorage.getItem('user'));
        if(allData === null){
            return false;
        }


        if(user.fk_role !== 1){
            let flag = true;
            if(allData.dependency_executor === 0 || allData.dependency_executor === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la dependencia ejecutora, seleccionala para poder continuar.'
                });
                return false;
            }

            let percentageFunding = 0;
            allData.founding_sources_origin.forEach((item) => {
                percentageFunding += Number(item.percentage);
            });

            if(percentageFunding > 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El porcentaje total de las fuentes de financiamiento excede el 100%, por favor revisa tus datos'
                });
                return false;
            }
            let percentageCalendar = 0;
            allData.calendar_investment.forEach((item) => {
                percentageCalendar += Number(item.physical);
            });

            if(percentageCalendar > 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El porcentaje total del calendario de inversiones excede el 100%, por favor revisa tus datos'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && !validator.isNumeric(allData.applicant_pin.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Ingresa un NIP correcto.'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && Number(allData.applicant_pin.trim()) !== user.pin){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Tú NIP no coincide, verifica tú información.'
                });
                return false;
            }
            
            allData.founding_sources_origin.map((source) => {
                if(source.amount.length > 0 && validator.isNumeric(source.amount.replaceAll(',','')) && Number(source.amount.replaceAll(',','')) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El monto de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });

            if(!flag){
                return false;
            }       
        }else{
            if(validator.isEmpty(allData.name_ppi)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'El nombre del PPI no puede ir vacío.'
                });
                return false;
            }
        }
    
        this.setState({loadingSave:true});

        let data = {
            name_ppi: allData.name_ppi.trim(),
            classification: allData.classificationSelected,
            funding_sources: allData.founding_sources_origin,
            investment_calendar: allData.calendar_investment,
            components: allData.components,
            goals: allData.goals,
            dependency_executor: allData.dependency_executor,
            cities: allData.citySelected,
            relationated_programs: allData.relationated_program.trim(),
            objectives: allData.objectives.trim(),
            action_lines: allData.action_lines.trim(),
            coordinates: allData.coordinatesData,
            observations_location: allData.observations_location,
            description: allData.description.trim(),
            offer_analysis: allData.current_situation_offer.trim(),
            analysis_of_demand: allData.demand_current_situation.trim(),
            interaction: allData.intereaction_current_situation.trim(),
            general_description: allData.general_description.trim(),
            objective_general: allData.objective_general.trim(),
            offer_analysis_ppi: allData.offer_ppi.trim(),
            analysis_of_demand_ppi: allData.demand_ppi.trim(),
            interaction_ppi: allData.interaction_ppi.trim(),
            execution_results: allData.execution_result.trim(),
            ppi_beneficiaries: allData.beneficiaries.toString().trim(),
            executive_project: allData.executive_project,
            executive_project_description: allData.executive_project_desc.trim(),
            permissions: allData.permissions,
            permissions_description: allData.permissions_desc.trim(),
            enviromental_studies: allData.enviromental_studies,
            enviromental_studies_description: allData.enviromental_studies_desc.trim(),
            property_rights: allData.property_rights,
            property_rights_description: allData.property_rights_desc.trim(),
            final_comments: allData.final_comments.trim(),
            name_applicant: allData.name_applicant,
            applicant_title: allData.applicant_title,
            approval_pin: allData.applicant_pin.trim(),
            name_executor: allData.executor_name.trim(),
            executor_title: allData.executor_title.trim()
        }
        axios.post(`${this.api}/ppi/save/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            this.setState({
                iconModal: <FontAwesomeIcon 
                            icon={faCheck} 
                            size={'3x'} 
                            style={{'color': '#6F9937', 'margin-bottom': '20px'}} />,
                showModal: true,
                titleModal : response.data.message,
                messageModal: 'La información se guardó correctamente, el estatus se mantendra como en proceso hasta que envíes el formato a revisión.',
                loadingSave: false,
                refrescar: true
            });
        })  
        .catch((error) => {
            console.error(error)
            this.setState({loadingSave: false});
        })
        .finally(() => {
        });
    }
    SendData = () => {
        let flag = true;
        let allData = this.props.data.data;
        let user = JSON.parse(localStorage.getItem('user'));
        let format = Number(this.props.format);
        let cedula = false;
        if(format === 1 || format === 2) {
            cedula = true;
        }
        if(allData === null){
            return false;
        }
        const fm = new FormatMoney({
            decimals: 2
        });

        
        if(user.fk_role !== 1){
            if(validator.isEmpty(allData.name_ppi)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has escrito el nombre del PPI para poder continuar.'
                });
                return false;
            }
            if(allData.classificationSelected === 0 || allData.classificationSelected === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has la clasificación del PPI para poder continuar.'
                });
                return false;
            }
            if(allData.citySelected === 0 || allData.citySelected === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la ciudad del PPI para poder continuar.'
                });
                return false;
            }
            if(allData.dependency_executor === 0 || allData.dependency_executor === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la dependencia ejecutora, seleccionala para poder continuar.'
                });
                return false;
            }

            let totalPercentageFunding = 0;

            allData.founding_sources_origin.map((source) => {
                if(source.origin === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has seleleccionado un origen en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                if(source.origin === 2 && source.subsource === null){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has seleleccionado un fondo de origen tipo federal en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                if(source.origin === 5 && (source.other === null || validator.isEmpty(source.other.trim()))){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has ingresado un fondo de origen tipo otro en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                
                if(source.percentage.length > 0 && validator.isNumeric(source.percentage) && Number(source.percentage) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El porcentaje de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }else{
                    totalPercentageFunding += Number(source.percentage);
                }
                if(source.amount.length > 0 && validator.isNumeric(source.amount.replaceAll(',','')) && Number(source.amount.replaceAll(',','')) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El monto de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });

            if(!flag){
                return false;
            }

            if(totalPercentageFunding !== 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del porcentaje de las fuentes de financiamiento deben ser igual a 100%'
                });
                return false;
            }

            let totalPhysicalPercentage = 0;
            let totalFinancial = 0;
            allData.calendar_investment.map((element) => {
                if(validator.isEmpty(element.advance.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has ingresado un valor en avance en el calendario de inversiones.'
                    });
                    flag = false;
                    return false;
                }                
                
                if(element.physical !== null){
                    totalPhysicalPercentage += parseFloat(element.physical);
                }
                if(isNaN(element.financial) && element.financial !== null){
                    totalFinancial += parseFloat(element.financial.replaceAll(',',''));
                }else if(element.financial !== null){
                    totalFinancial += parseFloat(element.financial);
                }
                return true;
            });

            if(!flag){
                return false;
            }

            if(totalPhysicalPercentage !== 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del porcentaje del calendario de inversiones deben ser igual a 100%'
                });
                return false;
            }

            if(parseFloat(totalFinancial).toFixed(2) !== parseFloat(allData.amount).toFixed(2)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del monto financiero del calendario de inversiones deben ser igual a $'+fm.from(allData.amount),
                });
                return false;
            }

            if((allData.coordinatesData.length === 0)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'No has seleccionado aún la localización geográfica'
                });
                return false;
            }

            if(validator.isEmpty(allData.relationated_program.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa los programas relacionados para poder continuar'
                });
                return false;
            }

            if(validator.isEmpty(allData.objectives.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa los objetivos/estrategias para poder continuar'
                });
                return false;
            }

            if(validator.isEmpty(allData.action_lines.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa las líneas de acción para poder continuar'
                });
                return false;
            }
            if(validator.isEmpty(allData.description.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa la descripción de la problemática para poder continuar'
                });
                return false;
            }

            if(allData.PicFile === null && allData.imagesFile === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has cargado el documento con las imágenes de evidencia de la problemática, cargalo para poder continuar'
                });
                return false;
            }
            if(!cedula && validator.isEmpty(allData.current_situation_offer.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa el análisis de la oferta de la situación actual para poder continuar'
                });
                return false;
            }
            if(!cedula && validator.isEmpty(allData.demand_current_situation.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa el análisis de la demanda de la situación actual para poder continuar'
                });
                return false;
            }
            if(!cedula && validator.isEmpty(allData.intereaction_current_situation.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa la interacción de la oferta y demanda de la situación actual para poder continuar'
                });
                return false;
            }
            if(validator.isEmpty(allData.general_description.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Ingresa la descripción general para poder continuar'
                });
                return false;
            }
            
            var total_amount_component = 0;
            allData.components.map((element) => {
                if(element.description && validator.isEmpty(element.description.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el nombre de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.number_concept && element.number_concept === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el número de un concepto en uno de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.description_concept && validator.isEmpty(element.description_concept.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la descripción de un concepto en uno de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.number_item && element.number_item === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el número de partidas de uno de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.description_item && validator.isEmpty(element.description_item.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la descripción de una partida en uno de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(validator.isEmpty(element.description.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la descripción de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(Number(element.unit_of_measure) === 0 && element.unit_of_measure != null){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la unidad de medida de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(validator.isEmpty(element.quantity.toString().trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la cantidad de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(validator.isEmpty(element.unit_price.toString().trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el precio unitario de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }

                total_amount_component += parseFloat(element.total_amount);
                return true;
            });
            if(!flag){
                return false;
            }

            var total_components = Number.parseFloat(total_amount_component * 1.16).toFixed(2);
            var cantidad_financiada = Number.parseFloat(allData.amount).toFixed(2);
            if(total_components !== cantidad_financiada){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El valor total de los componentes no coincide con el monto a financiar, revisa tus datos.'
                });
                return false;
            }
            
            allData.goals.map((element) => {
                if(validator.isEmpty(element.name_concept.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el nombre de un concepto en las metas del PPI'
                    });
                    flag = false;
                    return false;
                }

                if(Number(element.unit_of_measure) === 0 && element.unit_of_measure != null){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la unidad de medida de un concepto en las metas del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(validator.isEmpty(element.quantity.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la cantidad de un concepto en las metas del PPI'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });
            if(!flag){
                return false;
            }
            if(validator.isEmpty(allData.objective_general.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado el objetivo general del PPI para continuar'
                });
                return false;
            }
            if(!cedula && validator.isEmpty(allData.offer_ppi.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado el análisis de la oferta con PPI para continuar'
                });
                return false;
            }
            if(!cedula && validator.isEmpty(allData.demand_ppi.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado el análisis de la demanda con PPI para continuar'
                });
                return false;
            }
            if(!cedula && validator.isEmpty(allData.interaction_ppi.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado la interacción de la oferta y demanda con PPI para continuar'
                });
                return false;
            }
            if(validator.isEmpty(allData.execution_result.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado el Resultados de la ejecución y beneficios económicos y/o sociales para continuar'
                });
                return false;
            }
            if(validator.isEmpty(allData.beneficiaries.toString().trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado el número de beneficiarios del PPI para continuar'
                });
                return false;
            }
            if(validator.isEmpty(allData.applicant_pin.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Campo requerido',
                    messageModal: 'Ingresa tú NIP para poder continuar con el envío.'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && Number(allData.applicant_pin.trim()) !== user.pin){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Ingresa un NIP correcto.'
                });
                return false;
            }
            if(validator.isEmpty(allData.executor_name.toString())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Aún no has ingresado el nombre del responsable ejecutor del PPI para continuar'
                });
                return false;
            }
            // if(allData.responsable_document === null && allData.oficioFile === null){
            //     this.setState({
            //         iconModal: <FontAwesomeIcon 
            //                     icon={faExclamationTriangle} 
            //                     size={'3x'} 
            //                     style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
            //         showModal: true,
            //         titleModal : 'Valor incorrecto',
            //         messageModal: 'Aún no has cargado el documento de oficio para continuar'
            //     });
            //     return false;
            // }
        }else{
            if(validator.isEmpty(allData.name_ppi.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'El nombre del PPI no puede ir vacío.'
                });
                return false;
            }
        }
        
        this.setState({loadingSend : true});

        let data = {
            name_ppi: allData.name_ppi.trim(),
            classification: allData.classificationSelected,
            funding_sources: allData.founding_sources_origin,
            investment_calendar: allData.calendar_investment,
            components: allData.components,
            goals: allData.goals,
            dependency_executor: allData.dependency_executor,
            cities: allData.citySelected,
            relationated_programs: allData.relationated_program.trim(),
            objectives: allData.objectives.trim(),
            action_lines: allData.action_lines.trim(),
            coordinates: allData.coordinatesData,
            observations_location: allData.observations_location,
            description: allData.description.trim(),
            offer_analysis: allData.current_situation_offer.trim(),
            analysis_of_demand: allData.demand_current_situation.trim(),
            interaction: allData.intereaction_current_situation.trim(),
            general_description: allData.general_description.trim(),
            objective_general: allData.objective_general.trim(),
            offer_analysis_ppi: allData.offer_ppi.trim(),
            analysis_of_demand_ppi: allData.demand_ppi.trim(),
            interaction_ppi: allData.interaction_ppi.trim(),
            execution_results: allData.execution_result.trim(),
            ppi_beneficiaries: allData.beneficiaries.toString().trim(),
            executive_project: allData.executive_project,
            executive_project_description: allData.executive_project_desc.trim(),
            permissions: allData.permissions,
            permissions_description: allData.permissions_desc.trim(),
            enviromental_studies: allData.enviromental_studies,
            enviromental_studies_description: allData.enviromental_studies_desc.trim(),
            property_rights: allData.property_rights,
            property_rights_description: allData.property_rights_desc.trim(),
            final_comments: allData.final_comments.trim(),
            name_applicant: allData.name_applicant,
            applicant_title: allData.applicant_title,
            approval_pin: allData.applicant_pin.trim(),
            name_executor: allData.executor_name.trim(),
            executor_title: allData.executor_title.trim()
        }
        axios.post(`${this.api}/ppi/send/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {            
            this.setState({
                iconModal: <FontAwesomeIcon 
                            icon={faSave} 
                            size={'3x'} 
                            style={{'color': '#6F9937', 'margin-bottom': '20px'}} />,
                showModal: true,
                titleModal : 'Información enviada correctamente',
                messageModal: response.data.message,
                loadingSend: false,
                sent: true
            });
        })  
        .catch((error) => {
            console.error(error)
            this.setState({loadingSend: false});
        }).finally(() => {
        });
    }

    SaveDataACB = () => {
        let allData = this.props.data.data;
        let user = JSON.parse(localStorage.getItem('user'));
        if(allData === null){
            return false;
        }
        if(user.fk_role !== 1){
            let flag = true;
            if(allData.dependency_executor === 0 || allData.dependency_executor === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la dependencia ejecutora, seleccionala para poder continuar.'
                });
                return false;
            }

            let percentageFunding = 0;
            allData.founding_sources_origin.forEach((item) => {
                percentageFunding += Number(item.percentage);
            });

            if(percentageFunding > 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El porcentaje total de las fuentes de financiamiento excede el 100%, por favor revisa tus datos'
                });
                return false;
            }
            let percentageCalendar = 0;
            allData.calendar_investment.forEach((item) => {
                percentageCalendar += Number(item.physical);
            });

            if(percentageCalendar > 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El porcentaje total del calendario de inversiones excede el 100%, por favor revisa tus datos'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && !validator.isNumeric(allData.applicant_pin.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Ingresa un NIP correcto.'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && Number(allData.applicant_pin.trim()) !== user.pin){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Tú NIP no coincide, verifica tú información.'
                });
                return false;
            }
            
            allData.founding_sources_origin.map((source) => {
                if(source.amount.length > 0 && validator.isNumeric(source.amount.replaceAll(',','')) && Number(source.amount.replaceAll(',','')) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El monto de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });

            if(!flag){
                return false;
            }      
        }else{
            if(validator.isEmpty(allData.name_ppi)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'El nombre del PPI no puede ir vacío.'
                });
                return false;
            }
        }
    
        

        this.setState({loadingSave:true});
        
        let data = {
            name_ppi: allData.name_ppi.trim(),
            funding_sources: allData.founding_sources_origin,
            investment_calendar: allData.calendar_investment,
            components: allData.components,
            goals: allData.goals,
            dependency_executor: allData.dependency_executor,
            management_dependency: allData.management_dependency,
            maintenance_dependency: allData.maintenance_dependency,
            total_amount_taxes: allData.total_amount_taxes,
            cities: allData.citySelected,
            localities: allData.localitySelected,
            coordinates: allData.coordinatesData,
            observations_location: allData.observations_location,
            references: allData.references,
            cost_quantification: allData.cost_quantification,
            cost_quantification_alt2: allData.cost_quantification_alt2,
            benefits_quantification: allData.benefits_quantification,
            description: allData.description,
            objective: allData.objective,
            vpn: allData.vpn,
            tir: allData.tir,
            tri: allData.tri,
            optimal_tri: allData.optimal_tri,
            ppi_evaluado: allData.ppi_evaluado,
            alternativa: allData.alternativa,
            name_applicant: allData.name_applicant,
            applicant_title: allData.applicant_title,
            approval_pin: allData.applicant_pin,
            name_executor: allData.executor_name,
            executor_title: allData.executor_title
        }
        axios.post(`${this.api}/ppi/save_acb/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            this.setState({
                iconModal: <FontAwesomeIcon 
                            icon={faCheck} 
                            size={'3x'} 
                            style={{'color': '#6F9937', 'margin-bottom': '20px'}} />,
                showModal: true,
                titleModal : response.data.message,
                messageModal: 'La información se guardó correctamente, el estatus se mantendra como en proceso hasta que envíes el formato a revisión.',
                loadingSave: false,
                refrescar: true
            });
        })  
        .catch((error) => {
            console.error(error)
            this.setState({loadingSave: false});
        }).finally(() => {
        });
    }
    SendDataACB = () => {        
        let flag = true;
        let allData = this.props.data.data;
        let user = JSON.parse(localStorage.getItem('user'));

        if(allData === null){
            return false;
        }
        const fm = new FormatMoney({
            decimals: 2
        });

        
        if(user.fk_role !== 1){
            if(validator.isEmpty(allData.name_ppi)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has escrito el nombre del PPI para poder continuar.'
                });
                return false;
            }
            
            if(allData.dependency_executor === 0 || allData.dependency_executor === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la Instancia Ejecutora o Unidad Responsable, seleccionala para poder continuar.'
                });
                return false;
            }
            if(allData.management_dependency === 0 || allData.management_dependency === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la Instancia encargada de la Administración, seleccionala para poder continuar.'
                });
                return false;
            }
            if(allData.maintenance_dependency === 0 || allData.maintenance_dependency === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la Instancia encargada del Mantenimiento, seleccionala para poder continuar.'
                });
                return false;
            }


            if(allData.total_amount_taxes === null || validator.isEmpty(allData.total_amount_taxes.toString().trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has ingresado el Monto total solicitado, ingresalo para poder continuar.'
                });
                return false;
            }

            let totalPercentageFunding = 0;
            allData.founding_sources_origin.map((source) => {
                if(source.origin === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has seleleccionado un origen en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                if(source.origin === 2 && source.subsource === null){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has seleleccionado un fondo de origen tipo federal en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                if(source.origin === 5 && (source.other === null || validator.isEmpty(source.other.trim()))){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has ingresado un fondo de origen tipo otro en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                
                if(source.percentage.length > 0 && validator.isNumeric(source.percentage) && Number(source.percentage) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El porcentaje de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }else{
                    totalPercentageFunding += Number(source.percentage);
                }
                if(source.amount.length > 0 && validator.isNumeric(source.amount.replaceAll(',','')) && Number(source.amount.replaceAll(',','')) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El monto de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });

            if(!flag){
                return false;
            }

            if(totalPercentageFunding !== 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del porcentaje de las fuentes de financiamiento deben ser igual a 100%'
                });
                return false;
            }

            let totalPhysicalPercentage = 0;
            let totalFinancial = 0;
            allData.calendar_investment.map((element) => {
                if(validator.isEmpty(element.advance.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has ingresado un valor en avance en el calendario de inversiones.'
                    });
                    flag = false;
                    return false;
                }                
                
                if(element.physical !== null){
                    totalPhysicalPercentage += parseFloat(element.physical);
                }
                if(isNaN(element.financial) && element.financial !== null){
                    totalFinancial += parseFloat(element.financial.replaceAll(',',''));
                }else if(element.financial !== null){
                    totalFinancial += parseFloat(element.financial);
                }
                return true;
            });

            if(!flag){
                return false;
            }

            if(totalPhysicalPercentage !== 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del porcentaje del calendario de inversiones deben ser igual a 100%'
                });
                return false;
            }

            if(parseFloat(totalFinancial).toFixed(2) !== parseFloat(allData.amount).toFixed(2)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del monto financiero del calendario de inversiones deben ser igual a $'+fm.from(allData.amount),
                });
                return false;
            }

            if((allData.coordinatesData.length === 0)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'No has seleccionado aún la localización geográfica'
                });
                return false;
            }

            var total_amount_component = 0;
            allData.components.map((element) => {
                if(element.name_concept && validator.isEmpty(element.name_concept.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el nombre de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.number_concept && element.number_concept === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el nombre de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.description_concept && validator.isEmpty(element.description_concept.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el nombre de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.number_item && element.number_item === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el nombre de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.description_item && validator.isEmpty(element.description_item.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el nombre de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.description_concept && validator.isEmpty(element.description_concept.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la descripción de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(Number(element.unit_of_measure) === 0 && element.unit_of_measure != null){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la unidad de medida de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(validator.isEmpty(element.quantity.toString().trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la cantidad de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(validator.isEmpty(element.unit_price.toString().trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el precio unitario de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }

                total_amount_component += parseFloat(element.total_amount);
                return true;
            });
            if(!flag){
                return false;
            }

            var total_components = Number.parseFloat(total_amount_component * 1.16).toFixed(2);
            var cantidad_financiada = Number.parseFloat(allData.amount).toFixed(2);
            if(total_components !== cantidad_financiada){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El valor total de los componentes no coincide con el monto a financiar, revisa tus datos.'
                });
                return false;
            }
            
            allData.goals.map((element) => {
                if(validator.isEmpty(element.name_concept.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el nombre de un concepto en las metas del PPI'
                    });
                    flag = false;
                    return false;
                }

                if(Number(element.unit_of_measure) === 0 && element.unit_of_measure != null){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la unidad de medida de un concepto en las metas del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(validator.isEmpty(element.quantity.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la cantidad de un concepto en las metas del PPI'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });
            if(!flag){
                return false;
            }
            if(validator.isEmpty(allData.description.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Campo requerido',
                    messageModal: 'Ingresa la descripción para poder continuar con el envío.'
                });
                return false;
            }
            if(validator.isEmpty(allData.objective.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Campo requerido',
                    messageModal: 'Ingresa el objetivo para poder continuar con el envío.'
                });
                return false;
            }

            
            if(validator.isEmpty(allData.applicant_pin.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Campo requerido',
                    messageModal: 'Ingresa tú NIP para poder continuar con el envío.'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && Number(allData.applicant_pin.trim()) !== user.pin){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Ingresa un NIP correcto.'
                });
                return false;
            }
            if(validator.isEmpty(allData.executor_name.toString())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Aún no has ingresado el nombre del responsable ejecutor del PPI para continuar'
                });
                return false;
            }
            
            let flagAnex = true;
            if(Object.keys(allData.cost_quantification).length == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos'
                });
                flagAnex = false;
            }
            
            if(!flagAnex){
                return false;
            }

            let QDataC1 = 0; //Cantidad de datos en tabla costos 1
            allData.cost_quantification.map((cost1, index) => {
                let flagCost = false;
                if(cost1.inversion !== null && cost1.inversion.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost1.operation !== null && cost1.operation.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost1.maintenance !== null && cost1.maintenance.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost1.externalities !== null && cost1.externalities.toString().trim().length > 0){
                    flagCost = true;
                }

                if(flagCost){
                    QDataC1++;
                }
            });
            
            if(QDataC1 == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos'
                });
                flagAnex = false;
            }
            
            if(Object.keys(allData.cost_quantification_alt2).length == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos Alternativa 2'
                });
                flagAnex = false;
            }
            
            if(!flagAnex){
                return false;
            }

            let QDataC2 = 0; //Cantidad de datos en tabla costos 2
            allData.cost_quantification_alt2.map((cost2, index) => {
                console.log(cost2)
                let flagCost = false;
                if(cost2.inversion !== null && cost2.inversion.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost2.operation !== null && cost2.operation.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost2.maintenance !== null && cost2.maintenance.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost2.externalities !== null && cost2.externalities.toString().trim().length > 0){
                    flagCost = true;
                }

                if(flagCost){
                    QDataC2++;
                }
            });
            
            if(QDataC2 == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos Alternativa 2'
                });
                flagAnex = false;
            }

            
            if(!flagAnex){
                return false;
            }

            if(Object.keys(allData.benefits_quantification).length == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de beneficios'
                });
                flagAnex = false;
            }
            
            if(!flagAnex){
                return false;
            }

            let QDataBEN = 0; //Cantidad de datos en tabla beneficios
            allData.benefits_quantification.map((ben, index) => {
                let flagCost = false;
                if(ben.ben1 !== null && ben.benefit_1.toString().trim().length > 0){
                    flagCost = true;
                }
                if(ben.ben2 !== null && ben.benefit_2.toString().trim().length > 0){
                    flagCost = true;
                }
                if(flagCost){
                    QDataBEN++;
                }
            });
            
            if(!flagAnex){
                return false;
            }
            
            if(QDataBEN == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de beneficios'
                });
                flagAnex = false;
            }
            
            if(!flagAnex){
                return false;
            }
        }else{
            if(validator.isEmpty(allData.name_ppi.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'El nombre del PPI no puede ir vacío.'
                });
                return false;
            }
        }
        
        
        this.setState({loadingSend : true});

        let data = {
            name_ppi: allData.name_ppi.trim(),
            funding_sources: allData.founding_sources_origin,
            investment_calendar: allData.calendar_investment,
            components: allData.components,
            goals: allData.goals,
            dependency_executor: allData.dependency_executor,
            management_dependency: allData.management_dependency,
            maintenance_dependency: allData.maintenance_dependency,
            total_amount_taxes: allData.total_amount_taxes,
            cities: allData.citySelected,
            localities: allData.localitySelected,
            coordinates: allData.coordinatesData,
            observations_location: allData.observations_location,
            references: allData.references,
            cost_quantification: allData.cost_quantification,
            cost_quantification_alt2: allData.cost_quantification_alt2,
            benefits_quantification: allData.benefits_quantification,
            description: allData.description,
            objective: allData.objective,
            vpn: allData.vpn,
            tir: allData.tir,
            tri: allData.tri,
            optimal_tri: allData.optimal_tri,
            ppi_evaluado: allData.ppi_evaluado,
            alternativa: allData.alternativa,
            name_applicant: allData.name_applicant,
            applicant_title: allData.applicant_title,
            approval_pin: allData.applicant_pin,
            name_executor: allData.executor_name,
            executor_title: allData.executor_title
        }
        axios.post(`${this.api}/ppi/send_acb/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {            
            this.setState({
                iconModal: <FontAwesomeIcon 
                            icon={faSave} 
                            size={'3x'} 
                            style={{'color': '#6F9937', 'margin-bottom': '20px'}} />,
                showModal: true,
                titleModal : 'Información enviada correctamente',
                messageModal: response.data.message,
                loadingSend: false,
                sent: true
            });
        })  
        .catch((error) => {
            console.error(error)
            this.setState({loadingSend: false});
        }).finally(() => {
        });
    }

    /* EMPIEZA ACE */
    SaveDataACE = () => {
        let allData = this.props.data.data;
        let user = JSON.parse(localStorage.getItem('user'));
        if(allData === null){
            return false;
        }
        if(user.fk_role !== 1){
            let flag = true;
            if(allData.dependency_executor === 0 || allData.dependency_executor === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la dependencia ejecutora, seleccionala para poder continuar.'
                });
                return false;
            }

            let percentageFunding = 0;
            allData.founding_sources_origin.forEach((item) => {
                percentageFunding += Number(item.percentage);
            });

            if(percentageFunding > 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El porcentaje total de las fuentes de financiamiento excede el 100%, por favor revisa tus datos'
                });
                return false;
            }
            let percentageCalendar = 0;
            allData.calendar_investment.forEach((item) => {
                percentageCalendar += Number(item.physical);
            });

            if(percentageCalendar > 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'marginBottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El porcentaje total del calendario de inversiones excede el 100%, por favor revisa tus datos'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && !validator.isNumeric(allData.applicant_pin.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Ingresa un NIP correcto.'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && Number(allData.applicant_pin.trim()) !== user.pin){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Tú NIP no coincide, verifica tú información.'
                });
                return false;
            }
            
            allData.founding_sources_origin.map((source) => {
                if(source.amount.length > 0 && validator.isNumeric(source.amount.replaceAll(',','')) && Number(source.amount.replaceAll(',','')) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El monto de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });

            if(!flag){
                return false;
            }       
        }else{
            if(validator.isEmpty(allData.name_ppi)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'El nombre del PPI no puede ir vacío.'
                });
                return false;
            }
        }
    
        

        this.setState({loadingSave:true});
        
        let data = {
            name_ppi: allData.name_ppi.trim(),
            funding_sources: allData.founding_sources_origin,
            investment_calendar: allData.calendar_investment,
            components: allData.components,
            goals: allData.goals,
            dependency_executor: allData.dependency_executor,
            management_dependency: allData.management_dependency,
            maintenance_dependency: allData.maintenance_dependency,
            total_amount_taxes: allData.total_amount_taxes,
            cities: allData.citySelected,
            localities: allData.localitySelected,
            coordinates: allData.coordinatesData,
            observations_location: allData.observations_location,
            references: allData.references,
            cost_quantification_alt1: allData.cost_quantification_alt1,
            cost_quantification_alt2: allData.cost_quantification_alt2,
            ppi_evaluado: allData.ppi_evaluado,
            alternativa: allData.alternativa,
            name_applicant: allData.name_applicant,
            applicant_title: allData.applicant_title,
            approval_pin: allData.applicant_pin,
            name_executor: allData.executor_name,
            executor_title: allData.executor_title,
            description: allData.description,
            objective: allData.objective,
        }

        axios.post(`${this.api}/ppi/save_ace/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            this.setState({
                iconModal: <FontAwesomeIcon 
                            icon={faCheck} 
                            size={'3x'} 
                            style={{'color': '#6F9937', 'margin-bottom': '20px'}} />,
                showModal: true,
                titleModal : response.data.message,
                messageModal: 'La información se guardó correctamente, el estatus se mantendra como en proceso hasta que envíes el formato a revisión.',
                loadingSave: false,
                refrescar: true
            });
        })  
        .catch((error) => {
            console.error(error)
            this.setState({loadingSave: false});
        }).finally(() => {
        });
    }
    SendDataACE = () => {        
        let flag = true;
        let allData = this.props.data.data;
        let user = JSON.parse(localStorage.getItem('user'));
        
        if(allData === null){
            return false;
        }
        const fm = new FormatMoney({
            decimals: 2
        });

        
        if(user.fk_role !== 1){
            if(validator.isEmpty(allData.name_ppi)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has escrito el nombre del PPI para poder continuar.'
                });
                return false;
            }
            
            if(allData.dependency_executor === 0 || allData.dependency_executor === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la Instancia Ejecutora o Unidad Responsable, seleccionala para poder continuar.'
                });
                return false;
            }
            if(allData.management_dependency === 0 || allData.management_dependency === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la Instancia encargada de la Administración, seleccionala para poder continuar.'
                });
                return false;
            }
            if(allData.maintenance_dependency === 0 || allData.maintenance_dependency === null){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has seleccionado la Instancia encargada del Mantenimiento, seleccionala para poder continuar.'
                });
                return false;
            }
            if(allData.total_amount_taxes.length === 0 || (!isNaN(Number(allData.total_amount_taxes)) && Number(allData.total_amount_taxes) == 0)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Datos requeridos',
                    messageModal: 'No has ingresado el Monto total solicitado, ingresalo para poder continuar.'
                });
                return false;
            }

            let totalPercentageFunding = 0;
            allData.founding_sources_origin.map((source) => {
                if(source.origin === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has seleleccionado un origen en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                if(source.origin === 2 && source.subsource === null){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has seleleccionado un fondo de origen tipo federal en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                if(source.origin === 5 && (source.other === null || validator.isEmpty(source.other.trim()))){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has ingresado un fondo de origen tipo otro en tus fuentes de financiamiento.'
                    });
                    flag = false;
                    return false;
                }
                
                if(source.percentage.length > 0 && validator.isNumeric(source.percentage) && Number(source.percentage) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El porcentaje de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }else{
                    totalPercentageFunding += Number(source.percentage);
                }
                if(source.amount.length > 0 && validator.isNumeric(source.amount.replaceAll(',','')) && Number(source.amount.replaceAll(',','')) === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'El monto de las fuentes de financiamiento deben ser mayores a 0.'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });

            if(!flag){
                return false;
            }

            if(totalPercentageFunding !== 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del porcentaje de las fuentes de financiamiento deben ser igual a 100%'
                });
                return false;
            }

            let totalPhysicalPercentage = 0;
            let totalFinancial = 0;
            allData.calendar_investment.map((element) => {
                if(validator.isEmpty(element.advance.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor incorrecto',
                        messageModal: 'Aún no has ingresado un valor en avance en el calendario de inversiones.'
                    });
                    flag = false;
                    return false;
                }
                
                if(element.physical !== null){
                    totalPhysicalPercentage += parseFloat(element.physical);
                }
                if(isNaN(element.financial) && element.financial !== null){
                    totalFinancial += parseFloat(element.financial.replaceAll(',',''));
                }else if(element.financial !== null){
                    totalFinancial += parseFloat(element.financial);
                }
                return true;
            });

            if(!flag){
                return false;
            }

            if(totalPhysicalPercentage !== 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del porcentaje del calendario de inversiones deben ser igual a 100%'
                });
                return false;
            }

            if(parseFloat(totalFinancial).toFixed(2) !== parseFloat(allData.amount).toFixed(2)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'La suma del monto financiero del calendario de inversiones deben ser igual a $'+fm.from(allData.amount),
                });
                return false;
            }

            if((allData.coordinatesData.length === 0)){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'No has seleccionado aún la localización geográfica'
                });
                return false;
            }

            var total_amount_component = 0;
            allData.components.map((element) => {
                if(element.description && validator.isEmpty(element.description.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el nombre de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.number_concept && element.number_concept === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el número de un concepto en uno de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.description_concept && validator.isEmpty(element.description_concept.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la descripción de un concepto en uno de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.number_item && element.number_item === 0){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el número de partidas de uno de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.description_item && validator.isEmpty(element.description_item.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la descripción de una partida en uno de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(validator.isEmpty(element.description.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la descripción de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(Number(element.unit_of_measure) === 0 && element.unit_of_measure != null){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la unidad de medida de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(validator.isEmpty(element.quantity.toString().trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la cantidad de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(validator.isEmpty(element.unit_price.toString().trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el precio unitario de un componente en la descripción de los componentes del PPI'
                    });
                    flag = false;
                    return false;
                }

                total_amount_component += parseFloat(element.total_amount);
                return true;
            });
            if(!flag){
                return false;
            }

            var total_components = Number.parseFloat(total_amount_component * 1.16).toFixed(2);
            var cantidad_financiada = Number.parseFloat(allData.amount).toFixed(2);
            if(total_components !== cantidad_financiada){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'El valor total de los componentes no coincide con el monto a financiar, revisa tus datos.'
                });
                return false;
            }
            
            allData.goals.map((element) => {
                if(validator.isEmpty(element.name_concept.trim())){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado el nombre de un concepto en las metas del PPI'
                    });
                    flag = false;
                    return false;
                }

                if(Number(element.unit_of_measure) === 0 && element.unit_of_measure != null){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la unidad de medida de un concepto en las metas del PPI'
                    });
                    flag = false;
                    return false;
                }
                if(element.quantity === undefined || (element.quantity !== undefined && validator.isEmpty(element.quantity.toString().trim()))){
                    this.setState({
                        iconModal: <FontAwesomeIcon 
                                    icon={faExclamationTriangle} 
                                    size={'3x'} 
                                    style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                        showModal: true,
                        titleModal : 'Valor requerido',
                        messageModal: 'Aún no has ingresado la cantidad de un concepto en las metas del PPI'
                    });
                    flag = false;
                    return false;
                }
                return true;
            });
            if(!flag){
                return false;
            }
            if(validator.isEmpty(allData.description.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Campo requerido',
                    messageModal: 'Ingresa la descripción para poder continuar con el envío.'
                });
                return false;
            }
            if(validator.isEmpty(allData.objective.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Campo requerido',
                    messageModal: 'Ingresa el objetivo para poder continuar con el envío.'
                });
                return false;
            }

            let sumPercentagesIndicators = 0;
            sumPercentagesIndicators += allData.vpn;
            sumPercentagesIndicators += allData.tir;
            sumPercentagesIndicators += allData.tri;
            
            if(sumPercentagesIndicators > 100){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'La suma de los porcentajes de VPN, TIR y TRI no pueden ser mayores a 100, revisa tus datos'
                });
                flag = false;
                return false;
            }
            
            if(validator.isEmpty(allData.applicant_pin.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Campo requerido',
                    messageModal: 'Ingresa tú NIP para poder continuar con el envío.'
                });
                return false;
            }

            if(!validator.isEmpty(allData.applicant_pin.trim()) && Number(allData.applicant_pin.trim()) !== user.pin){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Ingresa un NIP correcto.'
                });
                return false;
            }
            if(validator.isEmpty(allData.executor_name.toString())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor incorrecto',
                    messageModal: 'Aún no has ingresado el nombre del responsable ejecutor del PPI para continuar'
                });
                return false;
            }
            
            
            let flagAnex = true;
            if(Object.keys(allData.cost_quantification_alt1).length == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos'
                });
                flagAnex = false;
            }
            
            if(!flagAnex){
                return false;
            }

            let QDataC1 = 0; //Cantidad de datos en tabla costos 1
            allData.cost_quantification_alt1.map((cost1, index) => {
                console.log(cost1)
                let flagCost = false;
                if(cost1.inversion !== null && cost1.inversion.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost1.operation !== null && cost1.operation.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost1.maintenance !== null && cost1.maintenance.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost1.externalities !== null && cost1.externalities.toString().trim().length > 0){
                    flagCost = true;
                }

                if(flagCost){
                    QDataC1++;
                }
            });
            
            if(QDataC1 == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos'
                });
                flagAnex = false;
            }
            
            if(Object.keys(allData.cost_quantification_alt2).length == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos Alternativa 2'
                });
                flagAnex = false;
            }
            
            if(!flagAnex){
                return false;
            }

            let QDataC2 = 0; //Cantidad de datos en tabla costos 2
            allData.cost_quantification_alt2.map((cost2, index) => {
                console.log(cost2)
                let flagCost = false;
                if(cost2.inversion !== null && cost2.inversion.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost2.operation !== null && cost2.operation.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost2.maintenance !== null && cost2.maintenance.toString().trim().length > 0){
                    flagCost = true;
                }
                if(cost2.externalities !== null && cost2.externalities.toString().trim().length > 0){
                    flagCost = true;
                }

                if(flagCost){
                    QDataC2++;
                }
            });
            
            if(QDataC2 == 0){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faExclamationTriangle} 
                                size={'3x'} 
                                style={{'color': '#FB9C23', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'Aún no has ingresado datos en la tabla de Cuantificación de costos Alternativa 2'
                });
                flagAnex = false;
            }
            
            if(!flagAnex){
                return false;
            }

        }else{
            if(validator.isEmpty(allData.name_ppi.trim())){
                this.setState({
                    iconModal: <FontAwesomeIcon 
                                icon={faTimesCircle} 
                                size={'3x'} 
                                style={{'color': '#C41622', 'margin-bottom': '20px'}} />,
                    showModal: true,
                    titleModal : 'Valor requerido',
                    messageModal: 'El nombre del PPI no puede ir vacío.'
                });
                return false;
            }
        }
        
        this.setState({loadingSend : true});

        let data = {
            name_ppi: allData.name_ppi.trim(),
            funding_sources: allData.founding_sources_origin,
            investment_calendar: allData.calendar_investment,
            components: allData.components,
            goals: allData.goals,
            dependency_executor: allData.dependency_executor,
            management_dependency: allData.management_dependency,
            maintenance_dependency: allData.maintenance_dependency,
            total_amount_taxes: allData.total_amount_taxes,
            cities: allData.citySelected,
            localities: allData.localitySelected,
            coordinates: allData.coordinatesData,
            observations_location: allData.observations_location,
            references: allData.references,
            cost_quantification_alt1: allData.cost_quantification_alt1,
            cost_quantification_alt2: allData.cost_quantification_alt2,
            ppi_evaluado: allData.ppi_evaluado,
            alternativa: allData.alternativa,
            name_applicant: allData.name_applicant,
            applicant_title: allData.applicant_title,
            approval_pin: allData.applicant_pin,
            name_executor: allData.executor_name,
            description: allData.description,
            objective: allData.objective,
            executor_title: allData.executor_title
        }
        axios.post(`${this.api}/ppi/send_ace/${this.props.id}`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then((response) => {            
            this.setState({
                iconModal: <FontAwesomeIcon 
                            icon={faSave} 
                            size={'3x'} 
                            style={{'color': '#6F9937', 'margin-bottom': '20px'}} />,
                showModal: true,
                titleModal : 'Información enviada correctamente',
                messageModal: response.data.message,
                loadingSend: false,
                sent: true
            });
        })  
        .catch((error) => {
            console.error(error)
            this.setState({loadingSend: false});
        }).finally(() => {
        });
    }
    /* TERMINA ACE */

    resolveStatus = (idStatus) => {
        let status = {};
        switch (Number(idStatus)){
            case 1:
            case 2:
            case 3:
            case 4:
                status = {badge: 'primary', label: 'Validando'};
                break;
            case 5:
                status = {badge: 'primary', label: 'En Proceso'};
                break;
            case 6:
                status = {badge: 'primary', label: 'En revisión'};
                break;
            case 7:
                status = {badge: 'warning', label: 'Revisado'};
                break;
            case 8:
                status = {badge: 'success', label: 'Aprobado'};
                break;
            case 9:
                status = {badge: 'secondary', label: 'No aprobado'};
                break;
            case 10:
                status = {badge: 'secondary', label: 'Cancelado'};
                break;
            default:
                break;
        };

        return status;
    };
    preRenderSwitch = (from) => {
        let html;
        let segmentacionFormatos = 0;

        switch (Number(this.props.format)) {
            case 1:
            case 2:
            case 3:
            case 4:
                segmentacionFormatos = 1;
                break;
            case 5:
            case 6:
            case 7:
                segmentacionFormatos = 2;
                break;
            case 8:
            case 9:
                segmentacionFormatos = 3;
                break;
            case 10:
            case 11:
                segmentacionFormatos = 4;
                break;
            default:
                break;
        }

        let user = JSON.parse(localStorage.getItem('user'));
        switch(from.toLowerCase()){
            case 'approval':
                html = (
                    <div className="top-header shadow border px-4 w-100 TopBar">
                        <div className="size-24 bold text-left pt-3 container-text">
                            <NavLink to='/ppis'>
                                <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                            </NavLink> 
                            <OverlayTrigger
                                key={'bottom'}
                                placement={'bottom'}
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        {this.props.title}
                                    </Tooltip>
                                }
                            >
                                <span className="longText">{this.props.title}</span>
                            </OverlayTrigger>
                        </div>
                        <div className="options-bar p-3 d-flex justify-content-between">
                            <div className="text-primary size-16 bold pt-1">{this.props.subtitle}</div>
                            <div className={`custom-badge ${this.props.badge}`}>{this.props.messageBadge}</div>
                        </div>
                    </div>
                );
                break;
            case 'approved':
                html = (
                    <div className="top-header shadow border px-4 w-100 TopBar">
                        <div className="size-24 bold text-left pt-3 container-text">
                            <NavLink to='/ppis'>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </NavLink>
                            <OverlayTrigger
                                key={'bottom'}
                                placement={'bottom'}
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        {this.props.title}
                                    </Tooltip>
                                }
                            >
                                <span className="longText">{this.props.title}</span>
                            </OverlayTrigger>
                        </div>
                        <div className="options-bar p-3 d-flex justify-content-between">
                            <div className={`custom-badge ${this.props.badge}`}>{this.props.messageBadge}</div>
                        </div>
                    </div>
                );
                break;
            case 'format':
                let data = this.props.data;
                if(user.fk_role === 1){
                    html = (
                        <div className="top-header shadow border px-4 w-100 TopBar">
                            <div className="size-24 bold text-left pt-3 container-text">
                                <NavLink to='/ppis'>
                                    <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                                </NavLink> 
                                <OverlayTrigger
                                    key={'bottom'}
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                            {this.props.title}
                                        </Tooltip>
                                    }
                                >
                                    <span className="longText">{this.props.title}</span>
                                </OverlayTrigger>
                            </div>
                            <div className="options-bar p-3 d-flex justify-content-between">                                
                                <div className="text-primary size-16 bold pt-1 wrap-format">
                                    {data.type_ppi} - <span>{data.format}</span>
                                </div>
                            </div>
                        </div>
                    );
                }else{
                    let status = this.props.data.status;
                    if(this.props.data.status){
                        status = this.props.data.status;
                    }else if(this.props.status){
                        status = this.props.status;
                    }

                    html = (
                        <div className="top-header shadow border px-4 w-100 TopBar">
                            <div className="size-24 bold text-left pt-3 container-text">
                                <NavLink to='/ppis'>
                                    <FontAwesomeIcon icon={faArrowLeft} size="2x" />
                                </NavLink>
                                <OverlayTrigger
                                    key={'bottom'}
                                    placement={'bottom'}
                                    overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                            {this.props.title}
                                        </Tooltip>
                                    }
                                >
                                    <span className="longText">{this.props.title}</span>
                                </OverlayTrigger>
                            </div>
                            <div className="options-bar p-3 d-flex justify-content-between">
                                <div className="text-primary size-16 bold pt-1 wrap-format">
                                    {data.type_ppi} - <span>{data.format}</span>
                                </div>
                                {this.state.sent === false && status < 6 && segmentacionFormatos === 1 && <div className="text-primary size-16 bold pt-1 d-flex justify-content-between buttons-wrap">
                                    <button className="btn btn-secondary" onClick={this.SaveData}>Guardar { this.state.loadingSave ? ( <FontAwesomeIcon icon={faSpinner} spin /> ) : null }</button>
                                    <button className="btn btn-primary" onClick={this.SendData}>Enviar { this.state.loadingSend ? ( <FontAwesomeIcon icon={faSpinner} spin /> ) : null }</button>
                                </div> }
                                
                                {this.state.sent === false && status < 6 && segmentacionFormatos === 2 && <div className="text-primary size-16 bold pt-1 d-flex justify-content-between buttons-wrap">
                                    <button className="btn btn-secondary" onClick={this.SaveDataFichaTecnica}>Guardar { this.state.loadingSave ? ( <FontAwesomeIcon icon={faSpinner} spin /> ) : null }</button>
                                    <button className="btn btn-primary" onClick={this.SendDataFichaTecnica}>Enviar { this.state.loadingSend ? ( <FontAwesomeIcon icon={faSpinner} spin /> ) : null }</button>
                                </div> }
                                
                                {this.state.sent === false && status < 6 && segmentacionFormatos === 3 && <div className="text-primary size-16 bold pt-1 d-flex justify-content-between buttons-wrap">
                                    <button className="btn btn-secondary" onClick={this.SaveDataACB}>Guardar { this.state.loadingSave ? ( <FontAwesomeIcon icon={faSpinner} spin /> ) : null }</button>
                                    <button className="btn btn-primary" onClick={this.SendDataACB}>Enviar { this.state.loadingSend ? ( <FontAwesomeIcon icon={faSpinner} spin /> ) : null }</button>
                                </div> }
                                {this.state.sent === false && status < 6 && segmentacionFormatos === 4 && <div className="text-primary size-16 bold pt-1 d-flex justify-content-between buttons-wrap">
                                    <button className="btn btn-secondary" onClick={this.SaveDataACE}>Guardar { this.state.loadingSave ? ( <FontAwesomeIcon icon={faSpinner} spin /> ) : null }</button>
                                    <button className="btn btn-primary" onClick={this.SendDataACE}>Enviar { this.state.loadingSend ? ( <FontAwesomeIcon icon={faSpinner} spin /> ) : null }</button>
                                </div> }
                            </div>
                        </div>
                    );
                }
                break;
            default:
                break;
        }
        return html;
    }
    render(){
        
        if(this.state.refreshComponent){
            window.location.reload();
        }
        let html = this.preRenderSwitch(this.props.from);
        return (
            <div>
                {html}
                <Modal show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Body>
                        {this.state.iconModal}
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">{this.state.messageModal}</p>

                        <div className="w-100 d-flex pull-right px-4 pt-4 btn-content">
                            <div 
                                className="btn btn-small btn-primary h-30 w-100"
                                data-toggle="modal" 
                                data-target="#modal-solicitud" 
                                onClick={this.handleClose}
                            >
                                Aceptar
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default TopBar;